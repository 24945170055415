import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import {EnvConfigurationService } from "../services/env-config.service";
import { RawData } from 'app/models/raw-data';
import { ProcessData, ProcessDataConf } from 'app/models/processData';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class RawDataService {
  constructor(
    private http: HttpService,
    private envSettings: EnvConfigurationService) { }
  async getAll(startDate: string, endDate: string, deviceId: number, productId: number, granularityMinutes?: number, schedules?: string, orderId?: number): Promise<RawData[]> {

    const response = await this.http
      .get(this.getDataUrl(startDate, endDate, deviceId, productId, granularityMinutes, schedules, orderId))
      .toPromise();

    return response.json() as RawData[];
  }

  async getStats(startDate: string, endDate: string, deviceId: number, productId: number, granularityMinutes?: number, schedules?: string, orderId?: number, stopType?: number, errorId?: number): Promise<RawData[]> {

    let url = this.getDataUrl(startDate, endDate, deviceId, productId, granularityMinutes, schedules, orderId, stopType, errorId);
    
    url += '&populateStats=true';
    
    const response = await this.http
      .get(url)
      .toPromise();
    return response.json() as RawData[];
  }

  getDataUrl(from: string, to: string, deviceId: number, productId: number, granularity?: number, shifts?: string, orderId?: number, stopType?: number, errorId?: number): string {
      let url = `${this.envSettings.settings.apiBase}/data?fromDate=${from}&toDate=${to}&deviceId=${deviceId}`;
      if (productId) {
          url += `&productId=${productId}`;
      }
      if (granularity) {
          url += `&granularity=${granularity}`;
      }
      if (shifts && shifts.length) {
          url +=  `&shifts=${shifts}`
      }
      if (orderId) {
          url += `&orderId=${orderId}`
      }
      if (stopType || stopType === 0) {
          url += `&stopType=${stopType}`;
      }
      if (errorId) {
          url += `&errorId=${errorId}`;
      }
      return url;
  }

  async getProcessData(startDate: string, endDate: string, deviceId: number, granularityMinutes?: number): Promise<ProcessData[]> {
    let url = `${this.envSettings.settings.apiBase}/processData?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;

    if (granularityMinutes) {
      url += `&granularity=${granularityMinutes}`;
    }

    const response = await this.http.get(url).toPromise();

    return response.json() as ProcessData[];
  }

  async getProcessDataList(deviceId: number): Promise<ProcessDataConf[]> {
    let url = `${this.envSettings.settings.apiBase}/processDataConf?deviceId=${deviceId}&enabled=true`;
    const response = await this.http.get(url).toPromise();
    return response.json() as ProcessDataConf[];
  }
}
