import { PipeTransform, Pipe  } from '@angular/core';
import { ErrorModelForSelect } from 'app/components/ui/dialog-set-stop-error/dialog-set-stop-error.component';

@Pipe({
  name: 'stopErrorFilterPipe'
})
export class StopErrorFilterPipe implements PipeTransform {

  transform(rows: any[], filter: string[]): any[] {
    if (!rows) {
      return;
    }
    
    let found = rows.filter(item => {
        if (filter == undefined || !filter.length) {
            return true;
        }
        if (typeof item.type == 'undefined') {
          return false;
        }
        
        return (filter.find(type => type === item.type)) != undefined;
    });

    return found;
  }

}
