import { StopTableRow } from '../stopsTable/stopTableRow.interface';
import { StopService } from '../../../services/stop-history.service';
import { OnInit, Inject, Component } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    templateUrl: './dialog-set-stop-note.component.html',
    styleUrls: ['./dialog-set-stop-note.component.scss'],
})
export class SetStopNoteDialogComponent implements OnInit {
    note: string;
    loading = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public stop: StopTableRow,
        public dialogRef: MatDialogRef<SetStopNoteDialogComponent>,
        private _stopService: StopService
    ) { }

    async ngOnInit() {
        this.note = this.stop.note;
    }

    /**
     * Set the error and close the modal
     *
     *
     * @memberOf SetStopErrorDialogComponent
     */
    async ok() {

        this.loading = true;
        await this._stopService.setNote(this.stop.id, this.note);
        this.loading = false;

        this.dialogRef.close(true);
    }

    /**
     * Close the modal
     *
     *
     * @memberOf SetStopErrorDialogComponent
     */
    cancel() {
        this.dialogRef.close();
    }
}
