import { ProcessData } from './processData';
import { Stats } from './stats';

export class RawData {
    _id?: string;
    date?: Date;
    dateFrom?: Date;
    deviceId?: number;
    productId?: number;
    frequency?: number;
    timestep?: number;
    values?: number;
    valuesTotal?: number;
    valuesDetail?: Map<number, number>;
    scraps?: number;
    scrapsTotal?: number;
    scrapsDetail?: Map<number, number>;
    scrapsPercentage?: number;
    dataTypeId?: number;
    sessionId?: number;
    granularity?: number;
    average?: number;
    inputAverage?: number;
    productionTime?: number;
    Stats?: Stats;
    processData?: ProcessData;
}

export interface CronGranularity {
    /**
    * Used as label in the db
    *
    * @type {number}
    * @memberOf CronGranularity
    */
    granularityMinutes: number;
    cron: string;
    isMinGranularity?: boolean;
    isMaxGranularity?: boolean;
    /**
    * Compute samples from a previous sample rather than raw data
    *
    * @type {number}
    * @memberOf CronGranularity
    */
    sampleFromGranularity?: number;
    /**
    * Use this granularity starting from this value of time filter interval (ms)
    *
    * @type {number}
    * @memberOf CronGranularity
    */
    timeIntervalStart?: number;
    label?: string;
}

export let dataGranularities: CronGranularity[] = [
    {
        // minute
        label: 'minute',
        granularityMinutes: 1,
        cron: '* * * * *'
    },
    {
        // 10 minutes
        label: 'ten_minutes',
        granularityMinutes: 10,
        cron: '*/10 * * * *'
    },
    {
        // hour
        label: 'hour',
        granularityMinutes: 60,
        cron: '0 * * * *',
        isMaxGranularity: true
    },
    {
        // 12 hours
        label: 'twelve_hours',
        granularityMinutes: 720,
        cron: '0 */12 * * *',
        sampleFromGranularity: 1
    },
    {
        // day
        label: 'day',
        granularityMinutes: 1440,
        cron: '0 0 * * *',
        sampleFromGranularity: 1
    },
    {
        // week
        label: 'week',
        granularityMinutes: 10080,
        cron: '0 0 * * 1',
        sampleFromGranularity: 10
    },
    {
        // month
        label: 'month',
        granularityMinutes: 46080,
        cron: '0 0 1 * *',
        sampleFromGranularity: 60
    }
];

export enum DataType {
    units = 1,
    dropped = 0,
    connection = 2
  };
