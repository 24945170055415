import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { LoginService } from '../../../services/login.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import { OrderService } from 'app/services/order.service';
import { OperatorService } from 'app/services/operator.service';

import { LoaderService } from 'app/services/loader.service';
import { SnackbarService } from 'app/services/snackbar.service';
import { SnackbarType } from 'app/models/snackbar';
import { PluginService } from 'app/services/plugin.service';
import { PluginType } from 'app/models/plugin';
import { Subscription } from 'rxjs';
import { ThemeService } from 'app/services/theme.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NavBarComponent implements OnDestroy, OnInit{
    
    languages = [
        { code: 'en', locale: 'en-US', label: 'English' },
        { code: 'it', locale: 'it-IT', label: 'Italiano' },
        { code: 'de', locale: 'de-DE', label: 'Deutsch' },
        { code: 'fr', locale: 'fr-FR', label: 'Français' },
        { code: 'el', locale: 'el-GR', label: 'Ελληνικά' }
    ];

    currentLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

    showMenu = false;
    openMenu = false;
    isOperatorLoginEnabled = false;
    isOrderPluginEnabled = false;

    private componentSubscriptions: Subscription;

    constructor(
        public translate: TranslateService,
        public loginService: LoginService,
        public navbar: NavbarService,
        private _activeFilter: ActiveFiltersService,
        private _router: Router,
        private _orderService: OrderService,
        private _operatorService: OperatorService,
        private _pluginService: PluginService,
        private _loaderService: LoaderService,
        private _snackbarService: SnackbarService,
        private _themeService: ThemeService
    ) { }

    async ngOnInit() {
        this.componentSubscriptions = new Subscription();
        this.componentSubscriptions.add(
            this._activeFilter.onFilterChanged.subscribe(() => {
                this.onFilterChanged();
            }));
        this.componentSubscriptions.add(   
            this._activeFilter.onDevicesListChanged.subscribe(() => {
                this.onFilterChanged();
            }));
    }

    getDirImages() {
        return this._themeService.isTeamSystemThemeSetted() ? 'team_system/' : '';
    }

    ngOnDestroy() {
        this.componentSubscriptions.unsubscribe();
    }

    async onFilterChanged() {
        this.isOperatorLoginEnabled = await this.operatorLoginEnabled();
        this.isOrderPluginEnabled = await this.ordersEnabled();
    }

    goBack() {
        if (this._router.isActive('/oee/trends', true)) {
            this._router.navigateByUrl('/oee');
        } else if (this._router.isActive('/orders/detail', true)) {
            this._activeFilter.orderCoreId = 0;
            this._router.navigateByUrl('/orders');
        } else if (this._activeFilter.device && this._activeFilter.device.ProductionLine) {
            this._router.navigateByUrl('/realtime-line');
        } else {
            this._router.navigateByUrl('/realtime');
        }
    }

    logout() {
        this.loginService.logout();
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
    }
    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isRootPage(): boolean {
        if (this._activeFilter.device && this._activeFilter.device.ProductionLine) {
            return this._router.isActive('/realtime-line', true);
        } else {
            return this._router.isActive('/realtime', true);
        }

    }

    /**
     * Check if the current page is the qa dashboard
     *
     * @memberof FilterBarComponent
     */
    public isQADashboard(): boolean {
        return this._router.isActive('/qa-dashboard', true);
    }

    public isLoginPage(): boolean {
        return this._router.isActive('/login', true);
    }

    async operatorLoginEnabled(): Promise<boolean> {
        if (this._activeFilter.device) {
            const plantId = this._activeFilter.device.Plant && this._activeFilter.device.Plant.id;
            let plugins = await this._pluginService.getPluginsForPlant(plantId);

            return plugins && plugins.length && plugins.find(aPlugin => aPlugin.code == PluginType.operatorLogin) != null;
        }

        return false;
    }

    async ordersEnabled(): Promise<boolean> {
        if (this._activeFilter.device) {
            const plantId = this._activeFilter.device.Plant && this._activeFilter.device.Plant.id;
            let plugins = await this._pluginService.getPluginsForPlant(plantId);

            return plugins && plugins.length && plugins.find(aPlugin => aPlugin.code == PluginType.order && !aPlugin.PluginsDevices.isOnSatellite && aPlugin.PluginsDevices.enabled) != null;
        }

        return false;
    }

    setLanguage(locale: string) {
        if (this.translate.currentLang === locale) {
            return;
        }

        localStorage.setItem('lang', locale);
        window.location.reload();
    }

    /**
     * Function to navigate to another view
     *
     * @param {any} path
     * @memberof NavBarComponent
     */
    navigate(path) {
        this._router.navigateByUrl(path);
        this.showMenu = false;
    }

    async downloadOperatorsLoginSessionsExcel() {        
        const loaderMessage = this.translate.instant('operators.download_report_start');
        this._loaderService.startLoader(loaderMessage);

        this._operatorService.getReport(
                        this._activeFilter.device && this._activeFilter.device.Plant? this._activeFilter.device.Plant.id : null,
                        this._activeFilter.dateBegin,
                        this._activeFilter.dateEnd)
                    .then(
                        res => {
                            let contentType =  res.headers.get('Content-Type');
                            let contentDisposition = res.headers.get('Content-Disposition');
                            let fileName = 'operators_report.xlsx';

                            //Get file name from content-disposition;
                            try {
                                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                    var matches = filenameRegex.exec(contentDisposition);
                                    if (matches != null && matches[1]) {
                                        fileName = matches[1].replace(/['"]/g, '');
                                    }
                                }
                            } catch(ex) {}

                            this._loaderService.stopLoader();
                            this.downloadFile(fileName, contentType, res['_body']);
                        },
                        error => { // Error
                            this._loaderService.stopLoader();
                            const message = this.translate.instant('errors.operators.report-download-failed');
                            this._snackbarService.showSnackbar(message, SnackbarType.error);

                        }
                    );
    }

    private downloadFile(filename, type, data) {
        var a = document.createElement('a');
        a.setAttribute('style','display:none')
        var blob = new Blob([data], {type: type});
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },1000)
    }

}
