import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { QualityControlService } from 'app/services/quality-control.service';
import { ActiveFiltersService } from 'app/services/active-filters.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { QualityControlRawData } from 'app/models/quality-control';
import { TranslateService } from '@ngx-translate/core';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

export class compliaceDonutData {
    compliance?: string; // non compliance - Compliance
    quantity?: number;
}



@Component({
    selector: 'quality-control-product',
    templateUrl: './quality-control-product.component.html',
    styleUrls: ['./quality-control-product.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class QualityControlProductComponent extends PageWithLoader implements OnInit, OnDestroy {

    private compliaceDonutChartData: compliaceDonutData[];

    private nonCompliantPercentage: string;

    private compliaceDonutChart;

    _filterSubscription: any;

    productId: number;

    sub: Subscription;

    constructor(
        public activeFilters: ActiveFiltersService,
        private _route: Router,
        private _qaData: QualityControlService,
        private _translate: TranslateService,
        _themeService: ThemeService
    ) {
        super(_themeService);
        am4core.options.commercialLicense = true;
    }

    // to lock data request if is already processing
    lockRequest: boolean;


    async ngOnInit() {
        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        // get data and draw charts
        await this.getDataAndDrawChart();

    }

    /**
     * Function to get data based on the selected filter and draw the chart.
     *
     * @memberof QualityControlProductComponent
     */
    private async getDataAndDrawChart() {
        const res = await this._qaData.getAll(
            this.activeFilters.dateBegin.toISOString(),
            this.activeFilters.dateEnd.toISOString(),
            this.activeFilters.deviceId,
            this.activeFilters.productId);

        this.drawComplianceDonutChart(res[0]);
    }


    private async onFilterChanged() {

        if (this.activeFilters.productId === 0) {
            this._route.navigate(['/qa-dashboard']);
        }

        this.lockRequest = true;
        await this.getDataAndDrawChart();
        this.lockRequest = false;
    }

    private drawComplianceDonutChart(data: QualityControlRawData) {

        this.compliaceDonutChartData = this._formattingDataComplianceDonutChart(data);

        this.compliaceDonutChart = am4core.create("compliaceDonutChart", am4charts.PieChart);

        this.compliaceDonutChart.data = this.compliaceDonutChartData;

        this.compliaceDonutChart.innerRadius = 100;

        // Add and configure Series
        let pieSeries = this.compliaceDonutChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "quantity";
        pieSeries.dataFields.category = "compliance";
        pieSeries.colors.list = [
            am4core.color("#EE6363"),
            am4core.color("#D9D8D9")
        ];
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        // Add label
        var label = this.compliaceDonutChart.seriesContainer.createChild(am4core.Label);
        label.html = `<div class='donuts-label'>
            <div class='percentage'>${this.nonCompliantPercentage}</div>
            <div class='quantity'>${this.compliaceDonutChartData[0].quantity} 
            ${this._translate.instant('qa-dashboard.of')} 
            ${this.compliaceDonutChartData[0].quantity + this.compliaceDonutChartData[1].quantity} 
            </div></div>`;
        label.horizontalCenter = "middle";
        label.fontFamily = 'Teko';
        label.fontSize = 28;
        label.verticalCenter = "middle";

        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;

        this.compliaceDonutChart.legend = new am4charts.Legend();

    }

    private _formattingDataComplianceDonutChart(data: QualityControlRawData): compliaceDonutData[] {

        let res: compliaceDonutData[] = [
            { compliance: 'Non compliant', quantity: 0 },
            { compliance: 'Complian', quantity: 0 }
        ];

        res[0].quantity = data.notCompliant.length;
        res[1].quantity = data.compliant.length;

        this.nonCompliantPercentage = (res[0].quantity / (res[0].quantity + res[0].quantity) * 100).toFixed(1) + '%';

        return res;
    }

    async ngOnDestroy() {



    }
}
