import { ErrorModel } from '../models/error';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ErrorsTagModel } from '../models/errorsTag';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class ErrorsService {
    errors: ErrorModel[];
    errorsTags: ErrorsTagModel[];
    constructor(private httpService: HttpService,
        private envSettings: EnvConfigurationService) { }

    /**
     * Get all errors
     *
     * @param {number} [deviceId]
     * @returns
     *
     * @memberOf ErrorsService
     */
    async getErrors(deviceId?: number, options?: any) {
        let url;
        if (deviceId) {
            url = `${this.envSettings.settings.apiBase}/error?deviceId=${deviceId}&populateCategory=true`;
        } else {
            url = `${this.envSettings.settings.apiBase}/error?`;
        }

        if (options) {
            let toAppend = '';
            if (options.populateErrorTag != undefined) {
                toAppend += `populateErrorTag=${options.populateErrorTag}&`
            } 

            if (toAppend != '') {
                url += `&${toAppend}`;
            }
        }
        const response = await this.httpService
            .get(url)
            .toPromise();
        this.errors = response.json() as ErrorModel[];
        return response.json() as ErrorModel[];
    }

    /**
     * Get all errors tags
     *
     * @param {number} [deviceId]
     * @returns
     *
     * @memberOf ErrorsService
     */
    async getTagsWithErrors(companyId?: number) {
        let url;
        if (companyId) {
            url = `${this.envSettings.settings.apiBase}/errorsTag?populateErrors=true&companyId=${companyId}`;
        } else {
            url = `${this.envSettings.settings.apiBase}/errorsTag`;
        }
        const response = await this.httpService
            .get(url)
            .toPromise();
        this.errorsTags = response.json() as ErrorsTagModel[];
        return response.json() as ErrorsTagModel[];
    }
}
