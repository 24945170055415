import { OnDestroy, OnInit, Component } from '@angular/core';
import { CronGranularity, dataGranularities, RawData } from 'app/models/raw-data';
import { Device } from 'app/models/device';
import { ProcessData, ProcessGraphData, ProcessDataConf } from 'app/models/processData';
import { ActiveFiltersService } from 'app/services/active-filters.service';
import { NavbarService } from 'app/services/navbar.service';
import { RawDataService } from 'app/services/raw-data.service';
import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from 'app/services/devices.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz.js";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

@Component({
    templateUrl: './process-data.component.html',
    styleUrls: ['./process-data.component.scss']
})

export class ProcessDataComponent extends PageWithLoader implements OnInit, OnDestroy {

    // to lock data request if is already processing
    private _filterSubscription;
    activeGranularity: CronGranularity;
    activeGranularityMinutes: number;
    granularities: CronGranularity[];
    chartsReady = false;
    device: Device;
    selectedProcessData: string;
    processDataList: ProcessDataConf[];
    /**
    * granularity not allowed for the current time filter
    *
    * @type {number[]}
    * @memberOf PiecesScrapsComponent
    */
    disabledGranularities: number[] = [];
    rawData: RawData[];
    availableData = false;
    lockRequest = true;
    chartDatas: any[];
    chart: any;
    zoomTimeout = null;
    constructor(
        private _activeFilters: ActiveFiltersService,
        private _navbar: NavbarService,
        private _dataService: RawDataService,
        private _translate: TranslateService,
        private _devicesService: DevicesService,
        _themeService: ThemeService
    ) {
        super(_themeService);
        am4core.options.commercialLicense = true;
    }

    async ngOnInit() {
        this._translate.get('pieces.process_data').subscribe((res: string) => {
            this._navbar.setTitle(res);
        })

        // build allowed granularities
        this._buildGranularities();

        // subscribe to device changed event
        this._filterSubscription = this._activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this._activeFilters.initializeActiveDevice();

        this._computeGranularity();
        this.getData();
        this.zoomTimeout = 0;
    }


    async ngOnDestroy() {
        if (this.chart) {
            this.chart.dispose();
            delete this.chart;
        };
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    onFilterChanged() {
        this._computeGranularity();
        this.selectedProcessData = null;
        this.getData();
    }

    /**
    * Build allowed granularities array
    *
    *
    * @memberOf PiecesScrapsComponent
    */
    private _buildGranularities() {
        const allowedGranularities = [1, 10, 60, 1440, 10080, 46080];

        this.granularities = dataGranularities.filter(granularity => {
            return allowedGranularities.indexOf(granularity.granularityMinutes) !== -1;
        });
    }

    /**
    * Set current granularity
    *
    * @param {number} granularityMinutes
    *
    * @memberOf PiecesScrapsComponent
    */
    async setGranularity(granularity: CronGranularity) {
        if (this.disabledGranularities.indexOf(granularity.granularityMinutes) !== -1) {
            return;
        }
        this.activeGranularity = granularity;
        this.activeGranularityMinutes = granularity.granularityMinutes;
        await this.getData();
    }

    async processDataChanged() {
        if (this.selectedProcessData) {
            await this.getData();
        }
    }

    /**
    * Compute a reasonable granularity based on current time filters
    *
    *
    * @memberof ProcessDataComponent
    */
    _computeGranularity() {
        const currentInterval = this._activeFilters.dateEnd.getTime() - this._activeFilters.dateBegin.getTime();
        const currentIntervalMinutes = currentInterval / 1000 / 60;

        // choose best granularity for the given time filter
        let bestGranularity: CronGranularity;
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 120)) {
                continue;
            } else {
                bestGranularity = granularity;
                break;
            }
        }
        this.activeGranularity = bestGranularity;
        this.activeGranularityMinutes = bestGranularity.granularityMinutes;

        // find disabled granularities for the current time filter
        // i.e. too low or too high granularities
        this.disabledGranularities = [];
        const disabledGranularities: number[] = [];
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 120)) {
                disabledGranularities.push(granularityMinutes);
            } else if (granularityMinutes > (currentIntervalMinutes / 3)) {
                disabledGranularities.push(granularityMinutes);
            }
        }
        this.disabledGranularities = disabledGranularities;
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof HomeComponent
    */
    async getData() {
        if (this.chart) {
            this.chart.dispose();
            delete this.chart;
        }
        this.lockRequest = true;
        this.availableData = false;
        
        await this._devicesService.getDevices();
        this.device = this._devicesService.getDeviceInfo(this._activeFilters.deviceId);
        this.rawData = await this._dataService.getAll(this._activeFilters.dateBegin.toISOString(), this._activeFilters.dateEnd.toISOString(), this._activeFilters.deviceId, this._activeFilters.productId, this.activeGranularity.granularityMinutes);
        
        this.processDataList = await this._dataService.getProcessDataList(this._activeFilters.deviceId);
        let filteredData: RawData[] = [];
        if (this.processDataList && this.processDataList.length > 0) {
            if (!this.selectedProcessData) {
                this.selectedProcessData = this.processDataList[0].code;
            }
            let pd = this.processDataList.find(p => p.code === this.selectedProcessData);
            filteredData = this.rawData.filter(p => p.processData && p.processData[pd.valueType]);
        }
        
        this.lockRequest = false;
        if (!filteredData && !filteredData.length) { return; };
        const chartData = this._formatProcessRawData(filteredData);
        this.drawChart(chartData);
    }


    /**
     *  Format data 
     * 
     * @param {data} data
     * @memberof ProcessDataComponent
     */
    _formatProcessRawData(rawData: RawData[]): ProcessGraphData[] {
        const processData: ProcessGraphData[] = [];
        let pd = this.processDataList.find(p => p.code === this.selectedProcessData);
        for (const data of rawData) {
            const check = processData.find(d => d.originalDate === data.date);
            if (check) { continue; }
            if (data.processData[pd.valueType].mean || data.processData[pd.valueType].mean === 0) {
                processData.push({
                    date: new Date(data.date),
                    originalDate: data.date,
                    minValue: data.processData[pd.valueType].minValue || 0,
                    maxValue: data.processData[pd.valueType].maxValue || 0,
                    mean: data.processData[pd.valueType].mean || 0,
                    valueType: data.processData[pd.valueType].valueType
                });
            }
        }
        return processData;
    }

    /**
    * Function to draw the chart
    *
    * @param {any} data
    * @memberof ProcessDataComponent
    */
    drawChart(data) {

        this.chart = am4core.create("chartdiv", am4charts.XYChart);
        this.chart.data = data;

        let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.dataFields.category = "category";
        dateAxis.dateFormatter.dateFormat = "dd MMM yyyy HH:mm:ss";
        dateAxis.renderer.minGridDistance = 60;
        dateAxis.baseInterval = { count: 1, timeUnit: "second" };
        dateAxis.max = new Date(this._activeFilters.dateEnd).getTime();
        dateAxis.min = new Date(this._activeFilters.dateBegin).getTime();
        dateAxis.strictMinMax = true;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.tooltip.fontFamily = 'Teko';
        dateAxis.tooltip.fontWeight = 600;
        dateAxis.tooltipDateFormat = "MMM dd HH:mm:ss";

        dateAxis.parseDate = true;
        dateAxis.dateFormats.setKey('second', 'HH:mm:ss');
        dateAxis.dateFormats.setKey('minute', 'HH:mm');
        dateAxis.dateFormats.setKey('hour', 'HH:mm');
        dateAxis.dateFormats.setKey('day', 'MMM dd');
        dateAxis.dateFormats.setKey('week', '[bold]MMM dd[/]');
        dateAxis.dateFormats.setKey('month', '[bold]MMM YYYY[/]');
        dateAxis.dateFormats.setKey('year', '[bold]YYYY[/]');

        dateAxis.periodChangeDateFormats.setKey('second', '[bold]HH:mm[/]');
        dateAxis.periodChangeDateFormats.setKey('minute', '[bold]HH:mm[/]');
        dateAxis.periodChangeDateFormats.setKey('hour', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('day', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('week', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('month', '[bold]MMM YYYY[/]');
        dateAxis.periodChangeDateFormats.setKey('year', '[bold]YYYY[/]');

        dateAxis.events.on("startchanged", this.onChartZoomed.bind(this));
        //dateAxis.events.on("endchanged", this.onChartZoomed);

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.axisFills.template.disabled = true;
        valueAxis.extraMin = 0.15;
        valueAxis.extraMax = 0.15;
        valueAxis.strictMinMax = true;

        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.openValueY = "minValue";
        series.dataFields.valueY = "maxValue";
        series.dataFields.dateX = "date";
        series.tooltipText = "min: {openValueY.value} max: {valueY.value}";
        series.sequencedInterpolation = true;
        series.stroke = am4core.color("#6EB8DB");
        series.fill = series.stroke;
        series.fillOpacity = 0;
        series.strokeOpacity = 0;
        series.columns.template.width = 0.01;
        series.tooltip.pointerOrientation = "horizontal";

        let meanSeries = this.chart.series.push(new am4charts.LineSeries());
        meanSeries.dataFields.valueY = "mean";
        meanSeries.dataFields.dateX = "date";
        meanSeries.tooltipText = "{value}"
        meanSeries.stroke = am4core.color("#6EB8DB").lighten(-0.5);

        let openBullet = series.bullets.create(am4charts.CircleBullet);
        openBullet.locationY = 1;

        let closeBullet = series.bullets.create(am4charts.CircleBullet);
        closeBullet.fill = this.chart.colors.getIndex(4);
        closeBullet.stroke = closeBullet.fill;

        this.chart.cursor = new am4charts.XYCursor();

        this.chart.scrollbarX = new am4core.Scrollbar();

        this.availableData = true;
    }

    /**
     * Triggered when the chart is zoomed
     *
     * @param {any} event
     *
     * @memberOf RealtimeComponent
     */
    onChartZoomed(event) {
        if (this.zoomTimeout !== null) {
            clearTimeout(this.zoomTimeout);
        }
        this.zoomTimeout = setTimeout(() => {
            this._activeFilters.setDates(
                new Date(event.target.minZoomed),
                new Date(event.target.maxZoomed));
            const granularity = this.activeGranularityMinutes;
            this._computeGranularity();
            if (granularity !== this.activeGranularityMinutes) { this.getData(); }
        }, 300);

    }
}
