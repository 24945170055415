import { Component, ViewEncapsulation, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { PercentageBarData } from './percentageBar.interface';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-percentage-bar',
    templateUrl: 'percentageBar.component.html',
    styleUrls: ['./percentageBar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PercentageBarComponent {
    @Input() data: PercentageBarData;

    constructor(
        private _translate: TranslateService
    ) {
        
    }

    translate(key: string): string {
        return this._translate.instant(key);
    }

    convertMillisToText(time: number) {
        let tmp = moment.duration(time);
        return `${Math.floor(tmp.asDays())}gg:${tmp.hours()}h:${tmp.minutes()}min`;
    }

    convertMillisToDays(time: number) {
        let tmp = moment.duration(time);
        return `${Math.floor(tmp.asDays())}`;
    }

    convertMillisToHours(time: number) {
        let tmp = moment.duration(time);
        return `${tmp.hours()}`;
    }

    convertMillisToMinutes(time: number) {
        let tmp = moment.duration(time);
        return `${tmp.minutes()}`;
    }
}
