import { Category } from './category';
import { ErrorsTagModel } from './errorsTag';
export enum ErrorType {
    unplanned = 0,
    planned = 1
};
export class ErrorModel {
    id?: number;
    text?: string;
    categoryId?: number;
    companyId?: number;
    enabled?: boolean;
    deleted?: boolean;
    deviceId?: number;
    type?: ErrorType;
    Category?: Category;
    ErrorsTags?: ErrorsTagModel[];
}

export class ErrorStats {
    instance?: ErrorModel;
    n?: number;
    time?: number;
}

