import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as _ from 'lodash';
import { Session } from 'app/models/session';
import { SessionService } from 'app/services/session.service';

@Component({
    styleUrls: ['./dialog-edit-session.component.scss'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './dialog-edit-session.component.html'
})
export class EditSessionDialogComponent implements OnInit {
    loading = false;
    tmpSession: Session;
    constructor(
        @Inject(MAT_DIALOG_DATA) public session: Session,
        public dialogRef: MatDialogRef<EditSessionDialogComponent>,
        private _sessionService: SessionService
    ) {}

    async ngOnInit() {
        this.tmpSession = Object.assign({}, this.session);
        if (!this.tmpSession.itemsDeclared && this.tmpSession.itemsDeclared !== 0) this.tmpSession.itemsDeclared = this.tmpSession.itemsTotal;
        if (!this.tmpSession.scrapsDeclared && this.tmpSession.scrapsDeclared !== 0) this.tmpSession.scrapsDeclared = this.tmpSession.scraps;
    }

    /**
     * Update session and close the modal
     *
     *
     * @memberOf EditSessionDialogComponent
     */
    async ok() {
        if (this.loading || !this.isEdited()) return;
        this.loading = true;
        let itemsDeclared = this.itemsEdited() ? this.tmpSession.itemsDeclared : null;
        let scrapsDeclared =  this.scrapsEdited() ? this.tmpSession.scrapsDeclared : null;
        let note =  this.noteEdited() ? this.tmpSession.note : null;
        this.session = await this._sessionService.update(this.tmpSession.id, itemsDeclared, scrapsDeclared, note);
        this.loading = false;

        this.dialogRef.close(this.session);
    }

    /**
     * Close the modal
     *
     *
     * @memberOf EditSessionDialogComponent
     */
    cancel() {
        this.dialogRef.close();
    }

    /**
     * check if the session is edited
     *
     * @returns {boolean}
     * @memberof EditSessionDialogComponent
     */
    isEdited(): boolean {
        return this.itemsEdited() || this.scrapsEdited() || this.noteEdited();
    }

    itemsEdited(): boolean {
        let itemsChanged = false;
        if (!this.session.itemsDeclared && this.session.itemsDeclared !== 0 && this.tmpSession.itemsDeclared !== this.tmpSession.itemsTotal) itemsChanged = true;
        if ((this.session.itemsDeclared || this.session.itemsDeclared === 0) && this.tmpSession.itemsDeclared !== this.session.itemsDeclared) itemsChanged = true;
        return itemsChanged;
    }

    scrapsEdited(): boolean {
        let scrapsChanged = false;
        if (!this.session.scrapsDeclared && this.session.scrapsDeclared !== 0 && this.tmpSession.scrapsDeclared !== this.tmpSession.scraps) scrapsChanged = true;
        if ((this.session.scrapsDeclared || this.session.scrapsDeclared === 0) && this.tmpSession.scrapsDeclared !== this.session.scrapsDeclared) scrapsChanged = true;
        return scrapsChanged;
    }

    noteEdited(): boolean {
        let noteChanged = false;
        if (this.isStringDifferent(this.tmpSession.note, this.session.note)) noteChanged = true;
        return noteChanged;
    }

    isNumberDifferent(n: number, fromNumber: number): boolean {
        if (n === null || n === undefined) return false;
        if (n === 0 && fromNumber !== 0) return true;
        if (n && n !== fromNumber) return true;
        return false;
    }
    isStringDifferent(s: string, fromString: string): boolean {
        if (s === null || s === undefined) return false;
        return !!s.localeCompare(fromString);
    }
}
