import { ErrorTypeString, StopTableRow } from '../components/ui/stopsTable/stopTableRow.interface';
import { PipeTransform, Pipe  } from '@angular/core';
import { StopType } from 'app/models/stop';

@Pipe({
  name: 'nonProductionPipe'
})
export class NonProductionPipe implements PipeTransform {

  transform(rows: StopTableRow[], show: boolean): any[] {
    if (!rows) {
      return;
    }
    if (show) {
      return rows;
    } else {
      return rows.filter(item => {
          return (item.type !== StopType.nonProduction);
      });
    }
  }

}
