import { HttpService } from './http.service';
import { Response, ResponseContentType, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

import { LoginService } from './login.service';

import * as moment from 'moment-timezone';
import { EnvConfigurationService } from './env-config.service';


@Injectable()
export class OrderService {

  private orderBasePath = 'orders';

  constructor(private http: HttpService,
              private _loginService: LoginService,
              private envSettings: EnvConfigurationService) { }


  /**
     * Get A download report link for the XLS
     *
     * @returns {string}
     *
     * @memberOf QualityControlService
     */
    
  getXLSLink(plantId: number, dateFrom: Date, dateTo: Date): string {
      let url = `${this.envSettings.settings.apiBase}/${this.orderBasePath}/xlsx?access_token=${this._loginService.token}&dateFrom=${dateFrom?dateFrom.toISOString(): null}&dateTo=${dateTo? dateTo.toISOString(): null}&plantId=${plantId}&timezone=${moment.tz.guess()}`;
      
      return url;
  }

  getReport(plantId: number, dateFrom: Date, dateTo: Date): Promise<Response> {
    const url = this.getXLSLink(plantId, dateFrom, dateTo);

    let headers = new Headers();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    
    let options = new RequestOptions();
    options.responseType = ResponseContentType.Blob;
    options.headers = headers;
    
    return this.http.post(url, null, options).toPromise();
}
}