import { constructDependencies } from '@angular/core/src/di/reflective_provider';
import { ActiveFiltersService, DateInterval } from '../../../services/active-filters.service';
import { Component, EventEmitter, Input, Output, ElementRef, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import * as moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import { StatsService } from 'app/services/stats.service';
import { ProductionSchedule } from 'app/models/schedules';
import { MatDialog } from '@angular/material';
import { SelectScheduleDialogComponent } from '../dialog-select-schedule/dialog-select-schedule.component';
import { Subscription, interval } from 'rxjs';
import { EnvConfigurationService } from 'app/services/env-config.service';

@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnDestroy {
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Output() dateChangeEvent = new EventEmitter<Date[]>();
    @ViewChild(DaterangePickerComponent)
    private picker: DaterangePickerComponent;
    today: number = Date.now();
    schedules: ProductionSchedule[];
    popupOpened = false;
    alreadyEmit = true;
    private _filterSubscription;
    private _RTdataInterval: Subscription;
    public daterange: any = {};
    public options: any = {
        parentEl: 'app-date-filter',
        // timePicker: true,
        // timePicker24Hour: true,
        opens: 'left',
        skipCSS: true,
        alwaysShowCalendars: false
    };

    constructor(
        private _activeFilters: ActiveFiltersService,
        private _translate: TranslateService,
        private _statService: StatsService,
        private _dialog: MatDialog,
        private _envSettings: EnvConfigurationService
    ) { }

    ngOnInit() {
        this._translate.stream([
            'date_filter.today',
            'date_filter.yesterday',
            'date_filter.last_week',
            'date_filter.month_to_date',
            'date_filter.last_month',
            'date_filter.apply',
            'date_filter.cancel',
            'date_filter.custom_range',
            'date_filter.schedules'
        ]).subscribe((translations) => {
            moment.locale(this._translate.currentLang);
            this.options['locale'] = {
                format: 'YYYY-MM-DD HH:mm',
                daysOfWeek: moment.weekdaysMin(),
                applyLabel: translations['date_filter.apply'],
                cancelLabel: translations['date_filter.cancel'],
                customRangeLabel: translations['date_filter.custom_range'],
            };
            this.options['ranges'] = {
                [translations['date_filter.today']]: [moment().startOf('day'), moment()],
                [translations['date_filter.yesterday']]: this.setDateInterval('yesterday'),
                [translations['date_filter.last_week']]: this.setDateInterval('lastweek'),
                [translations['date_filter.month_to_date']]: this.setDateInterval('month'),
                [translations['date_filter.last_month']]: this.setDateInterval('lastmonth'),
                [translations['date_filter.schedules']]: this.setDateInterval('schedules')
            };
            this.options['minDate'] = moment('01/01/2017', 'DD/MM/YYYY');
            this.options['maxDate'] = moment();
            this.options['startDate'] = moment(this.startDate);
            this.options['endDate'] = moment(this.endDate);
        });
        
        this._filterSubscription = this._activeFilters.onFilterChanged.subscribe(() => {
            this._fetchSchedules();
        });
        this._activeFilters.datesRangeSubject.subscribe((value) => {
            setTimeout(() => {
                this.realTimeNow(moment(this._activeFilters.dateBegin));
            },2000);
        });
        
        this._fetchSchedules();
        this._setRTdataInterval(this._activeFilters.dateBegin, this._activeFilters.dateEnd);
    }

    ngOnDestroy() {
        if (this._RTdataInterval) {
            this._RTdataInterval.unsubscribe();
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    public updateDateRange(start: Date, end: Date, schedules?: string) {
        this.picker.datePicker.setStartDate(moment(start));
        this.picker.datePicker.setEndDate(moment(end));
        
        this._activeFilters.schedules = null;
        this._setRTdataInterval(start, end);

        if (schedules && schedules.length) {
            this._activeFilters.schedules = schedules;
        }

        //Comment out - this was generating a duplicate emit change event call from dateChange callback
        //this._activeFilters.emitChangeEvent();
        this.dateChangeEvent.emit([moment(start).toDate(), moment(end).toDate()]);
    }

    activate() {
        this.popupOpened = !this.popupOpened;
    };
    setDateInterval(intervalType: DateInterval) {
        const [begin, end] = this._activeFilters.computeDatesFromIntervalString(intervalType);
        return [moment(begin), moment(end)];
    };

    /**
     * Function to close calendar filter and emit event
     *
     *
     * @memberof DateFilterComponent
     */
    public selectedDate(value: any) {
        // this is the date the user selected
        this.alreadyEmit = false;
        if (value.label === this._translate.instant('date_filter.schedules')) {
            // return
        } else if (moment(value.end).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
            this.updateDateRange(value.start, moment().toDate());
        } else {
            this.updateDateRange(value.start, value.end);
        }
    }

    /**
     * Function to refresh today time
     *
     * @memberof DateFilterComponent
     */
    public calendarApplied(value: any) {

        if (this._RTdataInterval) {
            this._RTdataInterval.unsubscribe();
        }

        if (value.picker.chosenLabel === this._translate.instant('date_filter.schedules')) {
            this.showSchedulesDialog();
        } else if (this.alreadyEmit && moment(value.picker.endDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
            this.updateDateRange(value.picker.startDate, moment().toDate());
        }
        this.alreadyEmit = true;
    }

    private async _fetchSchedules() {
        this.schedules = await this._statService.getSchedules(this._activeFilters.deviceId, this._activeFilters.dateBegin.toISOString(), this._activeFilters.dateEnd.toISOString());
        this._activeFilters.existShiftInRange = this.schedules && this.schedules.length ? true : false;
    }

    private showSchedulesDialog() {
        let savedSchedules: ProductionSchedule[] = [];
        if (this._activeFilters.schedules) {
            savedSchedules = JSON.parse(this._activeFilters.schedules)
        }
        this.schedules.map((schedule) => {
            if (savedSchedules && savedSchedules.length) {
                for (let saved of savedSchedules) {
                    if (schedule.dateFrom === saved.dateFrom && schedule.dateTo === saved.dateTo) {
                        schedule.saved = true;
                    }
                }
            } else {
                schedule.saved = false;
            }
        })
        let dialogRef = this._dialog.open(SelectScheduleDialogComponent, {
            data: this.schedules
        });
        dialogRef.afterClosed().subscribe((schedule) => {
            if (schedule) {
                if (schedule.length) {
                    this.updateDateRange(schedule[0].dateFrom, schedule[schedule.length - 1].dateTo, JSON.stringify(schedule));
                } else {
                    this.updateDateRange(this._activeFilters.dateBegin, this._activeFilters.dateEnd);    
                }
                this._activeFilters.emitChangeEvent();
            } else {
                localStorage.removeItem("schedules");
            }
        });
    }


    /**
     * Set real time data fetching (every n seconds) interval if the current date filter is up to now
     *
     *
     * @memberOf RealtimeComponent
     */
    private _setRTdataInterval(start: any, end: any) {

        if (this._RTdataInterval) {
            this._RTdataInterval.unsubscribe();
            this._RTdataInterval = null;
        }

        const diff = moment().diff(moment(end), 'minutes');

        if (diff < 60 && (!this._RTdataInterval || this._RTdataInterval.closed)) {
            this._RTdataInterval = interval(this._envSettings.settings.RTfetchDataInterval).subscribe(
                () => {
                    this.realTimeNow(moment(start));
                }
            );

        }
    }

    private realTimeNow(start: any) {
        this.updateDateRange(start, moment().toDate());
    }
};

