import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActiveFiltersService } from 'app/services/active-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from 'app/services/navbar.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { QualityControlService } from 'app/services/quality-control.service';
import { QualityControlRawData } from 'app/models/quality-control';
import { isPlatformWorkerApp } from '@angular/common';
import { Router } from '@angular/router';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

am4core.useTheme(am4themes_animated);

export class ControlCompliance {
    id?: number;
    description?: string;
}

/**
 * Util class
 * 
 * controlCompliance
 *  0 = Non compliance
 *  1 = Compliance
 *  2 = Not excuted / Not done
 */
export class ControlComplianceStats {
    controlCompliance?: number;
    percentage?: string;
    count?: string;
}
export class SelectedFilter {
    taskType?: number;
    controlType?: number;
    controlCompliance?: number;
}

@Component({
    selector: 'quality-control-dashboard',
    templateUrl: './quality-control-dashboard.component.html',
    styleUrls: ['./quality-control-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QualityControlDashboardComponent extends PageWithLoader implements OnInit, OnDestroy {

    _filterSubscription: any;

    // to lock data request if is already processing
    lockRequest: boolean;

    // data get from server
    qualityControlRawData: QualityControlRawData[];

    // active control List 
    activeControlList: ControlCompliance[];

    // acrive task List
    activeTaskList: ControlCompliance[];

    // all filtered data raw
    filterData: QualityControlRawData[];

    notCompliantControl: ControlComplianceStats = {};

    compliantControl: ControlComplianceStats = {};

    notDoneControl: ControlComplianceStats = {};

    totalControl: any;

    hasData = true;

    controlCompliaceColor = {
        NonCompliace: '#ed6464',
        Compliance: '#9ccc65',
        NotDone: '#fcde6a'
    }

    selectedFilter: SelectedFilter = {
        taskType: 0,
        controlType: 0,
        controlCompliance: 0
    };

    controlComplianceType: ControlCompliance[];

    private chart;

    chartheight: number;

    constructor(
        public activeFilters: ActiveFiltersService,
        private _qaData: QualityControlService,
        private _translate: TranslateService,
        private _navbar: NavbarService,
        private _route: Router,
        _themeService: ThemeService
    ) {
        super(_themeService);
        am4core.options.commercialLicense = true;
    }


    async ngOnInit() {

        if (this.activeFilters.productId !== 0) {
            this._route.navigate(['/qa-product', this.activeFilters.productId]);
        }

        this.lockRequest = true;

        this._translate.stream([
            'qa-dashboard.title',
            'qa-dashboard.all-control',
            'qa-dashboard.non-compliance',
            'qa-dashboard.not-executed',
            'qa-dashboard.compliance',
            'qa-dashboard.of'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['qa-dashboard.title']);

            this.controlComplianceType = [
                { id: 0, description: translations['qa-dashboard.non-compliance'] },
                { id: 1, description: translations['qa-dashboard.compliance'] },
                { id: 2, description: translations['qa-dashboard.not-executed'] }
            ]
        });

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        // get data and draw a chart
        await this.getDataAndDrawChart();

        this.lockRequest = false;
    }


    ngOnDestroy() {

        if (this.chart) {
            this.chart = null;
        }

        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }

    }

    /**
     * Function to get data based on the selected filter and draw the chart.
     *
     * @memberof QualityControlDashboardComponent
     */
    private async getDataAndDrawChart() {
        this.qualityControlRawData = await this._qaData.getAll(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId);
        if (!this.qualityControlRawData.length) {
            this.hasData = false;
            return;
        }
        await this.checkAndApplyFilter();
        await this.uploadFilter();
        await this.drawStats();
        this.drawChart();
    }

    public async onQAFilterChanged(value: string) {
        if (value === 'control') this.selectedFilter.taskType = 0;
        else if (value === 'task') this.selectedFilter.controlType = 0;
        this.lockRequest = true;
        await this.checkAndApplyFilter();
        await this.drawStats();
        this.drawChart();
        this.lockRequest = false;
    }

    private async onFilterChanged() {
        this._cleanAllLocalVariables();
        if (this.activeFilters.productId !== 0) this._route.navigate(['/qa-product', this.activeFilters.productId]);
        this.lockRequest = true;
        await this.getDataAndDrawChart();
        this.lockRequest = false;
    }



    public async changeControlCompliance() {
        this.lockRequest = true;
        this.inputDataOnChart();
        this.lockRequest = false;
    }

    /**
    * Function to draw chart
    *
    * @param {any} data
    * @memberof RealtimeComponent
    */
    private async drawChart() {
        this.chart = null;
        this.chart = am4core.create("chart", am4charts.XYChart);
        this.chart.draggable = false;
        this.chart.resizable = false;
        this.chart.fontFamily = 'Roboto';
        this.chart.paddingTop = 150;
        this.chart.paddingBottom = 40;

        //await this.createCategoryAxis();
        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Product";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.fontSize = 14;

        let axisTooltip = categoryAxis.tooltip;
        axisTooltip.fontFamily = 'Teko';
        axisTooltip.fontWeight = 600;
        axisTooltip.background.strokeWidth = 0;
        axisTooltip.background.cornerRadius = 3;
        axisTooltip.background.pointerLength = 0;
        axisTooltip.dy = 5;
        axisTooltip.pointerOrientation = "up";
        axisTooltip.tooltipX = 100;

        let dropShadow = new am4core.DropShadowFilter();
        dropShadow.dy = 1;
        dropShadow.dx = 1;
        dropShadow.opacity = 0.5;
        axisTooltip.filters.push(dropShadow);

        // If there are more thank ten products so create scrollbar
        if (this.chart.data.length > 10) {
            this.chart.scrollbarX = new am4core.Scrollbar();
        }
        // Creating cursor
        this.chart.cursor = new am4charts.XYCursor();

        // Disable cursor zoom on category
        //this.chart.cursor.lineX.disabled = true;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

        valueAxis.min = 0;

        valueAxis.layout = "absolute";
        valueAxis.fontSize = 14;

        // Set up valueAxis title
        valueAxis.title.text = "N° Pezzi";
        valueAxis.title.rotation = 0;
        valueAxis.title.align = "right";
        valueAxis.title.valign = "top";
        valueAxis.title.fontSize = 14;
        valueAxis.cursorTooltipEnabled = false;

        // If there are more thank ten products so create scrollbar
        valueAxis.title.dy = this.chart.data.length > 10 ? -60 : -40;

        this.chart.scrollbarY = new am4core.Scrollbar();

        this.createSeries('non-compliance', 'NON COMPLIANCE', this.controlCompliaceColor.NonCompliace);
        this.createSeries('compliance', 'COMPLIANCE', this.controlCompliaceColor.Compliance);
        this.createSeries('not-done', 'NOT DONE', this.controlCompliaceColor.NotDone);
        // Legend
        this.chart.legend = new am4charts.Legend();

        await this.inputDataOnChart();

        // Create container
        var info = this.chart.plotContainer.createChild(am4core.Container);
        info.setVisibility(false);
        info.width = am4core.percent(20);
        info.height = 100;
        info.y = -120;
        info.padding(10, 10, 10, 10);
        info.background.fill = am4core.color("#FFFFFF");
        info.background.fillOpacity = 1;
        info.zIndex = 100000;
        info.layout = "grid";

        info.defaultState.animationDuration = 500;
        info.hiddenState.animationDuration = 500;

        // shadow container 
        info.background.filters.push(new am4core.DropShadowFilter());
        let shadow = info.background.filters.getIndex(0);
        shadow.dx = 0;
        shadow.dy = 0;
        shadow.blur = 5;
        shadow.color = am4core.color("#5c5c5c");

        // Create tooltip label for total count control
        this.createTotalLabel(info);


        this.chart.cursor.behavior = "zoomY";
        this.chart.cursor.fullWidthLineX = true;
        this.chart.cursor.xAxis = categoryAxis;
        this.chart.cursor.lineX.strokeOpacity = 0;
        this.chart.cursor.lineX.fill = am4core.color("#000");
        this.chart.cursor.lineX.stroke = am4core.color("#FFFFFF");
        this.chart.cursor.lineX.fillOpacity = 0.1;
        this.chart.cursor.clickable = true;

        // Create tooltip label for compliant and not compliant control
        this.chart.series.each((series) => {
            if (series.name !== 'NOT DONE') {
                this.createLabel(series.name, info);
            }
        });

        // // Show last item by default
        // this.chart.events.on("ready", (ev) => {
        //     this.chart.series.each((series) => {
        //         this.updateValues(series.dataItems.last, series.name);
        //     });
        // });

        // Show overal close values when cursor is not shown
        this.chart.cursor.events.on("hidden", (ev) => {
            info.setVisibility(false);
        });

        // Set up cursor's events to update the label
        this.chart.cursor.events.on("cursorpositionchanged", (ev) => {
            const category = categoryAxis.getPositionLabel(ev.target.xPosition);
            info.x = am4core.percent(categoryAxis.categoryToPosition(category, .5) * 100 - 10);
            let count = 0;
            this.chart.series.each((series) => {

                const dataItem = categoryAxis.getSeriesDataItem(
                    series,
                    categoryAxis.toAxisPosition(ev.target.xPosition),
                    true
                );
                if (series.name !== 'NOT DONE') this.updateValues(dataItem, series.name);
                count += dataItem.valueY;
            });

            this.updateTotalCountValues(count);
            info.setVisibility(true);
        });
    }

    /**
     * Input data on chart
     */
    private async inputDataOnChart() {
        const datas: any[] = [];

        for (const control of this.filterData) {

            const data: any = {
                'non-compliance': control.notCompliant.length,
                'compliance': control.compliant.length,
                'not-done': control.notDone.length,
                'Product': control.code
            }

            datas.push(data);
        }

        datas.sort((a, b) => {
            if (this.selectedFilter.controlCompliance === 0) {
                return b['non-compliance'] - a['non-compliance'];
            } else if (this.selectedFilter.controlCompliance === 1) {
                return b['compliance'] - a['compliance'];
            } else {
                return b['not-done'] - a['not-done'];
            }
        });

        this.chart.data = datas;

        this.chart.validateData();
    }

    // Create series
    private createSeries(field: string, name: string, color: string) {

        // Set up series
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "Product";
        series.sequencedInterpolation = true;
        series.columns.template.fill = am4core.color(color);
        series.columns.template.stroke = am4core.color(color);
        series.columns.template.width = am4core.percent(18);
        // Make it stacked
        series.stacked = true;
        return series;
    }

    // Updates values
    private updateTotalCountValues(value: number) {
        this.chart.series.each((series) => {
            var label = this.chart.map.getKey('total_count');
            if (value != 0) {
                label.text = value;
            } else {
                label.text = "-";
            }
        });
    }

    // Updates values
    private updateValues(dataItem, key) {
        this.chart.series.each(() => {
            var label = this.chart.map.getKey(key);
            if (dataItem["valueY"] != 0) {
                label.text = this.chart.numberFormatter.format(dataItem["valueY"]);
            } else {
                label.text = "-";
            }
        });
    }

    // Create labels
    private createLabel(title: string, info: any) {
        let titleLabel = info.createChild(am4core.Label);
        titleLabel.text = title + ":";
        titleLabel.marginRight = 5;
        titleLabel.minWidth = am4core.percent(50);
        titleLabel.fill = am4core.color("#9e9e9e");
        titleLabel.fontFamily = 'Roboto';
        titleLabel.fontWeight = "bold";
        titleLabel.fontSize = 12;
        titleLabel.height = 18;

        let valueLabel = info.createChild(am4core.Label);
        valueLabel.id = title;
        valueLabel.align = "right";
        valueLabel.marginLeft = am4core.percent(20);
        valueLabel.text = "-";
        valueLabel.minWidth = am4core.percent(20);
        valueLabel.fontWeight = 500;
        valueLabel.fill = am4core.color("#000000");
        valueLabel.fontFamily = 'Teko';
        valueLabel.contentValign = "top";
        valueLabel.dy = -8;
        valueLabel.contentAlign = "right";
        valueLabel.fontSize = 18;
        valueLabel.height = 12;
    }

    // Create totl labels
    private createTotalLabel(info) {
        let titleLabel = info.createChild(am4core.Label);
        titleLabel.text = this._translate.instant('qa-dashboard.total_control');
        titleLabel.marginRight = 5;
        titleLabel.minWidth = am4core.percent(50);
        titleLabel.fill = am4core.color("#4b4b4b");
        titleLabel.fontFamily = 'Teko';
        titleLabel.fontWeight = 500;
        titleLabel.fontSize = 18;
        titleLabel.height = 40;

        let valueLabel = info.createChild(am4core.Label);
        valueLabel.id = "total_count";
        valueLabel.align = "right";
        valueLabel.marginLeft = am4core.percent(20);
        valueLabel.text = "-";
        valueLabel.minWidth = am4core.percent(20);
        valueLabel.fontWeight = 500;
        valueLabel.fill = am4core.color("#000000");
        valueLabel.fontFamily = 'Teko';
        valueLabel.fontSize = 18;
        valueLabel.height = 40;
    }

    /**
     * Apply filter 
     * 
     * @memberof QualityControlDashboardComponent
     */
    private async checkAndApplyFilter() {

        let filterData = this._clone(this.qualityControlRawData);

        if (this.selectedFilter.taskType != 0 && this.selectedFilter.controlType == 0) {

            let index = 0;

            for (const data of filterData) {

                filterData[index].notDone = data.notDone.filter((control) => {
                    return control.taskId == this.selectedFilter.taskType
                });
                filterData[index].compliant = data.compliant.filter((control) => {
                    return control.taskId == this.selectedFilter.taskType
                });
                filterData[index].notCompliant = data.notCompliant.filter((control) => {
                    return control.taskId == this.selectedFilter.taskType
                });

                if ((!filterData[index].notDone || !filterData[index].notDone.length)
                    && (!filterData[index].compliant || !filterData[index].compliant.length)
                    && (!filterData[index].notCompliant || !filterData[index].notCompliant.length)) {
                    filterData[index] = null;
                }

                index++;
            };
        } else if (this.selectedFilter.taskType == 0 && this.selectedFilter.controlType != 0) {

            let index = 0;

            filterData.forEach((data) => {

                filterData[index].notDone = data.notDone.filter((control) => {
                    return control.ControlHistory.controlId == this.selectedFilter.controlType;
                });
                filterData[index].compliant = data.compliant.filter((control) => {
                    return control.ControlHistory.controlId == this.selectedFilter.controlType
                });
                filterData[index].notCompliant = data.notCompliant.filter((control) => {
                    return control.ControlHistory.controlId == this.selectedFilter.controlType
                });

                if ((!filterData[index].notDone || !filterData[index].notDone.length)
                    && (!filterData[index].compliant || !filterData[index].compliant.length)
                    && (!filterData[index].notCompliant || !filterData[index].notCompliant.length)) {
                    filterData[index] = null;
                }

                index++;
            });
        }

        filterData = filterData.filter((data) => data != null);
        this.filterData = this._clone(filterData);

    }



    /**
     * Rebuild control and task list for filter grapht
     *
     * @private
     *
     * @memberOf QualityControlComponent
     */
    private async uploadFilter() {

        this.activeControlList = [];
        this.activeTaskList = [];

        for (const data of this.qualityControlRawData) {

            for (const control of data.compliant) {

                if (control.ControlHistory.controlId) {
                    //const checkControl = await this.activeControlList.find(async c => c.id === control.ControlHistory.controlId);
                    const checkControl = await this.findItemInArray(this.activeControlList, control.ControlHistory.controlId);
                    if (!checkControl) {
                        const data = {
                            id: control.ControlHistory.controlId,
                            description: control.ControlHistory.Control.description
                        }
                        this.activeControlList.push(data);
                    }
                }

                if (control.taskId && control.Task) {

                    const checkTask = await this.findItemInArray(this.activeTaskList, control.taskId)

                    if (!checkTask) {
                        const data = {
                            id: control.taskId,
                            description: control.Task.description
                        }
                        this.activeTaskList.push(data);
                    }
                };
            };

            for (const control of data.notCompliant) {

                if (control.ControlHistory.controlId) {
                    //const checkControl = await this.activeControlList.find(async c => c.id === control.ControlHistory.controlId);
                    const checkControl = await this.findItemInArray(this.activeControlList, control.ControlHistory.controlId);
                    if (!checkControl) {
                        const data = {
                            id: control.ControlHistory.controlId,
                            description: control.ControlHistory.Control.description
                        }
                        this.activeControlList.push(data);
                    }
                }

                if (control.taskId && control.Task) {

                    const checkTask = await this.findItemInArray(this.activeTaskList, control.taskId)

                    if (!checkTask) {
                        const data = {
                            id: control.taskId,
                            description: control.Task.description
                        }
                        this.activeTaskList.push(data);
                    }
                };
            };

            for (const control of data.notDone) {

                if (control.ControlHistory.controlId) {
                    //const checkControl = await this.activeControlList.find(async c => c.id === control.ControlHistory.controlId);
                    const checkControl = await this.findItemInArray(this.activeControlList, control.ControlHistory.controlId);
                    if (!checkControl) {
                        const data = {
                            id: control.ControlHistory.controlId,
                            description: control.ControlHistory.Control.description
                        }
                        this.activeControlList.push(data);
                    }
                }

                if (control.taskId && control.Task) {

                    const checkTask = await this.findItemInArray(this.activeTaskList, control.taskId)

                    if (!checkTask) {
                        const data = {
                            id: control.taskId,
                            description: control.Task.description
                        }
                        this.activeTaskList.push(data);
                    }
                };
            };
        }

        this._rebuildControlsList(this.activeControlList);
        this._rebuildTasksList(this.activeTaskList);

    }

    /**
     * Rebuild stats 
     *
     * @private
     *
     * @memberOf QualityControlComponent
     */
    private async drawStats() {

        const count = [0, 0, 0];

        for (const control of this.filterData) {

            count[0] += control.notCompliant.length;
            count[1] += control.compliant.length;
            count[2] += control.notDone.length;
        }

        this.totalControl = count[0] + count[1] + count[2];

        // Not compliance
        this.notCompliantControl = {
            controlCompliance: 0,
            count: count[0] + ' ' + this._translate.instant('qa-dashboard.of') + ' ' + this.totalControl,
            percentage: ((count[0] / this.totalControl) * 100).toFixed(1)
        }

        // Compliance
        this.compliantControl = {
            controlCompliance: 1,
            count: count[1] + ' ' + this._translate.instant('qa-dashboard.of') + ' ' + this.totalControl,
            percentage: ((count[1] / this.totalControl) * 100).toFixed(1)
        }

        // Not done
        this.notDoneControl = {
            controlCompliance: 2,
            count: count[2] + ' ' + this._translate.instant('qa-dashboard.of') + ' ' + this.totalControl,
            percentage: ((count[2] / this.totalControl) * 100).toFixed(1)
        }
    }

    /**
     * Rebuild control list with the default ('all control) plus the products given
     *
     * @private
     * @param {ControlCompliance[]} [control]
     *
     * @memberOf QualityControlComponent
     */
    private async _rebuildControlsList(controls?: ControlCompliance[]) {
        let label = 'ALL CONTROLS';
        await this._translate.get('filter_bar.all_control').subscribe((res: string) => {
            label = res;
        });

        controls.sort((a, b) => {
            var nameA = a.description.toUpperCase(); // ignore upper and lowercase
            var nameB = b.description.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        controls.unshift({
            id: 0,
            description: label
        });

        this.activeControlList = controls;
    }


    /**
     * Rebuild tasks list with the default ('all control) plus the products given
     *
     * @private
     * @param {ControlCompliance[]} [tasks]
     *
     * @memberOf QualityControlComponent
     */
    private async _rebuildTasksList(tasks?: ControlCompliance[]) {
        let label = 'ALL MONITORING ACTIVITIES';
        await this._translate.get('filter_bar.all_task').subscribe((res: string) => {
            label = res;
        });

        tasks.sort((a, b) => {
            var nameA = a.description.toUpperCase(); // ignore upper and lowercase
            var nameB = b.description.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        tasks.unshift({
            id: 0,
            description: label
        });

        this.activeTaskList = tasks;
    }

    private async findItemInArray(array?: any, id?: number): Promise<boolean> {
        const check = array.find(item => item.id === id);
        return check;
    }

    /**
    * Clean all local variable
    *
    * @param {any} data
    * @memberof RealtimeComponent
    */
    private _cleanAllLocalVariables() {
        this.filterData = this.qualityControlRawData = this.activeTaskList = this.activeControlList = [];
        this.notCompliantControl = this.compliantControl = this.notDoneControl = {};
        this.totalControl = null;
        this.hasData = true;

        this.selectedFilter = {
            taskType: 0,
            controlType: 0,
            controlCompliance: 0
        };
    }

    private _clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }
};
