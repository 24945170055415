import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Session } from '../models/session';
import { OrderCore } from 'app/models/order';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class OrderDataService {
    constructor(private httpService: HttpService,
        private envSettings: EnvConfigurationService) { }
    async getAllDataSession(startDate: string, endDate: string, lineId:number, satelliteUrl:string, orderCode?: string, deviceId?: number): Promise<Session[]> {

        let url = `${satelliteUrl}/sessionData?from=${startDate}&to=${endDate}&lineId=${lineId}`;

        if (orderCode) url += `&orderCode=${orderCode}`;
        if (deviceId) url += `&deviceId=${deviceId}`;

        const response = await this.httpService
            .get(url)
            .toPromise();

        return response.json() as Session[];
    }

    async getOrderList(startDate: Date, endDate: Date, lineId: number, satelliteUrl: string): Promise<any> {

        let url = `${satelliteUrl}/orderFilter?from=${startDate}&to=${endDate}&lineId=${lineId}`;

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as any[];
    }

    async getOrderCoreList(startDate: string, endDate: string, deviceId: number): Promise<any> {
        try {
            let url = `${this.envSettings.settings.apiBase}/order?populateProduct=true&dateFrom=${startDate}&dateTo=${endDate}&deviceId=${deviceId}`;

            const response = await this.httpService
                .get(url)
                .toPromise();
            return response.json() as any[];
        } catch(err) {
            console.error(err);
        }
    }

    async getOrdersData(startDate: string, endDate: string, deviceId: number, productId: number): Promise<OrderCore[]> {
        try {
            let url = `${this.envSettings.settings.apiBase}/orders/${deviceId}/data?dateFrom=${startDate}&dateTo=${endDate}`;

            if (productId) {
                url += `&productId=${productId}`;
            }

            const response = await this.httpService
                .get(url)
                .toPromise();
            return response.json() as OrderCore[];
            
        } catch(err) {
            console.error(err);
        }
    }

    async getOrderDataById(orderId: number, startDate: string, endDate: string): Promise<OrderCore> {
        try {
            let url = `${this.envSettings.settings.apiBase}/order/${orderId}/data?dateFrom=${startDate}&dateTo=${endDate}`;

            const response = await this.httpService
                .get(url)
                .toPromise();
            return response.json() as OrderCore;
            
        } catch(err) {
            console.error(err);
        }
    }
}