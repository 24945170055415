import { TranslateService } from '@ngx-translate/core';
import { OeeParams, StopsStats } from '../../../models/stats';
import { StatsService } from '../../../services/stats.service';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { NavbarService } from '../../../services/navbar.service';
import { ProductionGraphCategoryItem } from '../../ui/production-graph-category/production-graph-category.interface';
import { ProductionGraphComponent } from '../../ui/production-graph/production-graph.component';
import { ErrorType } from '../../../models/error';
import { KPIService } from 'app/services/KPI.service';
import { MatSlideToggleChange, MatCheckboxChange } from '@angular/material';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ProductionSchedule } from 'app/models/schedules';
import { OeeUtility } from 'app/services/oee.utility';
import { ThemeService } from 'app/services/theme.service';
import { PageWithLoader } from '../page-with-loader';

@Component({
    templateUrl: './oee.component.html',
    styleUrls: ['./oee.component.scss']
})
export class OeeComponent extends PageWithLoader implements OnInit, OnDestroy {
    @ViewChild('productionGraph') productionGraph: ProductionGraphComponent;
    private _filterSubscription;
    private chartValues: any;
    
    params: OeeParams;
    waterfall: StopsStats;
    qualityChart: any;
    qualityChartLabelContainer: any;
    oeeWaterfall: any;
    qualityString: string;
    performanceString: string;
    availabilityString: string;
    lockRequest = true;
    timerMode = false;
    oae: boolean;
    considerPlanned: boolean;
    considerChangeOver: boolean;
    considerSchedules: boolean;
    stopBars = {
        planned: [],
        unplanned: []
    };
    totalTime: number;
    wfValueAxis: any;
    nShifts: number;
    graphCategories: ProductionGraphCategoryItem[] = [];
    constructor(
        private AmCharts: AmChartsService,
        public activeFilters: ActiveFiltersService,
        private navbar: NavbarService,
        private router: Router,
        private _statsService: StatsService,
        private _translate: TranslateService,
        private _kpi: KPIService,
        private _oeeUtility: OeeUtility,
        _themeService: ThemeService
    ) {
        super(_themeService)
        am4core.options.commercialLicense = true;
    }


    async ngOnInit() {
        await this.checkOAE();

        this.considerPlanned = localStorage.getItem('considerPlannedInOEE') ? JSON.parse(localStorage.getItem('considerPlannedInOEE')) : false;
        this.considerChangeOver = localStorage.getItem('considerChangeOver') ? JSON.parse(localStorage.getItem('considerChangeOver')) : false;
        this.considerSchedules = localStorage.getItem('considerSchedules') ? JSON.parse(localStorage.getItem('considerSchedules')) : true;

        // this._translate.get('realtime.mtbf_long').subscribe((res: string) => {
        // });
        this._translate.get('oee.quality_rate').subscribe((res: string) => {
            this.qualityString = res;
        });
        this._translate.get('oee.availability').subscribe((res: string) => {
            this.availabilityString = res;
        });
        this._translate.get('oee.uptime_efficiency').subscribe((res: string) => {
            this.performanceString = res;
        });


        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();


        this.getData();
    }
    ngOnDestroy() {
        if (this.AmCharts && this.chartValues) {
            this.AmCharts.destroyChart(this.chartValues);
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }

        if (this.qualityChart) {
            this.qualityChart.dispose;
        }

        if (this.oeeWaterfall) {
            this.oeeWaterfall.dispose;
        }
    }

    /**
    * Function to navigate to another view
    *
    * @param {any} path
    * @memberof OeeComponent
    */
    navigate(path) {
        this.router.navigateByUrl(path);
    }

    onFilterChanged() {
        if (this.qualityChart) {
            this.qualityChart.dispose;
        }
        if (this.oeeWaterfall) {
            this.oeeWaterfall.dispose;
        }
        this.getData();
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof OeeComponent
    */
    async getData() {
        this.lockRequest = true;
        // this.sta[ts = await this._statsService.getStats(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, 0);
        let deviceIds: number[] = [];
        
        if(this.activeFilters.isMultipleDeviceSelected()) {
            this.qualityChart = undefined;
            this.oeeWaterfall = undefined;
            
            deviceIds = [...this.activeFilters.deviceIds];

        } else {
            deviceIds = [this.activeFilters.deviceIds[0]];
            this.waterfall = await this._statsService.getWaterfall(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceIds[0], null);
        }
        
        this.params = await this._statsService.getAggregateOee(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), deviceIds, null, this.considerPlanned, this.considerChangeOver, this.activeFilters.schedules);
        
        this.params.oee *= 100;
        this.params.performance *= 100;
        this.params.availability *= 100;
        this.params.quality *= 100;
        const oeeGraph = [];
        oeeGraph.push({
            data: this.params.quality,
            label: this.params.quality || this.params.quality === 0 ? this.params.quality.toFixed(1) : this.params.quality,
            name: this.qualityString,
            color: '#f68eb1'
        });
        oeeGraph.push({
            data: this.params.availability,
            label: this.params.availability || this.params.availability === 0 ? this.params.availability.toFixed(1) : this.params.availability,
            name: this.availabilityString,
            color: '#7ed3fc'
        });
        oeeGraph.push({
            data: this.params.performance,
            label: this.params.performance || this.params.performance === 0 ? this.params.performance.toFixed(1) : this.params.performance,
            name: this.performanceString,
            color: '#9571cf'
        });

        // Set timerMode
        this.timerMode = this.activeFilters.timerMode;

        this.drawChart(oeeGraph);
        if(!this.activeFilters.isMultipleDeviceSelected()) {
            this.parseErrors();
            this.parseCategoriesForGraph();

            if (this.qualityChart) {
                this.inputNewDataInQualityChart();
            } else {
                this.drawQualityChart();
            }

            if (this.oeeWaterfall) {
                this.inputNewDataOeeWaterfall();
            } else {
                this.drawOeeWaterfall();
            }
        }

        this.lockRequest = false;
    }

    /**
    * Function to draw the chart
    *
    * @param {any} data
    * @memberof RealtimeComponent
    */
    drawChart(oee) {
        const chartDefaultOptions = {
            type: 'serial',
            theme: 'light',
            hideCredits: true,
            categoryField: 'name',
            addClassNames: true,
            dataProvider: oee,
            columnWidth: 1,
            columnSpacing: 0,
            zoomOutButtonAlpha: 0,
            zoomOutText: '',
            zoomOutButtonImageSize: 0,
            panelsSettings: {
                marginLeft: 25,
                marginRight: 25,
                marginTop: 20,
                marginBottom: 0
            },
            categoryAxis: {
                boldLabels: true,
                labelColorField: 'color',
                fontSize: 12,
                gridAlpha: 0
            },
            chartCursorSettings: {
                enabled: true,
                pan: false,
                bulletsEnabled: true,
                valueBalloonsEnabled: true,
                graphBulletSize: 1,
                valueLineBalloonEnabled: false,
                valueLineEnabled: false,
                categoryBalloonEnabled: true,
                categoryLineEnabled: false,
                valueLineAlpha: 0.5,
                fullWidth: false,
                cursorAlpha: 0
            },
            chartScrollbarSettings: {
                enabled: false
            },
            valueAxes: [{
                minimum: 0,
                maximum: 100,
                autoGridCount: false,
                gridCount: 5,
                labelFunction: (value, valueText) => {
                    return valueText + '%';
                },
                color: '#4b4b4b',
                boldLabels: true
            }]
        };

        const valuesOptions = _.cloneDeep(chartDefaultOptions);

        valuesOptions['graphs'] = [{
            fillAlphas: 0.8,
            lineAlpha: 0,
            type: 'column',
            balloonText: '<b>[[label]]%</b>',
            fillColorsField: 'color',
            valueField: 'data',
            // labelText: '[[label]]%',
            // fontSize: 20,
            // labelPosition: 'inside',
            // boldLabel: true,
            // color: '#fff',
            // labelOffset: 10,
            columnWidth: 0,
            fixedColumnWidth: 110
        }];

        this.chartValues = this.AmCharts.makeChart('chart-values', valuesOptions);
    }

    parseErrors() {
        const errors = this.params && this.params.stopsStats && this.params.stopsStats.errors ? this.params.stopsStats.errors : [];
        this.stopBars.planned = [];
        this.stopBars.unplanned = [];
        errors.forEach((error) => {
            let text;
            if (error.instance.text) {
                text = error.instance.text;
            } else {
                if (error.instance.type === -1) {
                    text = this._translate.instant('stops.not_specified');
                }
                if (error.instance.type === 2) {
                    text = this._translate.instant('stops.change_over');
                }
                if (error.instance.type === 3) {
                    text = this._translate.instant('stops.not_production');
                }
                if (error.instance.type === 4) {
                    text = this._translate.instant('oee.schedules_loss');
                }
            }
            const parsedError = {
                text,
                duration: error.time,
                values: error.time,
                percentage: (error.time / this.params.timeScheduled * 100).toFixed(1)
            };
            if (error.time / this.params.timeScheduled * 100 >= 0.1) {
                if (!this.oae) {
                    if (error.instance.type === ErrorType.unplanned || error.instance.type === -1) {
                        this.stopBars.unplanned.push(parsedError);
                    } else if (error.instance.type === ErrorType.planned && this.considerPlanned) {
                        this.stopBars.unplanned.push(parsedError);
                    } else if (error.instance.type === 2 && this.considerChangeOver) {
                        this.stopBars.unplanned.push(parsedError);
                    }
                } else {
                    if (error.instance.type === ErrorType.unplanned || error.instance.type === -1) {
                        this.stopBars.unplanned.push(parsedError);
                    }
                }
            }
        });
    }

    parseCategoriesForGraph() {
        this.graphCategories = [];
        let schedules = this.activeFilters.schedules;
        if (schedules) {
            this.nShifts = JSON.parse(schedules).length;
        } else {
            this.nShifts = 0;
        }
        if (this.considerSchedules && schedules) {
            let _schedules: ProductionSchedule[] = JSON.parse(schedules);

            this.totalTime = 0;
            for (let schedule of _schedules) {
                this.totalTime += ((moment(schedule.dateTo).valueOf() - moment(schedule.dateFrom).valueOf()) / 1000 / 60);
            }
        } else {
            this.totalTime = (moment(this.activeFilters.dateEnd).valueOf() - moment(this.activeFilters.dateBegin).valueOf()) / 1000 / 60;
        }
        let remainingTime = this.totalTime;
        let categories;
        if (this.considerSchedules) {
            categories = this.params.stopsStats.categories;
        } else {
            categories = this.waterfall.categories;
        }
        categories.sort((a, b) => {
            if (a.instance.type < b.instance.type) {
                return 1;
            } else if (a.instance.type === b.instance.type) {
                if (a.time > b.time) {
                    return -1;
                } else {
                    return 1;
                }
            } else {
                return -1;
            }
        });
        this.graphCategories.push({
            category: this._translate.instant('oee.total_time'),
            duration: this._oeeUtility.convertMinutesToText(this.totalTime) + ' (' + ((this.totalTime / this.totalTime) * 100) + '%)',
            value: this.totalTime,
            open: 0,
            stepValue: this.totalTime,
            color: am4core.color("#E5856E")
        });
        for (const category of categories) {
            if (!category.time) {
                continue;
            }
            let categoryName = "";
            let color: any;
            switch (category.instance.type) {
                case 4:
                    color = am4core.color("#ffe040");
                    categoryName = this._translate.instant('oee.schedules_loss') + " (1)";
                    break;
                case 3:
                    color = am4core.color("#A9A9A9");
                    categoryName = this._translate.instant('stops.not_production') + " (1)";
                    break;
                case 2:
                    color = am4core.color("#FFDBB3");
                    categoryName = this._translate.instant('stops.change_over') + " (1)";
                    break;
                case -1:
                    color = am4core.color("#7299df");
                    categoryName = this._translate.instant('stops.not_specified') + " (1)";
                    break;
                case -2:
                    color = am4core.color("#aa8dd9");
                    categoryName = this._translate.instant('oee.minor_stops') + " (1)";
                    break;
                default:
                    color = category.instance.type === ErrorType.planned ? am4core.color("#AACC66") : am4core.color("#D7510F");
                    categoryName = category.instance.name;
                    break;
            }

            let waterfallItem : ProductionGraphCategoryItem = {
                category: categoryName,
                duration: this._oeeUtility.convertMinutesToText(category.time) + ' (' + ((category.time / this.totalTime) * 100).toFixed(1) + '%)',
                value: remainingTime - category.time,
                open: remainingTime,
                stepValue: remainingTime - category.time,
                color: color
            }
            remainingTime -= category.time;
            this.graphCategories.push(waterfallItem);
        };

        let lastCategory: ProductionGraphCategoryItem = {
            category: this._translate.instant('oee.operating_time'),
            value: remainingTime,
            duration: this._oeeUtility.convertMinutesToText(remainingTime) + ' (' + ((remainingTime / this.totalTime) * 100).toFixed(1) + '%)',
            open: 0,
            color: am4core.color("#01A651")
        };
        this.graphCategories.push(lastCategory);
    }

    drawQualityChart() {

        this.qualityChart = am4core.create("qualityChart", am4charts.PieChart);

        this.qualityChart.data = [{
            quantity: this.params.quality,
            description: this.qualityString
        }, {
            quantity: 100 - this.params.quality,
            description: ''
        }];

        this.qualityChart.innerRadius = am4core.percent(50);

        // Add and configure Series
        let pieSeries = this.qualityChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "quantity";
        pieSeries.dataFields.category = "description";
        pieSeries.colors.list = [
            am4core.color("#F7A2BE"),
            am4core.color("#FEEDF2")
        ];
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 0;
        pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        pieSeries.slices.template.tooltipX = -1000;

        // Add label
        this.qualityChartLabelContainer = this.qualityChart.seriesContainer.createChild(am4core.Label);
        this.qualityChartLabelContainer.html = `<div class='percentage'>${(Math.round(this.qualityChart.data[0].quantity * 100) / 100)} %</div></div>`;
        this.qualityChartLabelContainer.horizontalCenter = "middle";
        this.qualityChartLabelContainer.fontFamily = 'Teko';
        this.qualityChartLabelContainer.fontSize = am4core.percent(200);
        this.qualityChartLabelContainer.fontWeight = 500;
        this.qualityChartLabelContainer.fill = am4core.color("#4b4b4b");
        this.qualityChartLabelContainer.verticalCenter = "middle";

        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = 3;
        pieSeries.slices.template.tooltipText = "";
        pieSeries.labels.template.padding(0, 0, 0, 0);
        // pieSeries.labels.template.fill = '#FEEDF2';

    }

    private async inputNewDataInQualityChart() {

        this.qualityChart.data = [{
            quantity: this.params.quality,
            description: this.qualityString
        }, {
            quantity: 100 - this.params.quality,
            description: ''
        }];

        this.qualityChartLabelContainer.html = `<div class='percentage'>${(Math.round(this.qualityChart.data[0].quantity * 100) / 100)} %</div></div>`;
    }

    drawOeeWaterfall() {

        this.oeeWaterfall = am4core.create("oeeWaterfall", am4charts.XYChart);
        this.oeeWaterfall.hiddenState.properties.opacity = 0; // this makes initial fade in effect
        this.oeeWaterfall.data = this.graphCategories.reverse();

        let categoryAxis = this.oeeWaterfall.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.fontFamily = 'Roboto';
        categoryAxis.color = am4core.color("#d4d4d4");
        categoryAxis.fontWeight = 600;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.labels.template.adapter.add("textOutput", function(text) {
            if(text) return text.replace(/ \(.*/, "") 
            return '';
        });
        categoryAxis.adapter.add("getTooltipText", function(text, target, key) {
            if(text) return text.replace(/ \(.*/, "");
            return '';
        });

        this.wfValueAxis = this.oeeWaterfall.xAxes.push(new am4charts.DurationAxis());
        this.wfValueAxis.baseUnit = "minute";
        this.wfValueAxis.min = 0;
        this.wfValueAxis.max = this.totalTime + (this.totalTime / 20);
        this.wfValueAxis.strictMinMax = true;
        this.wfValueAxis.renderer.grid.template.disabled = true;
        this.wfValueAxis.renderer.labels.template.disabled = true;
        this.oeeWaterfall.durationFormatter.durationFormat = "d'd:'hh'h:'mm'min'";
        this.createWFGrid(0);
        this.createWFGrid(this.totalTime / 4);
        this.createWFGrid(this.totalTime / 2);
        this.createWFGrid(this.totalTime / 4 * 3);
        this.createWFGrid(this.totalTime);

        let columnSeries = this.oeeWaterfall.series.push(new am4charts.ColumnSeries());
        columnSeries.dataFields.categoryY = "category";
        columnSeries.dataFields.valueX = "value";
        columnSeries.dataFields.openValueX = "open";
        columnSeries.sequencedInterpolation = true;
        columnSeries.interpolationDuration = 1500;

        let columnTemplate = columnSeries.columns.template;
        columnTemplate.strokeOpacity = 0;
        columnTemplate.propertyFields.fill = "color";

        let label = columnTemplate.createChild(am4core.Label);
        label.text = "{duration}";
        label.align = "center";
        label.valign = "middle";

        this.oeeWaterfall.cursor = new am4charts.XYCursor();
        this.oeeWaterfall.cursor.fullWidthLineY = true;
        this.oeeWaterfall.cursor.yAxis = categoryAxis;
        this.oeeWaterfall.cursor.lineY.strokeOpacity = 0;
        this.oeeWaterfall.cursor.lineY.fill = "#ccc";
        this.oeeWaterfall.cursor.lineY.fillOpacity = 0.2;

    }

    private createWFGrid(value) {
        var range = this.wfValueAxis.axisRanges.create();
        range.value = value;
        range.label.text = `${this._oeeUtility.convertMinutesToText(value)}`;
      }

    private async inputNewDataOeeWaterfall() {
        this.oeeWaterfall.data = this.graphCategories.reverse();
        this.wfValueAxis.max = this.totalTime + (this.totalTime / 20);
        this.wfValueAxis.axisRanges.clear();
        this.createWFGrid(0);
        this.createWFGrid(this.totalTime / 4);
        this.createWFGrid(this.totalTime / 2);
        this.createWFGrid(this.totalTime / 4 * 3);
        this.createWFGrid(this.totalTime);
    }

    public async onCheckPlannedChange(event: MatCheckboxChange) {
        if (event.checked != this.considerPlanned) {
            this.considerPlanned = event.checked;
            localStorage.setItem('considerPlannedInOEE', this.considerPlanned.toString());
            await this.getData();
        }
    }

    public async onCheckChangeOverChange(event: MatCheckboxChange) {
        if (event.checked != this.considerChangeOver) {
            this.considerChangeOver = event.checked;
            localStorage.setItem('considerChangeOver', this.considerChangeOver.toString());
            await this.getData();
        }
    }

    public async onSlideSchedulesChange(event: MatSlideToggleChange) {
        if (event.checked != this.considerSchedules) {
            this.considerSchedules = event.checked;
            localStorage.setItem('considerSchedules', this.considerSchedules.toString());
            this.parseCategoriesForGraph();
            this.inputNewDataOeeWaterfall();
        }
    }

    private async checkOAE() {

        this.oae = localStorage.getItem('oae') ? localStorage.getItem('oae') === 'true' : false;

        if (this.oae) {
            this.navbar.setTitle('oae');
        } else {
            this.oae = false;
            this.navbar.setTitle('oee');
        }
    }

    
};
