import { Component, ViewEncapsulation, OnInit, Input, ViewChild } from '@angular/core';
import { ProductionGraphCategoryComponent } from '../production-graph-category/production-graph-category.component';
import { ProductionGraphItem } from './production-graph.interface';
import { TooltipComponent } from '@angular/material';

@Component({
    selector: 'production-graph',
    templateUrl: 'production-graph.component.html',
    styleUrls: ['./production-graph.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductionGraphComponent implements OnInit {
    @Input() item: ProductionGraphItem;
    @Input() index: number;
    @ViewChild('category') category: ProductionGraphCategoryComponent;
    @ViewChild('tooltip') tooltip: TooltipComponent;
    itemDuration: number;
    async ngOnInit() {
        this.itemDuration = 0;
        this.item.items.forEach((category) => {
            this.itemDuration += parseInt(category.duration);
        });
    }
}
