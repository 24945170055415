import { DurationPipe } from './pipes/duration.pipe';
import { ShortDurationPipe } from './pipes/shortDuration.pipe';
import { DateTimeFormatPipe } from './pipes/dateTimeFormat.pipe';
import { ProductService } from './services/product.service';
import { PiecesScrapsComponent } from './components/pages/pieces-scraps/pieces-scraps.component';
import { StopTypePipe } from './pipes/stopType.pipe';
import { SetStopNoteDialogComponent } from './components/ui/dialog-set-stop-note/dialog-set-stop-note.component';
import { SetStopErrorDialogComponent } from './components/ui/dialog-set-stop-error/dialog-set-stop-error.component';
import { ScrapsInfoDialogComponent } from './components/ui/dialog-scraps-info/dialog-scraps-info.component';
import { CompanyService } from './services/company.service';
import { ErrorsService } from './services/error.service';
import { ThemeService } from './services/theme.service';
import { StopsTableComponent } from './components/ui/stopsTable/stopsTable.component';
import { LimitToPipe } from './pipes/limitTo.pipe';
import { SlicePipe } from './pipes/slice.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { StatsService } from './services/stats.service';
import { ActiveFiltersService } from './services/active-filters.service';
import { LoaderService } from './services/loader.service';

import { NavBarComponent } from './components/ui/navbar/navbar.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import {MatNativeDateModule} from '@angular/material';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatIcon,
    MatIconModule,
    MatDatepickerModule
} from '@angular/material';

import { AppComponent } from './app.component';
import { FilterBarComponent } from './components/ui/filter-bar/filter-bar.component';
import { DateFilterComponent } from './components/ui/date-filter/date-filter.component';

import { RealtimeComponent } from './components/pages/realtime/realtime.component';
import { LoginComponent } from './components/pages/login/login.component';
import { StopsComponent } from './components/pages/stops/stops.component';
import { MtbfMttrComponent } from './components/pages/mtbf-mttr/mtbf-mttr.component';
import { OeeComponent } from './components/pages/oee/oee.component';
import { OeeTrendsComponent } from './components/pages/oee-trends/oee-trends.component';

import { SessionService } from 'app/services/session.service';
import { RawDataService } from 'app/services/raw-data.service';
import { StopService } from 'app/services/stop-history.service';
import { LoginService } from './services/login.service';
import { HttpService } from './services/http.service';
import { DevicesService } from './services/devices.service';
import { NavbarService } from './services/navbar.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StopUtility } from './services/stop.utility';
import { OeeUtility } from './services/oee.utility';

import { CalendarModule } from 'primeng/primeng';
import { AmChartsModule } from '@amcharts/amcharts3-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TooltipComponent } from './components/ui/tooltip/tooltip.component';
import { StopsBarComponent } from './components/ui/stopsBar/stopsBar.component';
import { StopsChartsComponent } from './components/ui/stopsCharts/stopsCharts.component';
import { PercentageBarComponent } from './components/ui/percentageBar/percentageBar.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ProductionGraphComponent } from './components/ui/production-graph/production-graph.component';
import { ProductionGraphCategoryComponent } from './components/ui/production-graph-category/production-graph-category.component';
import { AggregatesComponent } from './components/pages/aggregates/aggregates.component';
import { DeviceBoxComponent } from './components/ui/device-box/device-box.component';
import { MqttService } from './services/mqtt.service';
import { UiService } from './services/ui.service';
import { FilterArrayPipe } from './pipes/filterArray.pipe';
import { StopErrorFilterPipe } from './pipes/stopErrorFilter.pipe';
import { TimerPipe } from './pipes/timer.pipe';
import { LongDurationPipe } from './pipes/longDuration.pipe';
import { Daterangepicker } from 'ng2-daterangepicker';

import localeFr from "@angular/common/locales/fr";
import localeEl from "@angular/common/locales/el";
import localeDe from "@angular/common/locales/de";
import localeIt from "@angular/common/locales/it";
import { registerLocaleData } from '@angular/common';
import { SelectScheduleDialogComponent } from './components/ui/dialog-select-schedule/dialog-select-schedule.component';
import { NonProductionPipe } from './pipes/nonProduction.pipe';
import { SettingsComponent } from './components/pages/settings/settings.component';
import { KPIService } from './services/KPI.service';
import { RealtimeLineComponent } from './components/pages/realtime-line/realtime-line.component';
import { OrderDataService } from './services/order-data.service';
import { QualityControlDashboardComponent } from './components/pages/quality-control-dashboard/quality-control-dashboard.component';
import { QualityControlService } from './services/quality-control.service';
import { QualityControlProductComponent } from './components/pages/quality-control-product/quality-control-product.component';
import { ProcessDataComponent } from './components/pages/process-data/process-data.component';
import { EditSessionDialogComponent } from './components/ui/dialog-edit-session/dialog-edit-session.component';
import { LoaderComponent } from './components/ui/loader/loader.component';
import { SnackbarService } from './services/snackbar.service';
import { DashboardSettingsService } from './services/dashboard-settings.service';
import { OrderService } from './services/order.service';
import { OperatorService } from './services/operator.service';
import { StopGroupPipe } from './pipes/stopGroup.pipe';
import { StopErrorPipe } from './pipes/stopError.pipe';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { PluginService } from './services/plugin.service';
import { PageWithLoader } from './components/pages/page-with-loader';
import {DatePipe} from '@angular/common';
import { OrderComponent } from './components/pages/order/order.component';
import { OrderDetailComponent } from './components/pages/order-detail/order-detail.component';
import { EnvConfigurationService } from './services/env-config.service';


registerLocaleData(localeFr);
registerLocaleData(localeEl);
registerLocaleData(localeDe);
registerLocaleData(localeIt);

export function initConfig(configService: EnvConfigurationService) {
    // load the config file in this function
    return () => configService.load()
  }

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'realtime',
        component: RealtimeComponent
    },
    {
        path: 'stops',
        component: StopsComponent
    },
    {
        path: 'pieces',
        component: PiecesScrapsComponent,
        data : {type : 'pieces'}
    },
    {
        path: 'scraps',
        component: PiecesScrapsComponent,
        data : {type : 'scraps'}
    },
    {
        path: 'mtbf',
        component: MtbfMttrComponent,
        data : {type : 'mtbf'}
    },
    {
        path: 'mttr',
        component: MtbfMttrComponent,
        data : {type : 'mttr'}
    },
    {
        path: 'oee',
        component: OeeComponent
    },
    {
        path: 'oee/trends',
        component: OeeTrendsComponent
    },
    {
        path: 'lines',
        component: AggregatesComponent
    },
    {
        path: 'settings',
        component: SettingsComponent
    },
    {
        path: 'realtime-line',
        component: RealtimeLineComponent
    },
    {
        path: 'qa-dashboard',
        component: QualityControlDashboardComponent
    },
    {
        path: 'qa-product/:id',
        component: QualityControlProductComponent
    },
    {
        path: 'process-data',
        component: ProcessDataComponent
    },
    {
        path: 'orders',
        component: OrderComponent
    },
    {
        path: 'orders/detail',
        component: OrderDetailComponent
    },
    {
        path: '',
        redirectTo: '/realtime',
        pathMatch: 'full'
    }
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RealtimeComponent,
        RealtimeLineComponent,
        PiecesScrapsComponent,
        NavBarComponent,
        FilterBarComponent,
        DateFilterComponent,
        StopsComponent,
        StopsChartsComponent,
        MtbfMttrComponent,
        OeeComponent,
        OeeTrendsComponent,
        AggregatesComponent,
        LimitToPipe,
        SlicePipe,
        SafePipe,
        StopTypePipe,
        StopGroupPipe,
        StopErrorPipe,
        NonProductionPipe,
        DurationPipe,
        ShortDurationPipe,
        DateTimeFormatPipe,
        FilterArrayPipe,
        TimerPipe,
        LongDurationPipe,
        StopErrorFilterPipe,
        StopsTableComponent,
        SetStopErrorDialogComponent,
        SetStopNoteDialogComponent,
        EditSessionDialogComponent,
        ScrapsInfoDialogComponent,
        SelectScheduleDialogComponent,
        ProductionGraphComponent,
        ProductionGraphCategoryComponent,
        DeviceBoxComponent,
        TooltipComponent,
        StopsBarComponent,
        PercentageBarComponent,
        SettingsComponent,
        QualityControlDashboardComponent,
        QualityControlProductComponent,
        ProcessDataComponent,
        OrderComponent,
        OrderDetailComponent,
        ClickOutsideDirective,
        LoaderComponent,
        PageWithLoader
    ],
    imports: [
        RouterModule.forRoot(appRoutes),
        NgxMaterialTimepickerModule,
        NgxDatatableModule,
        NoopAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpModule,
        CalendarModule,
        AmChartsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        Daterangepicker,
        HttpClientModule,
        DragDropModule,
        MatIconModule,
        MatNativeDateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [EnvConfigurationService],
            multi: true
        },
        SessionService,
        RawDataService,
        StopService,
        LoginService,
        HttpService,
        DevicesService,
        ProductService,
        ErrorsService,
        CompanyService,
        ThemeService,
        NavbarService,
        UiService,
        ActiveFiltersService,
        StatsService,
        MqttService,
        KPIService,
        OrderDataService,
        QualityControlService,
        DurationPipe,
        ShortDurationPipe,
        FilterArrayPipe,
        StopErrorFilterPipe,
        ClickOutsideDirective,
        LoaderService,
        SnackbarService,
        DashboardSettingsService,
        StopUtility,
        OeeUtility,
        OrderService,
        OperatorService,
        PluginService,
        MatDatepickerModule,
        DatePipe
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        SetStopErrorDialogComponent,
        SetStopNoteDialogComponent,
        EditSessionDialogComponent,
        ScrapsInfoDialogComponent,
        SelectScheduleDialogComponent
    ]
})
export class AppModule { }
