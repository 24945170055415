import { Injectable } from '@angular/core';
import { Http, RequestOptions, RequestOptionsArgs, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class HttpService {
  private defaultOptions: any;

  constructor(private http: Http, 
    private router: Router,
    private envSettings: EnvConfigurationService) {
    
    this.setDefaultHeaders({});
    /* Following code is not working because "timeout" and "X-Request-Timeout" headers
      should be allowed and enabled on the backend */
    // this.setDefaultHeaders({
    //   'timeout': '10000',
    //   'X-Request-Timeout': '10000'
    // });
  }

  /**
   * set headers to use for every request
   *
   * @param {*} headers
   *
   * @memberOf HttpService
   */
  setDefaultHeaders(headers: any): void {
    if (!this.defaultOptions) {
      this.defaultOptions = {};
    }
    this.defaultOptions.headers = headers;
  }

  addDefaultHeader(headerKey: string, headerValue): void {
    this.defaultOptions.headers[headerKey] = headerValue;
  }

  private addDefaultOptions(options: RequestOptionsArgs) {
    let _options = new RequestOptions(this.defaultOptions);
    
    //Merge request options
    if(options) {
      _options = _options.merge(options);
    
      //Need to manually merge headers because RequestOptions doesn't do it
      if (options.headers) {
        // TODO: this is now working for xslx report download - check later how to merge all the headers (if needed)
        // for (let key of Object.keys(this.defaultOptions.headers)) {
        //   _options.headers.set(key, this.defaultOptions.headers[key]);
        // }
        for (let key of options.headers.keys()) {
          _options.headers.set(key, options.headers.get(key));
        }
      }
    }
    
    return _options;
  }

  getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
    if (options == null) {
      options = new RequestOptions();
    }
    if (options.headers == null) {
      options.headers = new Headers();
    }
    options.headers.append('Content-Type', 'application/json');

    return options;
  }

  intercept(observable: Observable<Response>): Observable<Response> {
    return observable.catch((err, source) => {
      if (err.status === 401 && !err.url.endsWith('/login')) {
        this.router.navigateByUrl('/login');
        return Observable.throwError(err);
      } else {
        return Observable.throwError(err);
      }
    });
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    if (this.envSettings.settings.httpTimeoutMs && url.indexOf("/data") >= 0) {
      return this.intercept(this.http.get(url, options).timeoutWith(this.envSettings.settings.httpTimeoutMs, Observable.throwError(new Error("Http Timeout"))));
    } else {
      return this.intercept(this.http.get(url, options));
    }
  }

  post(url: string, body?: any, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.http.post(url, body, options);
  }
  
  put(url: string, body?: any, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.intercept(this.http.put(url, body, options));
  }
  
  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.http.delete(url, options);
  }
}
