import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { DashboardUserSettings } from 'app/models/user-dashboard-settings';
import { EnvConfigurationService } from "./env-config.service";

@Injectable()
export class DashboardSettingsService {

    constructor(private httpService: HttpService,
        private envSettings: EnvConfigurationService) { }



    /**
     * Get all KPI
     *
     * @returns
     *
     * @memberOf KPIService
     */
    async getUserDashboardSettings(): Promise<DashboardUserSettings[]> {
        try {
            const url = `${this.envSettings.settings.apiBase}/dashboard/settings`;

            return this.httpService
                        .get(url)
                        .toPromise()
                        .then(response => response.json() as DashboardUserSettings[]);
           
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Upload all User Dashboard Settings
     *
     * @returns
     *
     * @memberOf DashboardSettingsService
     */
    async uploadUserDashboardSettings(data: any[]): Promise<number> {
        try {
            const url = `${this.envSettings.settings.apiBase}/dashboard/settings`;

            return this.httpService
                        .put(url, data)
                        .toPromise()
                        .then(response => response.status);
        } catch (err) {
            console.error(err);
        }
    }

    isSettingValueEnabled(userSettingValue: any): boolean {
        if (typeof userSettingValue == "undefined" || userSettingValue == null) return false;

        if (typeof userSettingValue === 'boolean') {
            return userSettingValue;
        }
        else if (userSettingValue === 1 || userSettingValue === 0) {
            userSettingValue == 1 ? true : false;
        } 
        else if (userSettingValue === "true" || userSettingValue === "1") {
            return true;
        }
        else if (userSettingValue === "false" || userSettingValue === "0") {
            return false;
        }
    }

}