import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from '../../../services/devices.service';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';

import { RawDataService } from 'app/services/raw-data.service';

import { NavbarService } from '../../../services/navbar.service';
import { Device } from '../../../models/device';
import { DeviceBoxComponent } from '../../ui/device-box/device-box.component';
import { MqttService } from '../../../services/mqtt.service';
import { LoginService } from '../../../services/login.service';
import { SnackbarService } from 'app/services/snackbar.service';
import { SnackbarType } from 'app/models/snackbar';
import { PageWithLoader } from "../page-with-loader";
import { ThemeService } from "../../../services/theme.service";

@Component({
    templateUrl: './aggregates.component.html',
    styleUrls: ['./aggregates.component.scss']
})
export class AggregatesComponent extends PageWithLoader implements OnInit, OnDestroy {

    @ViewChild('deviceBox') deviceBox: DeviceBoxComponent;
    devices: Device[];
    lockRequest = true;
    gridNumber: number;
    connected = false;
    hideButtons = false;
    buttonTimeout: any;
    sliderInterval: any;
    selectedPage = 1;
    pageNumber = 1;
    pageArray: number[];
    isFullScreen = false;
    showDisconnected = false;
    constructor(
        private navbar: NavbarService,
        private router: Router,
        private _translate: TranslateService,
        private _devicesService: DevicesService,
        public _mqttService: MqttService,
        private _loginService: LoginService,
        private _uiService: SnackbarService,
        _themeService: ThemeService
        ) {
          super(_themeService);
        }


    async ngOnInit() {
        this._translate.get('realtime.real_time').subscribe((res: string) => {
            this.navbar.setTitle(res);
        });
        this.showDisconnected = (this._loginService.readFromLocalStorage("showDisconnected") === "true");

        //await nell' onInit non è corretto, non funziona, angular non aspetta ngOnInit
        await this.getData(this.showDisconnected);
        await this.connectToMqtt();
    }

    ngOnDestroy() {
        if (this.connected) {
            this._mqttService.disconnect();
        }
    }

    /**
    * Function to navigate to another view
    *
    * @param {any} path
    * @memberof RealtimeComponent
    */
    navigate(path) {
        this.router.navigateByUrl(path);
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof HomeComponent
    */
    async getData(show) {
        this.lockRequest = true;
        let tmpDevices;
        tmpDevices = await this._devicesService.getDevices();
        if (!show) {
            tmpDevices = tmpDevices.filter((device) => device.state > 0);
            this.showDisconnected = false;
            this._loginService.writeInLocalStorage("showDisconnected", "false");
        } else {
            this.showDisconnected = true;
            this._loginService.writeInLocalStorage("showDisconnected", "true");
        }
        tmpDevices.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        tmpDevices.sort((a, b) => {
            if (a.state > 0 && b.state === 0) {
                return -1;
            }
            if (a.state === 0 && b.state > 0) {
                return 1;
            }
            return 0;
        });
        this.devices = tmpDevices;
        this.pageArray = [];
        if (this.devices.length > 4) {
            this.gridNumber = 6;
            this.pageNumber = (this.devices.length / 6) > Math.floor(this.devices.length / 6) ? Math.floor(this.devices.length / 6) + 1 : Math.floor(this.devices.length / 6);
            for (let i = 0; i < this.pageNumber; i++) {
                this.pageArray.push(i + 1);
            }
        } else {
            this.gridNumber = 4;
            this.pageArray = [1];
            this.pageNumber = 1;
        }
        this.selectedPage = 1;
        if (this._mqttService.isConnected()) {
            this.lockRequest = false;
        }
    }

    async connectToMqtt() {
        try {
          // get the token
          await this._mqttService.connect(this._loginService.readFromLocalStorage('user'), this._loginService.readFromLocalStorage('token'));
          this.lockRequest = false;
          this.connected = true;
        } catch (err) {
            this.connected = false;
            this._uiService.showSnackbar('Error retrieving devices status.\nPlease try again later.',SnackbarType.error, { verticalPosition: 'bottom'});
        }
    }

    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        this.resetTimeout();
    }

    resetTimeout() {
        this.hideButtons = false;
        if (this.buttonTimeout) {
            clearTimeout(this.buttonTimeout);
        }
        if (this.sliderInterval) {
            clearInterval(this.sliderInterval);
        }
        this.buttonTimeout = setTimeout(() => {
            this.hideButtons = true;
            this.sliderInterval = setInterval(() => {
                if (this.selectedPage === this.pageNumber) {
                    this.selectedPage = 1;
                } else {
                    this.selectedPage++;
                }
            }, 30000);
        }, 5000);
    }

    openFullScreen() {
        // Trigger fullscreen
        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
          mozRequestFullScreen(): Promise<void>;
          webkitRequestFullscreen(): Promise<void>;
          msRequestFullscreen(): Promise<void>;
        };
      
        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
          docElmWithBrowsersFullScreenFunctions.requestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
          docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
          docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }
        this.isFullScreen = true;
      }
      closeFullScreen(){
        const docWithBrowsersExitFunctions = document as Document & {
          mozCancelFullScreen(): Promise<void>;
          webkitExitFullscreen(): Promise<void>;
          msExitFullscreen(): Promise<void>;
        };
        if (docWithBrowsersExitFunctions.exitFullscreen) {
          docWithBrowsersExitFunctions.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
          docWithBrowsersExitFunctions.mozCancelFullScreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          docWithBrowsersExitFunctions.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
          docWithBrowsersExitFunctions.msExitFullscreen();
        }
        this.isFullScreen = false;
      }

    prevPage() {
        if (this.selectedPage > 1) {
            this.selectedPage--;
        }
        this.resetTimeout();
    }

    nextPage() {
        if (this.selectedPage < this.pageNumber) {
            this.selectedPage++;
        }
        this.resetTimeout();
    }
};
