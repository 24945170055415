import { Component, ViewEncapsulation, OnInit, Input, ViewChild } from '@angular/core';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { StopBarItem } from './stopBarItem.interface';

@Component({
    selector: 'app-stops-bar',
    templateUrl: 'stopsBar.component.html',
    styleUrls: ['./stopsBar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StopsBarComponent {
    @Input() stop: StopBarItem;
    @Input() isPlanned: boolean;
    @Input() isFrequencyChart: boolean;
    @ViewChild('tooltip') tooltip: TooltipComponent;
}
