import { ErrorTypeString, StopTableRow } from '../components/ui/stopsTable/stopTableRow.interface';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'shortDurationPipe'
})
export class ShortDurationPipe implements PipeTransform {

    transform(minutes: number): string {
        if (minutes < 0) {
            return '-';
        }
        const hoursAbs = Math.floor(minutes / 60);
        const minutesAbs = Math.floor(minutes - hoursAbs * 60);
        const secondsAbs = Math.floor((minutes - minutesAbs - hoursAbs * 60) * 60);

        let ret = '';
        if (hoursAbs) {
            ret += hoursAbs + 'h ';
        }
        if (minutesAbs) {
            ret += minutesAbs + 'm ';
        }
        if (secondsAbs) {
            ret += secondsAbs + 's';
        }
        return ret;
    }

}