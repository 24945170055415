import { ErrorTypeString, StopTableRow } from '../components/ui/stopsTable/stopTableRow.interface';
import { PipeTransform, Pipe, ViewEncapsulation } from '@angular/core';

@Pipe({
    name: 'durationPipe'
})
export class DurationPipe implements PipeTransform {

    transform(minutes: number, isHtml?: string): string {
        const hoursAbs = Math.floor(minutes / 60);
        const minutesAbs = Math.floor(minutes - hoursAbs * 60);
        const secondsAbs = Math.floor((minutes - minutesAbs - hoursAbs * 60) * 60);

        let ret = '';
        if (hoursAbs) {
            if (hoursAbs < 10) {
                ret += ('0' + hoursAbs);
            } else {
                ret += hoursAbs;
            }
            if (isHtml === 'html') {
                ret += '<span class="percentage">h</span> ';
            } else {
                ret += 'h ';
            }
        }
        if (minutesAbs || hoursAbs) {
            ret += ('00' + minutesAbs).slice(-2);
            if (isHtml === 'html') {
                ret += '<span class="percentage">m</span> ';
            } else {
                ret += 'm ';
            }
        }
        ret += ('00' + secondsAbs).slice(-2);
        if (isHtml === 'html') {
            ret += '<span class="percentage">s</span>';
        } else {
            ret += 's';
        }
        return ret;
    }

}
