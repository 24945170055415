import { Session } from './session';
import { Product } from './product';
import { ErrorModel } from './error';

export enum SensorType {
    standard = 0,
    delta = 1
};
export interface UniPi {
    id: number;
    serial: string;
    initialized: boolean;
    createdAt: Date;
    updatedAt: Date;
    sensorType: SensorType;
}
export interface ProductionLine {
    id: number;
    plant?: number;
    description: string;
}
export class Device {
    id: number;
    serial: string;
    location: any;
    sessionType: number;
    createdAt: Date;
    updatedAt: Date;
    address: string;
    categoryId: number;
    state: number;
    place: any;
    MAC?: string;
    label: string;
    companyId: number;
    productionState: number;
    errorDate: Date;
    plantId: number;
    Unipi: UniPi;
    Session?: Session;
    Product?: Product;
    Error?: ErrorModel;
    ProductionLine?: ProductionLine;
    Company?: Company;
    Plant?: Plant;
};

export interface Company {
    id: number;
    name: string;
    address?: string;
    cap?: string;
    city?: string;
    country?: string;
    createdAt?: Date;
    note?: string;
    province?: string;
    satelliteUrl?: string;
    taxCode?: string;
    updatedAt?: string;
    vatCode?: string;
}

export interface Plant {
    id: number,
    companyId: number,
    description: string
}

export interface DeviceInPlant {
    plant: Plant;
    devices: Device[]
}
