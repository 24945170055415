import { Product } from '../models/product';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class ProductService {
    constructor(private httpService: HttpService,
        private envSettings: EnvConfigurationService) { }

    /**
     * Method to get all products
     *
     * @memberof ProductService
     */
    async getProducts(deviceId?: number, dateFrom?: string, dateTo?: string): Promise<Product[]> {
        let url = `${this.envSettings.settings.apiBase}/product?deviceId=${deviceId}`;

        if (dateFrom && dateTo) {
            url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as Product[];
    }
}
