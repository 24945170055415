import {ActiveFiltersService} from "../../../services/active-filters.service";
import {Router} from "@angular/router";
import {DevicesService} from "../../../services/devices.service";
import {Device, DeviceInPlant} from "../../../models/device";
import {Component, Input, OnInit} from "@angular/core";
import {StopService} from "../../../services/stop-history.service";
import {LoginService} from "../../../services/login.service";
import {LoaderService} from "../../../services/loader.service";

import {SnackbarService} from "../../../services/snackbar.service";
import {SnackbarType} from "../../../models/snackbar";
import {TranslateService} from "@ngx-translate/core";

import * as moment from "moment/moment";
import * as _ from "lodash";
import {FormControl} from "@angular/forms";
import { QualityControlService } from 'app/services/quality-control.service';
import { environment } from "environments/environment";
import { OrderDataService } from "app/services/order-data.service";
import { OrderService } from "app/services/order.service";
import { EnvConfigurationService } from "app/services/env-config.service";

@Component({
    selector: 'app-filter-bar',
    templateUrl: './filter-bar.component.html',
    styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {

    // to lock data request if is already processing
    private _filterSubscription;
    @Input() multipleSelectEnabled: boolean = false;
    @Input() startDate: Date;
    @Input() endDate: Date;
    devicesList: Device[];
    productionLinesDeviceList: DeviceInPlant[];
    private countShow = 0;
    
    showTimeout: any;
    deviceFormControl: FormControl;
    isAllProducts: boolean = false;
    isAllOrdersCore: boolean = false;
    showOrderList: boolean = false;
    private _deviceFormControlSubscription;

    constructor(
        public activeFilters: ActiveFiltersService,
        private _deviceService: DevicesService,
        private _router: Router,
        private _stopDataService: StopService,
        private _loginService: LoginService,
        private _loaderService: LoaderService,
        private _snackbarService: SnackbarService,
        private _translateService: TranslateService,
        private _qualityControlService: QualityControlService,
        private _orderService: OrderService,
        private envSettings: EnvConfigurationService
    ) {
        this.deviceFormControl = new FormControl();
    }

    /**
     * Triggered when the date is changed
     *
     * @param {any} res
     *
     * @memberOf FilterBarComponent
     */
    dateChanged(res) {
        this.activeFilters.dateBegin = res[0];
        this.activeFilters.dateEnd = res[1];
        this.filterChanged();
    }

    /**
     * Triggered when a filter is changed
     *
     *
     * @memberOf FilterBarComponent
     */
    filterChanged() {
        this.activeFilters.emitChangeEvent();
        this.isAllOrdersCore = this.activeFilters.orderCoreId > 0 ? true : false;
        this.isAllProducts = this.activeFilters.productId > 0 ? true : false;
    }

    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isRootPage(): boolean {
        return this._router.isActive('/realtime', true) || this._router.isActive('/realtime-line', true);
    }

    /**
     * Check if the current page is the stop page
     *
     * @memberof FilterBarComponent
     */
    public isStopPage(): boolean {
        return this._router.isActive('/stops', true);
    }

    /**
     * Check if the current page is the orders page
     *
     * @memberof FilterBarComponent
     */
    public isOrderPage(): boolean {
        return this._router.isActive('/orders', true);
    }

    
    /**
     * Check if the current page is the qa pages
     *
     * @memberof FilterBarComponent
     */
    public isQualityControl(): boolean {

        if (this._router.isActive('/qa-product', true) ||
                this._router.isActive('/qa-detail', true)) {

            return true;

        } else return false;
    }

    public isQualityDashboard(): boolean {
        return this._router.isActive('/qa-dashboard', true) ? true : false;
    }

    /**
     * Disable product filter if there is the condition
     *
     * @memberof FilterBarComponent
     */
    public disableFilter(): boolean {
        if (!this._router.isActive('/oee', true) && !this._router.isActive('/oee/trends', true)) {
            return false;
        } else {
            return true;
        }
    }

    goBack() {
        if (this._router.isActive('/oee/trends', true)) {
            this._router.navigateByUrl('/oee');
        } else {
            if (this.activeFilters.device.ProductionLine) {
                this._router.navigateByUrl('/realtime-line');
            } else {
                this._router.navigateByUrl('/realtime');
            }
        }
    }

    openSmartTV() {
        window.open(this.envSettings.settings.smartTvUrl, '_blank');
    }

    downloadReport() {
        const momentStart = moment(this.activeFilters.dateBegin);
        const momentEnd = moment(this.activeFilters.dateEnd);

        const diff = momentEnd.diff(momentStart, 'days')

        let limit: number = 32;
        if (this.activeFilters.existShiftInRange) {
            limit = 32;
        } else {
            limit = 366;
        }
        if(diff > limit) {
            const message = this._translateService.instant('errors.stops.report-download-filter-exceed');
            this._snackbarService.showSnackbar(message, SnackbarType.error);
            return;
        }
        const loaderMessage = this._translateService.instant('stops.download_report_start');
        this._loaderService.startLoader(loaderMessage);

        this._stopDataService.getReport(
                        this.activeFilters.deviceId,
                        this.activeFilters.dateBegin,
                        this.activeFilters.dateEnd,
                        this.activeFilters.productId)
                    .then(
                        res => {
                            let contentType =  res.headers.get('Content-Type');
                            let contentDisposition = res.headers.get('Content-Disposition');
                            let fileName = 'report.xlsx';

                            //Get file name from content-disposition;
                            try {
                                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                    var matches = filenameRegex.exec(contentDisposition);
                                    if (matches != null && matches[1]) {
                                        fileName = matches[1].replace(/['"]/g, '');
                                    }
                                }
                            } catch(ex) {}

                            this._loaderService.stopLoader();
                            this.downloadFile(fileName, contentType, res['_body']);
                        },
                        error => { // Error
                            this._loaderService.stopLoader();
                            const message = this._translateService.instant('errors.stops.report-download-failed');
                            this._snackbarService.showSnackbar(message, SnackbarType.error);

                        }
                    );
    }

    downloadQCReport() {
        const momentStart = moment(this.activeFilters.dateBegin);
        const momentEnd = moment(this.activeFilters.dateEnd);

        const diff = momentEnd.diff(momentStart, 'days')

        const loaderMessage = this._translateService.instant('qa-dashboard.download_report_start');
        this._loaderService.startLoader(loaderMessage);

        this._qualityControlService.getReport(
                        this.activeFilters.deviceId,
                        this.activeFilters.dateBegin,
                        this.activeFilters.dateEnd,
                        this.activeFilters.productId)
                    .then(
                        res => {
                            let contentType =  res.headers.get('Content-Type');
                            let contentDisposition = res.headers.get('Content-Disposition');
                            let fileName = 'qc_report.xlsx';

                            //Get file name from content-disposition;
                            try {
                                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                    var matches = filenameRegex.exec(contentDisposition);
                                    if (matches != null && matches[1]) {
                                        fileName = matches[1].replace(/['"]/g, '');
                                    }
                                }
                            } catch(ex) {}

                            this._loaderService.stopLoader();
                            this.downloadFile(fileName, contentType, res['_body']);
                        },
                        error => { // Error
                            this._loaderService.stopLoader();
                            const message = this._translateService.instant('errors.stops.report-download-failed');
                            this._snackbarService.showSnackbar(message, SnackbarType.error);

                        }
                    );
    }

    private downloadFile(filename, type, data) {
        var a = document.createElement('a');
        a.setAttribute('style','display:none')
        var blob = new Blob([data], {type: type});
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },1000)
    }

    async ngOnInit() {
        
        this.isAllOrdersCore = this.activeFilters.orderCoreId > 0 ? true : false;
        this.isAllProducts = this.activeFilters.productId > 0 ? true : false;

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        this.devicesList = await this._deviceService.getDevices();
        let plantDeviceList = _.groupBy(this.devicesList, d => d.Plant ? d.Plant.id : -1);
        this.productionLinesDeviceList =
            Object
                .keys(plantDeviceList)
                .map(plantId => {
                    return <DeviceInPlant>{
                        plant : plantDeviceList[plantId][0].Plant || {id : -1, number : undefined, description : 'None'},
                        devices: plantDeviceList[plantId]
                    }
                });

        if(this.multipleSelectEnabled) {
            let deviceSelected = this.devicesList.filter(d => this.activeFilters.deviceIds.includes(d.id));
            this.deviceFormControl.patchValue(deviceSelected);
        }

        this._deviceFormControlSubscription = this.deviceFormControl.valueChanges.subscribe(devices => {
            if(devices.length === 0) {
                devices = this.devicesList.filter(d => this.activeFilters.deviceId === d.id);
                this.deviceFormControl.patchValue(devices, {emitEvent: false});
            }
           this.activeFilters.deviceIds = devices.map(d => d.id);
           this.filterChanged();
        });

        this._reBuildDeviceList();
    }

    onFilterChanged() {
        if (this.devicesList) {
            this._reBuildDeviceList();
        } else return;
    }

    /**
     * Rebuilt device list whith production Line
     *
     * @memberof FilterBarComponent
     */
    public _reBuildDeviceList() {
        let productionLines = this.devicesList.filter((device) => device.ProductionLine);

        const activeDevice = this.activeFilters.device || this.devicesList
        .find((item) => item.id.toString() === this._loginService.readFromLocalStorage('deviceId'));

        this.devicesList = this.devicesList.filter((device) => !device.ProductionLine);

        let lines = [];

        if ((activeDevice && activeDevice.ProductionLine)) {
            activeDevice.label = activeDevice.ProductionLine.description;
            lines.push(activeDevice.ProductionLine);
            this.devicesList.push(activeDevice);
        }

        for (let device of productionLines) {
            if (!lines.find((item) => item.id === device.ProductionLine.id)) {
                device.label = device.ProductionLine.description;
                lines.push(device.ProductionLine);
                this.devicesList.push(device);
            };
        };
    }
    /**
     * Get a dowload report link for the XLS
     *
     * @private
     * @returns {string}
     *
     * @memberOf StopsComponent
     */
    private _getXLSLink(): string {
        return this._stopDataService.getXLSLink(this.activeFilters.deviceId, this.activeFilters.dateBegin, this.activeFilters.dateEnd, this.activeFilters.productId);
    }

    /**
     * Check if the current user is admin.
     *
     *
     * @memberof FilterBarComponent
     */
    isAdmin() {
        return this._loginService.readFromLocalStorage('user') === 'admin@techmass.it';
    }

    /**
     * Check if the current page is line page.
     *
     *
     * @memberof FilterBarComponent
     */
    isLinePage() {
        return this._router.isActive('realtime-line', true);
    }

    async downloadOrdersExcel() {        
        const loaderMessage = this._translateService.instant('orders.download_report_start');
        this._loaderService.startLoader(loaderMessage);

        this._orderService.getReport(
            this.activeFilters.device && this.activeFilters.device.Plant? this.activeFilters.device.Plant.id : null,
            this.activeFilters.dateBegin,
            this.activeFilters.dateEnd)
            .then(res => {
                let contentType =  res.headers.get('Content-Type');
                let contentDisposition = res.headers.get('Content-Disposition');
                let fileName = 'orders_report.xlsx';

                //Get file name from content-disposition;
                try {
                    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, '');
                        }
                    }
                } catch(ex) {}

                this._loaderService.stopLoader();
                this.downloadFile(fileName, contentType, res['_body']);
            }, error => { // Error
                this._loaderService.stopLoader();
                const message = this._translateService.instant('errors.orders.report-download-failed');
                this._snackbarService.showSnackbar(message, SnackbarType.error);

            }
        );
    }

};