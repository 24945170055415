import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NavbarService } from 'app/services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { KPIService } from 'app/services/KPI.service';
import { UserKPISetting } from 'app/models/KPI';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UiService } from 'app/services/ui.service';
import { Router } from '@angular/router';
import { DashboardSettingsService } from 'app/services/dashboard-settings.service';
import { DashboardUserSettings, DashboardSettingInstance } from 'app/models/user-dashboard-settings';
import { DashboardSettingsGroups, DashboardSettingsLabel } from 'app/models/user-dashboard-settings';


@Component({
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SettingsComponent implements OnInit {

    kpis: UserKPISetting[];
    dashboardUserSettings: DashboardUserSettings[];
    dateFiltersOptions: DashboardUserSettings[];
    oae: boolean;

    constructor(
        private navbar: NavbarService,
        private _translate: TranslateService,
        private _kpi: KPIService,
        private _dashboardSettings: DashboardSettingsService,
        private _ui: UiService,
        private _router: Router
    ) { }

    async ngOnInit(): Promise<void> {

        this._translate.get('setting.title').subscribe((res: string) => {
            this.navbar.setTitle(res);
        });

        this._translate.onLangChange.subscribe(() => {
            this.navbar.setTitle(this._translate.instant('setting.title'));
        });

        this.oae = localStorage.getItem('oae') ? localStorage.getItem('oae') === 'true' : false;

        // fetch all KPI
        this.kpis = await this._kpi.getUserKPI();

        this._moveOaeInLastPosition();

        this.kpis = this.kpis.slice(0, this.kpis.length - 1);

        this.dashboardUserSettings = await this._dashboardSettings.getUserDashboardSettings();
        this.dateFiltersOptions = this.filterSettingsByGroup(DashboardSettingsGroups.datefilters);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.kpis, event.previousIndex, event.currentIndex);

        this.kpis.forEach((kpi, index) => {
            kpi.order = index + 1;
        });


    }

    private _moveOaeInLastPosition() {
        const oaeIndex = this.kpis.findIndex((item) => item.KPISetting.id === 9); 

        moveItemInArray(this.kpis, oaeIndex, this.kpis.length -1);

        this.kpis.forEach((kpi, index) => {
            kpi.order = index + 1;
        });
    }

    async saveOrderKPI() {

        let kpis: UserKPISetting[] = [];

        this.kpis.forEach(kpi => {
            kpis.push({ KPISettingId: kpi.KPISettingId, order: kpi.order});
        });

        kpis.push({ KPISettingId: 9, order: kpis.length });

        const res = await this._kpi.uploadUserKPI(kpis);

        if (res === 200) {

            this._translate.get('setting.success').subscribe((res) => {
                this._ui.openSnackBar(res);
            });

            this._router.navigateByUrl('/realtime');

        } else {

            this._translate.get('setting.error').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
    }
    
    async saveFilteringOptions() {
        let userSettings: DashboardUserSettings[] = [];

        this.dashboardUserSettings.forEach(aSetting => {
            userSettings.push({
                dashboardSettingId: aSetting.DashboardSetting.id, 
                value: aSetting.value
            });
        });

        const res = await this._dashboardSettings.uploadUserDashboardSettings(userSettings);
        if (res === 200) {
            this.dashboardUserSettings.forEach(aSetting => {
                localStorage.setItem(aSetting.DashboardSetting.label, aSetting.value);
            });
            
            this._translate.get('setting.success').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        } else {
            this._translate.get('setting.error').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }        
    }

    public async cutOaeInKpiArray(kpis: UserKPISetting[]): Promise<UserKPISetting[]> {
        return kpis.filter((item) => { return item.KPISetting.id !== 9 });
    }

    public changeOaeSettings() {
        this.oae = !this.oae;
        localStorage.setItem('oae', this.oae.toString());
    }

    public switchSetting(setting: DashboardUserSettings, index: number) {
        let option = this.dashboardUserSettings.find( el => {

            return el.DashboardSetting.id == setting.DashboardSetting.id;
        });
        
        if (option) {
            if (!option.value) {
                option.value = "true";
            } else {
                if (option.value === true || option.value === false) {
                    option.value = !option.value;   
                }
                else if (option.value === 1 || option.value === 0) {
                    option.value = !option.value ? 1 : 0;
                } 
                else if (option.value === "true") {
                    option.value = "false";
                }
                else if (option.value === "1") {
                    option.value = "0"
                }
                else if (option.value === "false") {
                    option.value = "true";
                }
                else if (option.value === "0") {
                    option.value = "1"
                }
            }
        }
    }

    public isChecked(userSetting: DashboardUserSettings) {
        if (userSetting && userSetting.value) {
            return userSetting.value === true || userSetting.value === 1 || userSetting.value === "true" || userSetting.value === "1";
        }

        return false;
    }

    private filterSettingsByGroup(groupLabel: DashboardSettingsGroups) {
        if (this.dashboardUserSettings) {
            return this.dashboardUserSettings.filter( e => e.DashboardSetting.groupLabel === groupLabel);
        }

        return [];
    }

    private isSwitch(setting: DashboardSettingInstance) {
        return setting && setting.label === DashboardSettingsLabel.forcetoday;
    }
}