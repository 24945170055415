import { TranslateService } from '@ngx-translate/core';
import { Stats, OeeParams } from '../../../models/stats';
import {CronGranularity,  dataGranularities,  RawData} from '../../../models/raw-data';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AmChartsService } from '@amcharts/amcharts3-angular';

import { RawDataService } from 'app/services/raw-data.service';

import { NavbarService } from '../../../services/navbar.service';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

@Component({
    templateUrl: './oee-trends.component.html',
    styleUrls: ['./oee-trends.component.scss']
})
export class OeeTrendsComponent extends PageWithLoader implements OnInit, OnDestroy {
    // to lock data request if is already processing
    private _filterSubscription;

    stats: RawData[];
    params: OeeParams;
    oee= [];
    private chartValues: any;
    private chartScraps: any;
    private qualityString: string;
    performanceString: string;
    availabilityString: string;
    lockRequest = true;
    activeGranularity: CronGranularity;
    activeGranularityMinutes: number;
    granularities: CronGranularity[];
    /**
    * granularity not allowed for the current time filter
    *
    * @type {number[]}
    * @memberOf OeeTrendsComponent
    */
    disabledGranularities: number[] = [];
    constructor(
        private AmCharts: AmChartsService,
        private activeFilters: ActiveFiltersService,
        private changeDetectorRef: ChangeDetectorRef,
        private navbar: NavbarService,
        private router: Router,
        private route: ActivatedRoute,
        private _dataService: RawDataService,
        private _translate: TranslateService,
        _themeService: ThemeService
    ) { super(_themeService) }


    async ngOnInit() {
        this._translate.get('oee.trends').subscribe((res: string) => {
            this.navbar.setTitle('oee - ' + res);
        });
        this._translate.get('oee.quality_rate').subscribe((res: string) => {
            this.qualityString = res;
        });
        this._translate.get('oee.availability').subscribe((res: string) => {
            this.availabilityString = res;
        });
        this._translate.get('oee.uptime_efficiency').subscribe((res: string) => {
            this.performanceString = res;
        });

        // build allowed granularities
        this._buildGranularities();

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        this._computeGranularity();

        this.getData();
    }
    ngOnDestroy() {
        if (this.AmCharts && this.chartValues) {
            this.AmCharts.destroyChart(this.chartValues);
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    /**
    * Function to navigate to another view
    *
    * @param {any} path
    * @memberof OeeComponent
    */
    navigate(path) {
        this.router.navigateByUrl(path);
    }

    onFilterChanged() {
        this._computeGranularity();
        this.getData();
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof OeeComponent
    */
    async getData() {
        this.lockRequest = true;
        // this.stats = await this._dataService.getStats(this.activeFilters.dateBegin.toISOString(),
        //                                                     this.activeFilters.dateEnd.toISOString(),
        //                                                     this.activeFilters.deviceId, 0,
        //                                                     this.activeGranularity.granularityMinutes);

        this.oee = [];
        const label = [];

        await this.stats.forEach((item, index) => {
            if (index === 0 || (index !== 0 && moment(this.stats[index - 1].date).format('DD-MM-YYYY HH:mm') !== moment(item.date).format('DD-MM-YYYY HH:mm'))) {
                // this.oee.push({
                //     oee: 115,
                //     oeeText: item.Stats.oeeParams.oee || item.Stats.oeeParams.oee === 0 ? item.Stats.oeeParams.oee.toFixed(0) : '',
                //     availability: item.Stats.oeeParams.availability ? (item.Stats.oeeParams.availability / 3).toFixed(1) : item.Stats.oeeParams.availability,
                //     quality: item.Stats.oeeParams.quality ? (item.Stats.oeeParams.quality / 3).toFixed(1) : item.Stats.oeeParams.quality,
                //     performance: item.Stats.oeeParams.performance ? (item.Stats.oeeParams.performance / 3).toFixed(1) : item.Stats.oeeParams.performance,
                //     availabilityText: item.Stats.oeeParams.availability ? item.Stats.oeeParams.availability.toFixed(1) : item.Stats.oeeParams.availability,
                //     qualityText: item.Stats.oeeParams.quality ? item.Stats.oeeParams.quality.toFixed(1) : item.Stats.oeeParams.quality,
                //     performanceText: item.Stats.oeeParams.performance ? item.Stats.oeeParams.performance.toFixed(1) : item.Stats.oeeParams.performance,
                //     date: item.date
                // });
                // label.push({
                //     y: 30,
                //     text: item.Stats.oeeParams.oee || item.Stats.oeeParams.oee === 0 ? item.Stats.oeeParams.oee.toFixed(0) + '%' : ''
                // });
            }
        });

        if (this.oee.length === 0) {
            this.oee.push({
                oee: null,
                availability: null,
                quality: null,
                performance: null,
                date: this.activeFilters.dateBegin.toISOString()
            });
        }

        this.drawChart(this.oee, label);
        this.lockRequest = false;
    }


    /**
    * Function to draw the chart
    *
    * @param {any} data
    * @memberof RealtimeComponent
    */
   drawChart(oee, label) {
       const chartDefaultOptions = {
            type: 'serial',
            theme: 'light',
            hideCredits: true,
            categoryField: 'date',
            language: this._translate.currentLang,
            fontFamily: 'Roboto',
            addClassNames: true,
            dataProvider: oee,
            columnSpacing: 2,
            categoryAxis: {
                minPeriod: this.activeGranularity.granularityMinutes === 60 ? 'hh' : this.activeGranularity.granularityMinutes === 46080 ? 'MM' : 'DD',
                parseDates: true,
                fontSize: 12,
                gridAlpha: 0,
                dateFormats: [{period: 'fff', format: 'JJ:NN:SS'},
                    {period: 'ss', format: 'JJ:NN:SS'},
                    {period: 'mm', format: 'JJ:NN'},
                    {period: 'hh', format: 'JJ:NN'},
                    {period: 'DD', format: 'DD/MM'},
                    {period: 'WW', format: 'DD/MM'},
                    {period: 'MM', format: 'MM/YYYY'},
                    {period: 'YYYY' , format: 'YYYY'}]
            },
            legend: {
                align: 'center',
                equalWidths: false,
                labelText: '[[title]]',
                markerType: 'circle',
                divId: 'legend'
            },
            zoomOutButtonAlpha: 0,
            zoomOutText: '',
            zoomOutButtonImageSize: 0,
            panelsSettings: {
                marginLeft: 25,
                marginRight: 25,
                marginTop: 20,
                marginBottom: 0
            },
            chartCursorSettings: {
                enabled: true,
                pan: false,
                bulletsEnabled: true,
                valueBalloonsEnabled: true,
                graphBulletSize: 1,
                valueLineBalloonEnabled: false,
                valueLineEnabled: false,
                categoryBalloonEnabled: true,
                categoryLineEnabled: false,
                valueLineAlpha: 0.5,
                fullWidth: false,
                cursorAlpha: 0
            },
            chartScrollbarSettings: {
                enabled: false
            },
            valueAxes: [{
                stackType: 'regular',
                minimum: 0,
                maximum: 150,
                gridCount: 5,
                autoGridCount: false,
                labelFunction: (value, valueText, valueAxis) => {
                    return valueText + '%';
                },
                color: '#4b4b4b',
                boldLabels: true
            }]
        };

        const valuesOptions = _.cloneDeep(chartDefaultOptions);

        valuesOptions['graphs'] = [{
            fillAlphas: 1,
            lineAlpha: 0,
            type: 'column',
            balloonText: '<b>[[qualityText]]%</b>',
            title: this.qualityString,
            fillColors: '#f68eb1',
            valueField: 'quality',
            columnWidth: 0.85
        }, {
            fillAlphas: 1,
            lineAlpha: 0,
            type: 'column',
            balloonText: '<b>[[availabilityText]]%</b>',
            fillColors: '#7ed3fc',
            valueField: 'availability',
            title: this.availabilityString,
            // labelText: '[[oee]]',
            // fontSize: 15,
            // labelPosition: 'middle',
            // boldLabel: true,
            // color: '#4d4d4d',
            // labelOffset: 150,
            columnWidth: 0.85
        }, {
            fillAlphas: 1,
            lineAlpha: 0,
            type: 'column',
            balloonText: '<b>[[performanceText]]%</b>',
            title: this.performanceString,
            fillColors: '#9571cf',
            valueField: 'performance',
            color: '#4d4d4d',
            columnWidth: 0.85
        }, {
            id: 'total',
            valueField: 'oee',
            labelText: '[[oeeText]]%',
            classNameField: true,
            visibleInLegend: false,
            boldLabel: true,
            showBalloon: false,
            lineAlpha: 0,
            fontSize: 14
        }];
        this.chartValues = this.AmCharts.makeChart('chart-values', valuesOptions);
    }

    /**
    * Set current granularity
    *
    * @param {number} granularityMinutes
    *
    * @memberOf OeeTrendsComponent
    */
    async setGranularity(granularity: CronGranularity) {
        if (this.disabledGranularities.indexOf(granularity.granularityMinutes) !== -1) {
            return;
        }
        this.activeGranularity = granularity;
        this.activeGranularityMinutes = granularity.granularityMinutes;
        await this.getData();
    }

    /**
    * Compute a reasonable granularity based on current time filters
    *
    *
    * @memberOf OeeTrendsComponent
    */
    _computeGranularity() {
        const currentInterval = this.activeFilters.dateEnd.getTime() - this.activeFilters.dateBegin.getTime();
        const currentIntervalMinutes = currentInterval / 36000;

        // choose best granularity for the given time filter
        let bestGranularity: CronGranularity;
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 75)) {
                continue;
            } else {
                bestGranularity = granularity;
                break;
            }
        }
        this.activeGranularity = bestGranularity;
        this.activeGranularityMinutes = bestGranularity.granularityMinutes;

        // find disabled granularities for the current time filter
        // i.e. too low or too high granularities
        this.disabledGranularities = [];
        const disabledGranularities: number[] = [];
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 100)) {
                disabledGranularities.push(granularityMinutes);
            } else if (granularityMinutes > (currentIntervalMinutes / 3)) {
                disabledGranularities.push(granularityMinutes);
            }
        }
        this.disabledGranularities = disabledGranularities;
    }

    /**
    * Build allowed granularities array
    *
    *
    * @memberOf OeeTrendsComponent
    */
    private _buildGranularities() {
        const allowedGranularities = [60, 1440, 10080, 46080];

        this.granularities = dataGranularities.filter(granularity => {
            return allowedGranularities.indexOf(granularity.granularityMinutes) !== -1;
        });
    }
};
