import { StopTableRow } from '../../ui/stopsTable/stopTableRow.interface';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import { StopType } from '../../../models/stop';
import { ActivatedRoute, Router } from '@angular/router';
import { StopService } from '../../../services/stop-history.service';
import { NavbarService } from '../../../services/navbar.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '../../ui/tooltip/tooltip.component';
import { StopsChartsComponent } from '../../ui/stopsCharts/stopsCharts.component';
import { PercentageBarData } from '../../ui/percentageBar/percentageBar.interface';
import { StopUtility, StopErrorTypeLabels } from 'app/services/stop.utility';
import * as moment from 'moment/moment';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

interface SelectedStop {
    id: number;
    percentage: string;
}

@Component({
    templateUrl: './stops.component.html',
    styleUrls: ['./stops.component.scss']
})

export class StopsComponent extends PageWithLoader implements OnInit, OnDestroy {
    @ViewChild('plannedTooltip') plannedTooltip: TooltipComponent;
    @ViewChild('unPlannedTooltip') unPlannedTooltip: TooltipComponent;

    @ViewChild('stopAbstractCharts') stopAbstractCharts: StopsChartsComponent;
    @ViewChild('plannedStopsCharts') plannedStopsCharts: StopsChartsComponent;
    @ViewChild('unplannedStopsCharts') unplannedStopsCharts: StopsChartsComponent;

    private _filterSubscription: any;
    XLSlink: string;
    lockRequest: boolean;

    /**
     * stops parsed for table
     *
     * @type {StopTableRow[]}
     * @memberOf StopsComponent
     */
    stopsParsed: StopTableRow[] = [];
    stopsTable: StopTableRow[] = [];
    selectedStop: SelectedStop;
    stopsLimit: number;
    percentageBarData: PercentageBarData;
    translations;
    refreshStops;
    constructor(
        private navbar: NavbarService,
        private stopDataService: StopService,
        private _activeFilters: ActiveFiltersService,
        private _activatedRoute: ActivatedRoute,
        private _translate: TranslateService,
        private router: Router,
        private _stopsUtility: StopUtility,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }

    async ngOnInit() {
        this._translate.get('realtime.stops').subscribe((res: string) => {
            this.navbar.setTitle(res);
        });

        // check if limit for stop table is set in query params
        this._activatedRoute.queryParams.subscribe(params => {
            if (params['stopsLimit']) {
                this.stopsLimit = parseInt(params['stopsLimit'], 10);
            }
        });

        // set a default deviceId filter if it's not already set
        await this._activeFilters.initializeActiveDevice();
        this._filterSubscription = this._activeFilters.onFilterChanged.subscribe(() => this.filtersChanged());

        // initialize report downloading link
        this.XLSlink = this.stopDataService.getXLSLink(this._activeFilters.deviceId, this._activeFilters.dateBegin, this._activeFilters.dateEnd, this._activeFilters.productId);

        // set up charts
        StopsChartsComponent.mainInstance = this.stopAbstractCharts;
        StopsChartsComponent.activateInstance(this.stopAbstractCharts);
        this.plannedStopsCharts.deactivate();
        this.unplannedStopsCharts.deactivate();

        // get and compute all stop data
        await this.getStops();
        this.refreshStops = setInterval(async () => {
            if (!this.router.isActive('/stops', true)) {
                clearInterval(this.refreshStops);
            } else {
                if (moment().valueOf() - moment(this._activeFilters.dateEnd).valueOf() <= 301000) {
                    this._activeFilters.setDates(this._activeFilters.dateBegin, moment().toDate());
                    await this.getStops();
                }
            }
        }, 300000);
    }
    ngOnDestroy() {
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
        clearInterval(this.refreshStops);
    }

    /**
     * Called when the filters are changed
     *
     * @returns
     *
     * @memberOf StopsComponent
     */
    async filtersChanged() {
        if (this.lockRequest) {
            return;
        }
        this.XLSlink = this._getXLSLink();

        await this.getStops();
    }

    /**
     * Get a dowload report link for the XLS
     *
     * @private
     * @returns {string}
     *
     * @memberOf StopsComponent
     */
    private _getXLSLink(): string {
        return this.stopDataService.getXLSLink(this._activeFilters.deviceId, this._activeFilters.dateBegin, this._activeFilters.dateEnd, this._activeFilters.productId);
    }

    async getStops() {
        this.lockRequest = true;
        const stops = await this.stopDataService.getAll(
                                        this._activeFilters.dateBegin.toISOString(),
                                        this._activeFilters.dateEnd.toISOString(), 
                                        this._activeFilters.deviceId, 
                                        this._activeFilters.productId,
                                        this._activeFilters.schedules,
                                        false,
                                        this._activeFilters.orderCoreId);
        this.lockRequest = false;
        const stopsParsed: StopTableRow[] = [];
        const stopsTable: StopTableRow[] = [];

        stops.forEach(stop => {

            // parse stops for table
            const stopParsed = this._stopsUtility.transformStop(stop, true);
            stopsParsed.push(stopParsed);
            if (stopParsed.type !== StopType.disconnection) {
                stopsTable.push(stopParsed);
            }
        });

        this.stopsParsed = stopsParsed.filter(aStop => !aStop.hasSplits).reverse();
        this.stopsTable = stopsTable.filter(aStop => !aStop.splitFrom).reverse();

        let onlySplitFrom = stopsTable.filter(aStop => aStop.splitFrom);
        onlySplitFrom.forEach( aSubsplit => {
            let idx = this.stopsTable.findIndex( el => el.id == aSubsplit.splitFrom);

            this.stopsTable.splice(idx+1, 0, aSubsplit);
        });
        // parse stops for stop bars
        StopsChartsComponent.activeInstance.setStopData(this.stopsTable.filter(e=>!e.hasSplits));
        this.computeProgressBar(this.stopsParsed);
    }

    private computeProgressBar(stops: StopTableRow[]) {
        let tmpData: PercentageBarData = {
            totalTime: 0,
            stopsTime: 0,
            percentage: {
                total: 0,
                planned: 0,
                unplanned: 0,
                notSpecified: 0,
                nonProduction: 0,
                changeActivity: 0,
                changeOver: 0,
                other: 0
            }
        };

        // Calculate total time (ms)
        tmpData.totalTime = (this._activeFilters.dateEnd.getTime() - this._activeFilters.dateBegin.getTime())
        
        // Calculate stops time (ms)
        stops
          .filter((stop) => {
                // do not include disconnections in these stats
                return stop.errorTypeString !== StopErrorTypeLabels.DISCONNECTION;
          })
          .forEach((stop) => {
            let duration = stop.filterDuration * 60 * 1000; // convert from minutes to milliseconds
            let percentage = duration / tmpData.totalTime * 100;
            
            tmpData.stopsTime += duration;
            tmpData.percentage.total += percentage;

            switch(stop.errorTypeString) {
                case  StopErrorTypeLabels.CHANGE_OVER:
                    tmpData.percentage.changeOver += percentage;
                    break;
                case StopErrorTypeLabels.PLANNED:
                    tmpData.percentage.planned += percentage;
                    break;
                case StopErrorTypeLabels.UNPLANNED:
                    tmpData.percentage.unplanned += percentage;
                    break;
                case StopErrorTypeLabels.NOT_SPECIFIED:
                    tmpData.percentage.notSpecified += percentage;
                    break;
                case StopErrorTypeLabels.CHANGE_WORK_PROCESS_TASK:
                    tmpData.percentage.changeActivity += percentage;
                    break;
                case StopErrorTypeLabels.NON_PRODUCTION:
                    tmpData.percentage.nonProduction += percentage;
                    break;
                case StopErrorTypeLabels.DISCONNECTION:
                    // do not include disconnections in these stats
                    break;
                default:
                    tmpData.percentage.other += percentage;
                    break;
            }
        });

        tmpData.percentage.total = Math.round(tmpData.percentage.total);
        this.percentageBarData = tmpData
    }


    /**
     * Fired when the outer area of the donut chart is clicked
     *
     *
     * @memberOf StopsComponent
     */
    // donutChartClicked() {
    //     // unselect slice
    //     this.selectedStop = null;
    //     //this._donutChart.dataProvider = _.cloneDeep(this._donutStopsData);

    //     // refresh the UI
    //     this.changeDetectorRef.detectChanges();
    //     this._donutChart.validateData();
    // }

    changeStopsChartsView(event: string) {
        if (!this[event]) return;
        StopsChartsComponent.activateInstance(this[event]);
        setTimeout(() => {
            StopsChartsComponent.activeInstance.setStopData(this.stopsTable.filter(e=>!e.hasSplits));
        }, 100)
    }
}
