import { OnInit, Inject, Component, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProductionSchedule } from 'app/models/schedules';


@Component({
    templateUrl: './dialog-select-schedule.component.html',
    styleUrls: ['./dialog-select-schedule.component.scss'],
})
export class SelectScheduleDialogComponent implements OnInit {
    @Output() dateChangeEvent = new EventEmitter<Date[]>();
    _schedules = this.schedules.map((schedule) => schedule.saved ? schedule.saved : false);
    allSelected = true;
    constructor(
        @Inject(MAT_DIALOG_DATA) public schedules: ProductionSchedule[],
        public dialogRef: MatDialogRef<SelectScheduleDialogComponent>
    ) {}

    async ngOnInit() {
        this._schedules.forEach((schedule) => {
            if (!schedule) {
                this.allSelected = false;
                return;
            }
        });
    }

    /**
     * Set the schedule and close modal
     *
     *
     * @memberOf SelectScheduleDialogComponent
     */
    selectedSchedule() {
        const res = this.schedules.filter((s, index) => this._schedules[index]);
        this.dialogRef.close(res);
    }

    /**
     * Set scehdules 
     * 
     * 
     * @memberOf SelectScheduleDialogComponent
     */
    addSchedule(idx: number) {
        this._schedules[idx] = !this._schedules[idx];
        if (this._schedules[idx]) {
            this.allSelected = true;
            this._schedules.forEach((schedule) => {
                if (!schedule) {
                    this.allSelected = false;
                    return;
                }
            });
        } else {
            this.allSelected = false;
        }
    }
    /**
     * Close the modal
     *
     *
     * @memberOf SelectScheduleDialogComponent
     */
    cancel() {
        this.dialogRef.close();
    }
    selectAll() {
        if (this.allSelected) {
            for (let i = 0; i < this._schedules.length; i++) {
                this._schedules[i] = false;
            }
            this.allSelected = false;
        } else {
            for (let i = 0; i < this._schedules.length; i++) {
                this._schedules[i] = true;
            }
            this.allSelected = true;
        }
    }
}
