export enum KPI {
    pieces = 1,
    scrap = 2,
    stop = 3,
    oee = 4,
    mtbf = 5,
    mttr = 6,
    time = 7,
    downtime = 8,
    oae = 9,
    timePerc = 10,
    downtimePerc = 11,
    operatingTime = 14,
    operatingTimePerc = 15
}

export interface KPISetting {
    id?: number,
    description?: string,
    enabled?: true,

}

export interface UserKPISetting {
    userId?: number;
    KPISettingId?: KPI;
    order?: number;
    UserId?: number;
    label?: string;
    KPISetting?: KPISetting;
};