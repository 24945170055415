import { TranslateService } from '@ngx-translate/core';
import { Stats } from '../../../models/stats';
import { StatsService } from '../../../services/stats.service';
import {CronGranularity,  dataGranularities,  RawData} from '../../../models/raw-data';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { RawDataService } from 'app/services/raw-data.service';

import { NavbarService } from '../../../services/navbar.service';
import { ErrorsService } from '../../../services/error.service';
import { ShortDurationPipe } from '../../../pipes/shortDuration.pipe';
import { ProductionSchedule } from 'app/models/schedules';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

@Component({
    templateUrl: './mtbf-mttr.component.html',
    styleUrls: ['./mtbf-mttr.component.scss']
})
export class MtbfMttrComponent extends PageWithLoader implements OnInit, OnDestroy {
    // to lock data request if is already processing
    private _filterSubscription;
    activeGranularity: CronGranularity;
    activeGranularityMinutes: number;
    granularities: CronGranularity[];
    chartsReady = false;
    graphType;
    private errorsList;
    plannedList;
    unplannedList;
    selectedType;
    selectedError;
    availableData = false;
    /**
    * granularity not allowed for the current time filter
    *
    * @type {number[]}
    * @memberOf PiecesScrapsComponent
    */
    disabledGranularities: number[] = [];
    rawData: RawData[];
    stats: Stats;
    lockRequest = true;
    private chartValues: any;
    private chartScraps: any;
    constructor(
        private AmCharts: AmChartsService,
        private activeFilters: ActiveFiltersService,
        private changeDetectorRef: ChangeDetectorRef,
        private navbar: NavbarService,
        private _dataService: RawDataService,
        private router: Router,
        private route: ActivatedRoute,
        private _statsService: StatsService,
        private _translate: TranslateService,
        private _errorService: ErrorsService,
        private durationPipe: ShortDurationPipe,
        _themeService: ThemeService
    ) {super(_themeService)}

    /**
    * Set current granularity
    *
    * @param {number} granularityMinutes
    *
    * @memberOf PiecesScrapsComponent
    */
    async setGranularity(granularity: CronGranularity) {
        if (this.disabledGranularities.indexOf(granularity.granularityMinutes) !== -1) {
            return;
        }
        this.activeGranularity = granularity;
        this.activeGranularityMinutes = granularity.granularityMinutes;
        await this.getData();
    }

    /**
    * Compute a reasonable granularity based on current time filters
    *
    *
    * @memberOf PiecesScrapsComponent
    */
    _computeGranularity() {
        const currentInterval = this.activeFilters.dateEnd.getTime() - this.activeFilters.dateBegin.getTime();
        const currentIntervalMinutes = currentInterval / 36000;

        // choose best granularity for the given time filter
        let bestGranularity: CronGranularity;
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 75)) {
                continue;
            } else {
                bestGranularity = granularity;
                break;
            }
        }
        this.activeGranularity = bestGranularity;
        this.activeGranularityMinutes = bestGranularity.granularityMinutes;

        // find disabled granularities for the current time filter
        // i.e. too low or too high granularities
        this.disabledGranularities = [];
        const disabledGranularities: number[] = [];
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 100)) {
                disabledGranularities.push(granularityMinutes);
            } else if (granularityMinutes > (currentIntervalMinutes / 3)) {
                disabledGranularities.push(granularityMinutes);
            }
        }
        this.disabledGranularities = disabledGranularities;
    }

    async ngOnInit() {
        this.graphType = this.route.snapshot.data.type;
        if (this.graphType === 'mtbf') {
            this._translate.get('realtime.mtbf').subscribe((res: string) => {
                this.navbar.setTitle(res);
            });
        } else {
            this._translate.get('realtime.mttr').subscribe((res: string) => {
                this.navbar.setTitle(res);
            });
        }

        // build allowed granularities
        this._buildGranularities();

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        this._computeGranularity();

        this.selectedType = null;
        this.selectedError = null;

        this.getData();

        this.getErrors();
    }
    ngOnDestroy() {
        if (this.AmCharts && this.chartValues) {
            this.AmCharts.destroyChart(this.chartValues);
        }
        if (this.AmCharts && this.chartScraps) {
            this.AmCharts.destroyChart(this.chartScraps);
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    /**
    * Build allowed granularities array
    *
    *
    * @memberOf PiecesScrapsComponent
    */
    private _buildGranularities() {
        const allowedGranularities = [60, 1440, 10080, 46080];

        this.granularities = dataGranularities.filter(granularity => {
            return allowedGranularities.indexOf(granularity.granularityMinutes) !== -1;
        });
    }
    /**
    * Function to navigate to another view
    *
    * @param {any} path
    * @memberof RealtimeComponent
    */
    navigate(path) {
        this.router.navigateByUrl(path);
    }

    onFilterChanged() {
        this._computeGranularity();
        this.getData();
        this.getErrors();
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof HomeComponent
    */
    async getData() {
        this.lockRequest = true;

        this.stats = await this._statsService.getStats(this.activeFilters.dateBegin.toISOString(),
                                                        this.activeFilters.dateEnd.toISOString(),
                                                        this.activeFilters.deviceId,
                                                        this.activeFilters.productId,
                                                        this.selectedType,
                                                        this.selectedError,
                                                        null,
                                                        this.activeFilters.schedules,
                                                        this.activeFilters.orderCoreId);
        if (this.stats[this.graphType]) {
            this.availableData = true;
            this.rawData = await this._dataService.getStats(this.activeFilters.dateBegin.toISOString(),
                                                            this.activeFilters.dateEnd.toISOString(),
                                                            this.activeFilters.deviceId,
                                                            this.activeFilters.productId,
                                                            this.activeGranularity.granularityMinutes,
                                                            this.activeFilters.schedules,
                                                            this.activeFilters.orderCoreId,
                                                            this.selectedType,
                                                            this.selectedError,);
            const data = this._formatRawDataForChart(this.rawData);
            this.drawChart(data);
            this.lockRequest = false;
        } else {
            this.availableData = false;
            this.lockRequest = false;
        }
    }

    async getErrors() {
        this.errorsList = await this._errorService.getTagsWithErrors(this.activeFilters.device.companyId);
        this.plannedList = [];
        this.unplannedList = [];
        for (const tag of this.errorsList) {
            const planned = {
                id: tag.id,
                name: tag.name ? tag.name : '',
                Errors: []
            };
            const unplanned = {
                id: tag.id,
                name: tag.name ? tag.name : '',
                Errors: []
            };
            for (const error of tag.Errors) {
                if (error.type === 0) {
                    unplanned.Errors.push(error);
                }
                if (error.type === 1) {
                    planned.Errors.push(error);
                }
            }
            if (planned.Errors.length > 0) {
                this.plannedList.push(planned);
            }
            if (unplanned.Errors.length > 0) {
                this.unplannedList.push(unplanned);
            }
        }
    }

    /**
    * Format an array of raw data to the format used by the chart
    *
    * @private
    * @param {RawData[]} rawData
    * @returns {*}
    *
    * @memberOf RealtimeComponent
    */
    private _formatRawDataForChart(rawData: RawData[]): any {
 
        const data = [];
        this._translate.stream([
            'times.from',
            'times.to',
            'times.week'
        ]).subscribe((translations) => {
            if (this.activeFilters.schedules && this.activeFilters.schedules.length) {
                const _schedules: ProductionSchedule[] = JSON.parse(this.activeFilters.schedules);
                let groups = [];
                
                for (let schedule of _schedules) {
                    const from = new Date(schedule.dateFrom);
                    const to = new Date(schedule.dateTo);
                    const dataGroup = rawData.filter( (d) => new Date(d.dateFrom) > from && new Date(d.date) <= to );
                    
                    dataGroup['fromGroup'] = schedule.dateFrom;
                    dataGroup['toGroup'] = schedule.dateTo;

                    if (dataGroup && dataGroup.length) {
                        groups.push(dataGroup);
                    }
                }
                const datas = groups.map(aGroup => {
                    let axisDate;
                    let tooltipDate;
                    
                    axisDate = moment(aGroup.fromGroup).locale(this._translate.currentLang).format('DD MMM HH:mm');
                    tooltipDate = `${moment(aGroup.fromGroup).locale(this._translate.currentLang).format('DD MMMM HH:mm')}\n-\n${moment(aGroup.toGroup).locale(this._translate.currentLang).format('DD MMMM HH:mm')}`;
                    
                    let value = {
                        axisDate: axisDate,
                        balloonDate: tooltipDate,
                        [this.graphType]: 0
                    };
                    
                    aGroup.forEach( item => {
                        if (item[this.graphType]) {
                            value[this.graphType] += item[this.graphType];
                        }
                    });
                    return value;
                });

                data.push(...datas);
            } else {
                data.push(...rawData.map(item => {
                    // compute date of sampled data switching for granularity
                    let axisDate;
                    let tooltipDate;
                    switch (this.activeGranularityMinutes) {
                        case 1440:
                            axisDate = moment(item.date).locale(this._translate.currentLang).format('DD MMM');
                            tooltipDate = moment(item.date).locale(this._translate.currentLang).format('DD MMMM');
                            break;
                        case 10080:
                            axisDate = moment(item.date).format('YYYY') + ' ' + translations['times.week'] + ' ' + moment(item.date).format('w');
                            tooltipDate = translations['times.from'] + ' ' + moment(item.dateFrom).format('DD/MM') + ' ' + translations['times.to'] + ' ' + moment(item.date).format('DD/MM');
                            break;
                        case 46080:
                            axisDate = moment(item.dateFrom).format('MM/YYYY') + ' - ' + moment(item.date).format('MM/YYYY');
                            tooltipDate = translations['times.from'] + ' ' + moment(item.dateFrom).format('MM/YYYY') + ' ' + translations['times.to'] + ' ' + moment(item.date).format('MM/YYYY');
                            break;
                        default:
                            axisDate = moment(item.date).valueOf();
                            tooltipDate = moment(item.dateFrom).format('HH:mm') + ' - ' + moment(item.date).format('HH:mm');
                            break;
                    }
                    const value = {
                        axisDate: axisDate,
                        balloonDate: tooltipDate
                    };
                    value[this.graphType] = item[this.graphType] ? item[this.graphType] : 0;
                    return value;
                }));
            }
        });

        return data;
    }

    /**
    * Function to draw the chart
    *
    * @param {any} data
    * @memberof RealtimeComponent
    */
    drawChart(data) {
        const chartDefaultOptions = {
            type: 'serial',
            theme: 'light',
            hideCredits: true,
            categoryField: 'axisDate',
            addClassNames: true,
            dataProvider: data,
            fontFamily: 'Roboto',
            columnSpacing: 0,
            categoryAxis: {
                gridAlpha: 0,
                fontSize: 12,
                autoRotateCount: 12,
                autoRotateAngle: 45
            },
            zoomOutButtonAlpha: 0,
            zoomOutText: '',
            zoomOutButtonImageSize: 0,
            legendSettings: {
                labelText: ''
            },
            panelsSettings: {
                marginLeft: 25,
                marginRight: 25,
                marginTop: 20,
                marginBottom: 0
            },
            guides: [{
                above: true,
                balloonColor: '#5cc6d4',
                labelRotation: 1.2,
                position: 'right',
                boldLabel: true,
                inside: true,
                color: '#555',
                lineAlpha: 1,
                lineColor: '#5cc6d4',
                lineThickness: 1.5,
                id: 'AvgLine',
                label: this._translate.instant('realtime.' + this.graphType + '_long') + ': ' + this.durationPipe.transform(this.stats[this.graphType]),
                value: this.stats[this.graphType]
            }],
            chartCursorSettings: {
                enabled: true,
                pan: false,
                bulletsEnabled: true,
                valueBalloonsEnabled: true,
                graphBulletSize: 1,
                valueLineBalloonEnabled: false,
                valueLineEnabled: false,
                categoryBalloonEnabled: true,
                categoryLineEnabled: false,
                valueLineAlpha: 0.5,
                fullWidth: false,
                cursorAlpha: 0
            },
            chartScrollbarSettings: {
                enabled: false
            },
            valueAxes: [{
                stackType: 'regular',
                minimum: 0,
                showFirstLabel: false,
                autoGridCount: false,
                labelFunction: (value, valueText, valueAxis) => {
                    return this.durationPipe.transform(value);
                },
                color: '#4b4b4b',
                boldLabels: true,
                fontSize: 12,
                includeGuidesInMinMax: true
            }]
        };

        if (this.activeGranularityMinutes < 1440) {
            chartDefaultOptions.categoryAxis['minPeriod'] = this.activeGranularity.granularityMinutes === 60 ? 'hh' : 'DD';
            chartDefaultOptions.categoryAxis['parseDates'] = true;
            chartDefaultOptions.categoryAxis['dateFormats'] = [
                {period: 'fff', format: 'JJ:NN:SS'},
                {period: 'ss', format: 'JJ:NN:SS'},
                {period: 'mm', format: 'JJ:NN'},
                {period: 'hh', format: 'JJ:NN'},
                {period: 'DD', format: 'DD/MM'},
                {period: 'WW', format: 'DD/MM'},
                {period: 'MM', format: 'MM/YYYY'},
                {period: 'YYYY' , format: 'YYYY'}
            ];
        }

        const valuesOptions = _.cloneDeep(chartDefaultOptions);

        valuesOptions['colors'] = ['#FBD63F'];
        valuesOptions['graphs'] = [{
            fillAlphas: 0.8,
            lineAlpha: 0.2,
            type: 'column',
            valueField: this.graphType,
            columnWidth: 0.7,
            balloonFunction: (balloon) => {
                return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + this.durationPipe.transform(balloon.values.value) + '</b>';
            }
        }];

        this.chartValues = this.AmCharts.makeChart('chart-mtbf', valuesOptions);
    }

    typeChanged() {
        if (this.selectedType === 'null' || this.selectedType === null) {
            this.selectedType = null;
            this.selectedError = null;
        } else {
            this.selectedType = parseInt(this.selectedType, 10);
            this.selectedError = null;
        }
        this.getData();
    }

    errorChanged() {
        if (this.selectedError === 'null' || this.selectedError === null) {
            this.selectedError = null;
        } else {
            this.selectedError = parseInt(this.selectedError, 10);
        }
        this.getData();
    }
};
