import { LoaderService } from '../../../services/loader.service';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageWithLoader } from 'app/components/pages/page-with-loader';
import { ThemeService } from 'app/services/theme.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent extends PageWithLoader implements OnInit, OnDestroy {
    loaderStateSubscription: Subscription;
    isStart: boolean = false;
    message: string = '';

    constructor(
        private loaderService: LoaderService,
        _themeService: ThemeService) {
        super(_themeService);
    }

    ngOnInit() {

        
        this.loaderStateSubscription = this.loaderService.getSubject().subscribe((state) => {
            this.isStart = state ? state.isLoading : false;
            if(this.isStart && state.message && state.message != '') {
                // loaderMessage
                this.message = state.message;
            } else {
                this.message = '';
            }
        });
        
    }

    ngOnDestroy() {
        this.loaderStateSubscription.unsubscribe();
    }
}
