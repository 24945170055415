import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable()
export class OeeUtility {
    constructor() { }

    convertMinutesToText(time: number) {
        let tmp = moment.duration(time * 60 * 1000);
        let formattedDate = "";
        if (Math.floor(tmp.asDays())) {
            formattedDate += Math.floor(tmp.asDays()) + "d"
        }
        if (tmp.hours()) {
            if (formattedDate.length) {
                formattedDate += ":"
            }
            formattedDate += tmp.hours() + "h"
        }
        if (formattedDate.length) {
            formattedDate += ":"
        }
        formattedDate += tmp.minutes() + "min"

        return formattedDate;
    }
}