import { LoginService } from './services/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DevicesService } from './services/devices.service';
import { ActiveFiltersService } from './services/active-filters.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import { DashboardSettingsLabel, DashboardUserSettings } from './models/user-dashboard-settings';
import { DashboardSettingsService } from './services/dashboard-settings.service';
import { from } from 'rxjs';

declare var ga: any;

@Component({
    selector: 'app-root',
    template: `
    <app-loader></app-loader>
    <app-navbar></app-navbar>
    <div>
        <router-outlet></router-outlet>
    </div>`,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private _activeFilters: ActiveFiltersService,
        private _devicesService: DevicesService,
        private _activatedRoute: ActivatedRoute,
        private _loginService: LoginService,
        private _dashboardSettings: DashboardSettingsService,
        private _translate: TranslateService,
        private _router: Router
    ) {
        const lang = localStorage.getItem('lang');

        _translate.setDefaultLang(lang || 'en');
        _translate.use(lang || 'en');

        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (window["ga"]) {
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                }
            }
        });
    }

    ngOnInit() {

        /*
         * Load user settings in local storage
         */
        if (this._activeFilters.readFromLocalStorage('deviceId')) {
            this._activeFilters.deviceId = parseInt(this._activeFilters.readFromLocalStorage('deviceId'), 10);
        }
        if (this._activeFilters.readFromLocalStorage('productId')) {
            this._activeFilters.productId = parseInt(this._activeFilters.readFromLocalStorage('productId'), 10);
        }
        if (this._activeFilters.readFromLocalStorage('orderCoreId')) {
            this._activeFilters.orderCoreId = parseInt(this._activeFilters.readFromLocalStorage('orderCoreId'), 10);
        }
        if (this._activeFilters.readFromLocalStorage('granularity')) {
            this._activeFilters.granularity = parseInt(this._activeFilters.readFromLocalStorage('granularity'), 10);
        }
        if (this._activeFilters.readFromLocalStorage('dateBegin')) {
            this._activeFilters.dateBegin = moment(this._activeFilters.readFromLocalStorage('dateBegin')).toDate();
        }
        if (this._activeFilters.readFromLocalStorage('dateEnd')) {
            this._activeFilters.dateEnd = moment(this._activeFilters.readFromLocalStorage('dateEnd')).toDate();
        }
        // set a default time filter
        if (!this._activeFilters.dateBegin || !this._activeFilters.dateEnd) {
            this._activeFilters.setDateFromIntervalString('today');
        }
  
        from(this._dashboardSettings.getUserDashboardSettings()).subscribe( val => {
            let userSettings: DashboardUserSettings[] = val;
            userSettings.forEach( aSetting => {
                if (this._dashboardSettings.isSettingValueEnabled(aSetting.value)) {
                    localStorage.setItem(aSetting.DashboardSetting.label, "true");
                }
            });

            if (localStorage.getItem(DashboardSettingsLabel.forcetoday) != null && 
                localStorage.getItem(DashboardSettingsLabel.forcetoday) === "true") {
                    this._activeFilters.setDateFromIntervalString('today');
                    localStorage.removeItem(DashboardSettingsLabel.forcetoday);
                }
        });

        // get params from query filter
        this._activatedRoute.queryParams.subscribe(params => {
            if (params['token']) {
                this._loginService.setToken(params['token']);
            }
            if (params['deviceId']) {
                this._activeFilters.deviceId = parseInt(params['deviceId'], 10);
            }
            if (params['dateBegin']) {
                this._activeFilters.dateBegin = new Date(params['dateBegin']);
            }
            if (params['dateEnd']) {
                this._activeFilters.dateEnd = new Date(params['dateEnd']);
            }
        });

    }

    @HostListener('window:beforeunload')  beforeUnload() {
        try {
            let forceTodaySettingValue = localStorage.getItem(DashboardSettingsLabel.forcetoday);
            if (forceTodaySettingValue) {
                if (this._dashboardSettings.isSettingValueEnabled(forceTodaySettingValue)) {
                    localStorage.setItem(DashboardSettingsLabel.forcetoday,"true");
                }
            }
        } catch(ex) {}
    }
};