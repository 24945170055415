export enum UserRole {
    admin = 0,
    supervisor = 1,
    tablet = 2,
    manager = 3
}
export interface User {
    id?: string;
    email?: string;
    password?: string;
    role?: UserRole;
    firstName?: string;
    lastName?: string;
    activationCode?: string;
    activated?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    enabled?: boolean;
    companyId?: number;
    // Companies?: CompanyInstance[];
    tabletDeviceId?: number;
    // TabletDevice?: DeviceInstance;
    MAC?: string;
    tabletSerial?: string;
    // Session?: SessionInstance;
    token?: string;
};
