import { StopTableRow } from '../components/ui/stopsTable/stopTableRow.interface';
import { PipeTransform, Pipe  } from '@angular/core';
import { ErrorsTagModel } from 'app/models/errorsTag';

@Pipe({
  name: 'stopGroupPipe'
})
export class StopGroupPipe implements PipeTransform {

  transform(rows: StopTableRow[], filter: ErrorsTagModel[]): any[] {
    if (!rows) {
      return;
    }
    return rows.filter(item => {
        if (!filter || !filter.length) {
            return true;
        }
        if (!item.errorsTag) {
          return false;
        }
        return (filter.find(group => group.id === item.errorsTag.id));
    });
  }

}
