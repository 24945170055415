import { Component, HostBinding, ViewEncapsulation, OnInit, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { StopBarItem } from '../stopsBar/stopBarItem.interface';
import { DurationPipe } from '../../../pipes/duration.pipe';
import { StopsBarComponent } from '../stopsBar/stopsBar.component';
import * as AmCore4 from "@amcharts/amcharts4/core";
import * as AmCharts4 from "@amcharts/amcharts4/charts";
import AmThemesAnimations4 from "@amcharts/amcharts4/themes/animated";
import AmThemesMaterial4 from "@amcharts/amcharts4/themes/material";
import { StopTableRow } from '../stopsTable/stopTableRow.interface';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { group } from '@angular/animations';

const StopColors = {
    changeOver: '#FFDBB3',
    notSpecified: '#6495ED',
    unplanned: '#D7510F',
    planned: '#AACC66',
    nonProduction: '#A9A9A9',
    disconnection: '#FFEB3B',
    changeActivity: '#008048'
};

enum StopsChartsType { main, planned, unplanned }

interface StopBars {
    planned: StopBarItem[];
    unplanned: StopBarItem[];
}

interface FilterStopBar {
    planned: string;
    unplanned: string;
}

interface StopSubCounter {
    counter: number;
    groups: [{
        type: string;
        percent: number;
    }];
}
interface StopsCounters {
    nonProductionStops?: StopSubCounter;
    changeOverStops?: StopSubCounter;
    planned: StopSubCounter;
    unplanned: StopSubCounter;
    notSpecifiedStops?: StopSubCounter;
    changeActivityStops?: StopSubCounter;
    disconnections?: StopSubCounter;
}

@Component({
    selector: 'app-stops-charts',
    templateUrl: 'stopsCharts.component.html',
    styleUrls: ['./stopsCharts.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StopsChartsComponent {
    @Input() title: string;
    @Output() change: EventEmitter<string> = new EventEmitter<string>();

    @HostBinding('class.active') isActive: boolean;
    private type: StopsChartsType;

    // Donut chart data
    @ViewChild('donutChart') donutChartDiv: ElementRef;
    private _donutChart: any;
    private _donutStopsData: any[] = [];

    plannedData: StopBarItem[];
    @ViewChild('plannedBars') plannedBars: StopsBarComponent;
    unplannedData: StopBarItem[];
    @ViewChild('unplannedBars') unplannedBars: StopsBarComponent;

    private stopData: StopTableRow[];
    public isFrequencyChart = false;
    public hasStopGroups = false;
    public plannedLimit: number = 7;
    public unplannedLimit: number = 7;
    public plannedTypeList: string[] = ["All"];
    public unplannedTypeList: string[] = ["All"];
    public filterStopBar: FilterStopBar = { planned: 'All', unplanned: 'All' };

    static activeInstance: StopsChartsComponent = null;
    static mainInstance: StopsChartsComponent = null;

    constructor(
        private _translate: TranslateService,
        private durationPipe: DurationPipe
    ) {
        // enable AmCharts license
        AmCore4.options.commercialLicense = true;
    }

    // ngOnInit(): void {
    //     console.log(`main: ${this.isMain}; active: ${this.isActive}`);
    //     console.log(`has main: ${!!StopsChartsComponent.mainInstance}; has active: ${!!StopsChartsComponent.activeInstance}`)
    //     // Check if active and activate
    //     if(this.isMain && !StopsChartsComponent.activeInstance) {
    //         if(!StopsChartsComponent.mainInstance) {
    //             StopsChartsComponent.mainInstance = this;
    //             StopsChartsComponent.activateInstance(this);
    //             this.type = StopsChartsType.main;
    //         } else {
    //             this.isMain = false;

    //             if(this.title === 'Planned stops')
    //                 this.type = StopsChartsType.planned;
    //             else
    //                 this.type = StopsChartsType.unplanned;
    //         }
    //     }
    // }

    public static activateInstance(instance: StopsChartsComponent) {
        if (StopsChartsComponent.activeInstance) StopsChartsComponent.activeInstance.deactivate();
        StopsChartsComponent.activeInstance = instance;
        StopsChartsComponent.activeInstance.activate();
    }

    activate() {
        this.isActive = true;

        if (!StopsChartsComponent.mainInstance || StopsChartsComponent.mainInstance === this) {
            this.type = StopsChartsType.main;
        } else {
            if (this.title === 'planned')
                this.type = StopsChartsType.planned;
            else
                this.type = StopsChartsType.unplanned;
        }
    }

    deactivate() {
        this.isActive = false;
    }

    private static backToMain() {
        StopsChartsComponent.activateInstance(StopsChartsComponent.mainInstance);
    }

    setStopData(stopTable: StopTableRow[]) {
        this.stopData = stopTable;

        // Check if any stop groups are present
        this.hasStopGroups = false;
        for (let stop of this.stopData) {
            if (stop.errorsTag) {
                this.hasStopGroups = true;
                break;
            }
        }

        this.renderStopData();
    }

    changePlannedLimit() {
        this.plannedLimit = this.plannedLimit === 7 ? 100 : 7;
    }

    changeUnplannedLimit() {
        this.unplannedLimit = this.unplannedLimit === 7 ? 100 : 7;
    }

    private renderStopData() {
        switch (this.type) {
            case StopsChartsType.planned:
                // console.log('parsing planned')
                this.stopData = this.stopData.filter((data) => data.errorTypeString === 'Planned');
                break;

            case StopsChartsType.unplanned:
                // console.log('parsing unplanned')
                this.stopData = this.stopData.filter((data) => data.errorTypeString === 'Unplanned');
                break;

            // default:
            //     console.log('parsing main')
        }

        // parse stops for stop bars
        let stopBars = this.computeStopsNamePercentage(this.isFrequencyChart, this.stopData);
        if (this.type !== StopsChartsType.unplanned) this.plannedData = stopBars.planned;
        if (this.type !== StopsChartsType.planned) this.unplannedData = stopBars.unplanned;

        // redraw the dount chart
        this.redrawDonutChart(this.getStopsForCharts(this.isFrequencyChart, this.stopData));
    }

    reRenderStopData() {
        // parse stops for stop bars
        let stopBars = this.computeStopsNamePercentage(this.isFrequencyChart, this.stopData);
        if (this.type !== StopsChartsType.unplanned) this.plannedData = stopBars.planned;
        if (this.type !== StopsChartsType.planned) this.unplannedData = stopBars.unplanned;
    }

    changeDonutToggle(event) {
        this.isFrequencyChart = event.checked;
        this.renderStopData();
    }

    private getStopsForCharts(isFrequencyChart: boolean, stopsTable: StopTableRow[]): StopsCounters {

        // stop counters (used for donut chart)
        const stopCounters: StopsCounters = {
            nonProductionStops: {
                counter: 0, groups: [] as any
            },
            changeOverStops: {
                counter: 0, groups: [] as any
            },
            planned: {
                counter: 0, groups: [] as any
            },
            unplanned: {
                counter: 0, groups: [] as any
            },
            notSpecifiedStops: {
                counter: 0, groups: [] as any
            },
            changeActivityStops: {
                counter: 0, groups: [] as any
            },
            disconnections: {
                counter: 0, groups: [] as any
            }
        };

        // if you also want the disconnections and the non production stops in the donut, uncomment this line
        // for (const stop of this.stopsParsed) {

        for (const stop of stopsTable) {
            let errorsTag;

            // compute numbers of stops for each stop type (used for donut chart)
            switch (stop.errorTypeString) {
                case 'Change Over':
                    if (stop.errorsTag) {
                        errorsTag = stopCounters.changeOverStops.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.changeOverStops.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.changeOverStops.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.changeOverStops.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                case 'Planned':
                    if (stop.errorsTag) {
                        errorsTag = stopCounters.planned.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.planned.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.planned.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.planned.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                case 'Unplanned':
                    if (stop.errorsTag) {
                        errorsTag = stopCounters.unplanned.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.unplanned.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.unplanned.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.unplanned.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                case 'Non Production':
                    if (stop.errorsTag) {
                        let errorsTag = stopCounters.nonProductionStops.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.nonProductionStops.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.nonProductionStops.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.nonProductionStops.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                case 'Not Specified':
                    if (stop.errorsTag) {
                        let errorsTag = stopCounters.notSpecifiedStops.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.notSpecifiedStops.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.notSpecifiedStops.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.notSpecifiedStops.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                case 'Change Activity':
                    if (stop.errorsTag) {
                        errorsTag = stopCounters.changeActivityStops.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.changeActivityStops.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.changeActivityStops.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.changeActivityStops.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                case 'Disconnection':
                    if (stop.errorsTag) {
                        let errorsTag = stopCounters.disconnections.groups.find((group) => group.type === stop.errorsTag.name);
                        if (!errorsTag) {
                            errorsTag = { percent: 0, type: stop.errorsTag.name };
                            stopCounters.disconnections.groups.push(errorsTag);
                        }
                    }
                    if (isFrequencyChart) {
                        stopCounters.disconnections.counter++;
                        if (errorsTag) errorsTag.percent++;
                    } else {
                        stopCounters.disconnections.counter += stop.filterDuration;
                        if (errorsTag) errorsTag.percent += stop.filterDuration;
                    }
                    break;
                default:
                    console.error(`Unspecified stop type '${stop.errorTypeString}'`);
                    break;
            }
        }

        this.plannedTypeList = stopCounters.planned.groups.map(group => group.type);
        this.plannedTypeList.push('All');
        this.unplannedTypeList = stopCounters.unplanned.groups.map(group => group.type);
        this.unplannedTypeList.push('All');

        return stopCounters;
    }

    private computeStopsNamePercentage(isFrequencyChart: boolean, stopsTable: StopTableRow[]): StopBars {
        const stopBarsMap = {
            planned: new Map<string, StopBarItem>(),
            unplanned: new Map<string, StopBarItem>()
        };

        let totalDuration = 0;
        // if you also want the disconnections and the non production stops in the donut, uncomment this line
        // this.stopsParsed.forEach(stop => {
        stopsTable.forEach(stop => {
            if (stop.errorTypeString !== 'Non Production') {
                totalDuration += stop.filterDuration;
            }
            if (stop.errorTypeString === 'Planned' || stop.errorTypeString === 'Unplanned') {
                const text = stop.error;
                const field = (stop.errorTypeString === 'Planned') ? 'planned' : 'unplanned';

                if (stop.errorsTag && this.filterStopBar[field] != 'All' && this.filterStopBar[field] != stop.errorsTag.name) return;

                if (!stopBarsMap[field].has(text)) {
                    stopBarsMap[field].set(text, {
                        values: 0,
                        percentage: 0,
                        duration: 0,
                        text: stop.error,
                        tag: (stop.errorsTag ? stop.errorsTag.name : null)
                    });
                }

                const stopBarItem = stopBarsMap[field].get(text);

                stopBarItem.duration += stop.filterDuration;
                if (isFrequencyChart) {
                    stopBarItem.values++;
                } else {
                    stopBarItem.values += stop.filterDuration;
                }
            }

        });

        const stopBars: StopBars = {
            planned: [],
            unplanned: []
        };
        for (const field of ['planned', 'unplanned']) {
            stopBarsMap[field].forEach(stop => {
                if (isFrequencyChart) {
                    stop.percentage = Math.round(stop.values / stopsTable.length * 10000) / 100;
                } else {
                    stop.percentage = Math.round(stop.values / totalDuration * 10000) / 100;
                }
                stopBars[field].push(stop);
            });
        }

        return {
            planned: _.orderBy(stopBars.planned, 'duration', 'desc'),
            unplanned: _.orderBy(stopBars.unplanned, 'duration', 'desc')
        };
    }

    private redrawDonutChart(counters: StopsCounters) {
        this._translate.stream([
            'stops.change_over',
            'stops.planned_stop',
            'stops.unplanned_stop',
            'stops.not_specified',
            'stops.change_activity',
            'stops.not_production',
            'stops.disconnections',
        ]).subscribe((translations) => {
            // Display chart
            AmCore4.useTheme(AmThemesAnimations4);
            AmCore4.useTheme(AmThemesMaterial4);
            this._donutChart = AmCore4.create(this.donutChartDiv.nativeElement, AmCharts4.PieChart);
            this._donutChart.responsive.enabled = true;
            this._donutChart.innerRadius = new AmCore4.Percent(50);
            this._donutChart.paddingLeft = new AmCore4.Percent(25);
            this._donutChart.paddingRight = new AmCore4.Percent(25);
            this._donutChart.fontSize = 12;
            this._donutChart.numberFormatter.numberFormat = ".#";

            let selected;

            function generateChartData(types) {
                let chartData = [];
                for (var i = 0; i < types.length; i++) {
                    if (i == selected) {
                        if (types[i].subs.length > 0) {
                            for (var x = 0; x < types[i].subs.length; x++) {
                                chartData.push({
                                    type: types[i].subs[x].type,
                                    percent: types[i].subs[x].percent,
                                    color: types[i].color,
                                    pulled: true
                                });
                            }
                        } else {
                            chartData.push({
                                type: types[i].type,
                                percent: types[i].percent,
                                color: types[i].color,
                                pulled: true
                            });
                        }
                    } else {
                        if (types[i].percent > 0) {
                            chartData.push({
                                type: types[i].type,
                                percent: types[i].percent,
                                color: types[i].color,
                                id: i
                            });
                        }
                    }
                }
                return chartData;
            }

            // Parse and add data
            switch (this.type) {
                case StopsChartsType.main:
                    this._donutStopsData = [{
                        type: translations['stops.change_over'],
                        percent: counters.changeOverStops.counter,
                        subs: counters.changeOverStops.groups,
                        color: StopColors.changeOver
                    }, {
                        type: translations['stops.planned_stop'],
                        percent: counters.planned.counter,
                        subs: counters.planned.groups,
                        color: StopColors.planned
                    }, {
                        type: translations['stops.unplanned_stop'],
                        percent: counters.unplanned.counter,
                        subs: counters.unplanned.groups,
                        color: StopColors.unplanned
                    }, {
                        type: translations['stops.not_specified'],
                        percent: counters.notSpecifiedStops.counter,
                        subs: counters.notSpecifiedStops.groups,
                        color: StopColors.notSpecified
                    }, {
                        type: translations['stops.change_activity'],
                        percent: counters.changeActivityStops.counter,
                        subs: counters.changeActivityStops.groups,
                        color: StopColors.changeActivity
                    }
                    ];

                    this._donutChart.data = generateChartData(this._donutStopsData);
                    break;

                case StopsChartsType.planned:
                    this._donutChart.data = counters.planned.groups.map((group) => {
                        return {
                            type: group.type,
                            percent: group.percent,
                            color: StopColors.planned
                        };
                    });
                    break;

                case StopsChartsType.unplanned:
                    this._donutChart.data = counters.unplanned.groups.map((group) => {
                        return {
                            type: group.type,
                            percent: group.percent,
                            color: StopColors.unplanned
                        };
                    });
                    break;
            }

            // Add and configure Series
            let pieSeries = this._donutChart.series.push(new AmCharts4.PieSeries());
            pieSeries.dataFields.value = "percent";
            pieSeries.dataFields.category = "type";
            pieSeries.slices.template.propertyFields.fill = "color";
            pieSeries.slices.template.propertyFields.isActive = "pulled";
            pieSeries.slices.template.stroke = AmCore4.color("#fff");
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.slices.template.adapter.add('tooltipText', (text, target: any) => {
                const value: any = target.tooltipDataItem;
                if (this.isFrequencyChart) {
                    return value.dataContext.type + ': ' + value.values.value.percent.toFixed(1) + '% (' + value.value + ')';
                } else {
                    return value.dataContext.type + ': ' + value.values.value.percent.toFixed(1) + '% (' + this.durationPipe.transform(value.value, 'text') + ')';
                }
            });

            if (this.type === StopsChartsType.main) {
                pieSeries.slices.template.events.on("hit", (event) => {
                    if (event.target.dataItem.dataContext.id != undefined) {
                        selected = event.target.dataItem.dataContext.id;
                    } else {
                        selected = undefined;
                    }
                    this._donutChart.data = generateChartData(this._donutStopsData);
                });
            }
        });
    }

    openPlannedTab() {
        this.change.emit('plannedStopsCharts');
    }

    openUnplannedTab() {
        this.change.emit('unplannedStopsCharts');
    }

    backToMain() {
        this.change.emit('stopAbstractCharts');
    }
}
