import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from '../../../services/ui.service';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import { ThemeService } from 'app/services/theme.service';
import { PageWithLoader } from '../page-with-loader';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})

export class LoginComponent extends PageWithLoader implements OnInit {

    wait: boolean;

    user: any = {};

    res = false;

    feedback: string;

    public mail: string;

    public password: string;

    public recover_mail: string;

    public loading = false;

    passwordLost = false;

    loginForm = new FormGroup({
        mail: new FormControl('', [
            Validators.required
        ]),

        password: new FormControl('', [
            Validators.required
        ])
    });

    resetForm = new FormGroup({
        recover_mail: new FormControl('', [
            Validators.required
        ])
    });

    constructor(
        private _loginService: LoginService,
        private _ui: UiService,
        private _router: Router,
        private activeFilter: ActiveFiltersService,
        private _translate: TranslateService,
        _themeService: ThemeService
        ) {
          super(_themeService);
        }


    async login() {
        if (!this.loginForm.valid) return;

        this.loading = true;
        this.wait = true;
        this.res = await this._loginService.login(this.mail, this.password);
        this.wait = false;
        
        this.loading = false;

        if (this.res) {
            // user authenticated
            this.feedback = this._translate.instant('login.logged_in');
            this._ui.openSnackBar(this.feedback);

            if (this.activeFilter.device && this.activeFilter.device.ProductionLine) {
                this._router.navigateByUrl('/realtime-line');
            } else {
                this._router.navigateByUrl('/realtime');
            }
        } else {
            // auth error
            this.feedback = this._translate.instant('login.wrong');
            this._ui.openSnackBar(this.feedback);
            this._themeService.setTeamSystemTheme(window.location.href.includes("mes-industry40.teamsystem.com"));
        }
    }

    public async resetSubmit(): Promise<any> {
        if (!this.resetForm.valid) return;

        try {
            this.loading = true;
            const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
            const res = await this._loginService.resetPassword(this.recover_mail, lang);

            if (res) {
                this._translate.get('login.recover.success').subscribe((res) => {
                    this._ui.openSnackBar(res);
                });
            }

        } catch (err) {
            this._translate.get('login.recover.wrong').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        this.loading = false;

    }

    ngOnInit() {
    }

    isPasswordLost(is: boolean) {
        this.passwordLost = is ? true : false;
    }
}
