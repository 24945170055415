import { Component, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ProductionGraphCategoryItem } from './production-graph-category.interface';

@Component({
    selector: 'production-graph-category',
    templateUrl: 'production-graph-category.component.html',
    styleUrls: ['./production-graph-category.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductionGraphCategoryComponent {
    @Input() category: ProductionGraphCategoryItem;
    @Input() index: number;
    @Input() duration: number;
    @ViewChild('tooltip') tooltip: TooltipComponent;
}
