export enum DashboardSettingType {
    forcetoday = 1
}

export enum DashboardSettingsLabel {
    forcetoday = 'forcetoday'
}
export enum DashboardSettingsGroups {
    datefilters = 'datefilters'
}
/*
 DashboardSettingInstance[label] == DashboardSettingType[key]
 DashboardSettingInstance[type] == DashboardSettingType[value]
*/
export interface DashboardSettingInstance {
    id?: number,
    enabled?: true,
    label: DashboardSettingsLabel,
    description?: string,
    type: DashboardSettingType,
    groupLabel: DashboardSettingsGroups,
    groupDescription: string
}


export interface DashboardUserSettings {
    userId?: number;
    DashboardSetting?: DashboardSettingInstance,
    dashboardSettingId?: number,
    value?: any;
};
