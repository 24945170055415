import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';

import { TooltipComponent, MatDialog, MatTabChangeEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import { UserKPISetting } from '../../../models/KPI';
import { KPIService } from '../../../services/KPI.service';
import { DevicesService } from '../../../services/devices.service';
import { RawDataService } from '../../../services/raw-data.service';
import { StopService } from '../../../services/stop-history.service';
import { SessionService } from '../../../services/session.service';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { StatsService } from '../../../services/stats.service';
import { RawData } from '../../../models/raw-data';
import { Session } from '../../../models/session';
import { Stop, StopType } from '../../../models/stop';
import { Device, SensorType } from '../../../models/device';
import { StopsAggregation } from '../../../models/stop-aggregation';
import { OeeParams, Stats } from '../../../models/stats';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';
import { ErrorType, ErrorModel } from '../../../models/error';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { OrderDataService } from 'app/services/order-data.service';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

am4core.useTheme(am4themes_animated);

export interface ChartRawData {
    date: number;
    y: number;
}

export interface GanttChartColorOrder {
    order: number;
    color: any;
}

const stopDurationPercentage = 150;

@Component({
    templateUrl: './realtime-line.component.html',
    styleUrls: ['./realtime-line.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RealtimeLineComponent extends PageWithLoader implements OnInit, OnDestroy {
    @ViewChild('mtbfTooltip') mtbfTooltip: TooltipComponent;
    @ViewChild('mttrTooltip') mttrTooltip: TooltipComponent;
    @ViewChild('chartGroup') chartGroup;

    // to lock data request if is already processing
    private _filterSubscription;
    /**
     * Interval to fetch data every n seconds, if the date filter ends to now
     *
     * @private
     * @type {*}
     * @memberOf RealtimeComponent
     */
    private _RTdataInterval: any;
    private _zoomEndDate: Date;
    private _zoomBeginDate: Date;
    lockRequest = true;
    sessionData: Session[];
    visibleSessions: Session[];
    zoomTimeout = null;
    sessionsGuides: any[];
    filteredStops: Stop[];
    device: Device;
    devicesLine: Device[];
    uptime: any;
    uptimePerc: any;
    uptimeNoProduction: any;
    uptimeNoProductionPerc: any;
    downtime: any;
    downtimePerc: any;
    canExclude = false;
    canExpand = false;
    lineLabel: string;
    // APIresults: any; // actual data from APIs
    rawData: RawData[];
    kpis: UserKPISetting[];
    private formattedData: ChartRawData[];
    isLine: boolean;
    /**
     * Current granularity in minutes of the current (zoom) view
     *
     * @private
     * @type {number}
     * @memberOf RealtimeComponent
     */
    private _currentGranularity: number;
    sessions: Session[];
    stops: Stop[];
    stopsAggregation: StopsAggregation[];
    stats: Stats;
    oee: OeeParams;
    timerMode = false;
    statsAvaiable = false;
    statsTimeout: any;
    tabIndex: number;
    orderColor: GanttChartColorOrder[] = [];
    private chart: any;
    oae: boolean;

    /**
     * Set color for orders
     */
    colorSet = new am4core.ColorSet();
    constructor(
        public activeFilters: ActiveFiltersService,
        private _orderDataService: OrderDataService,
        private _devicesService: DevicesService,
        private _rawDataService: RawDataService,
        private _stopDataService: StopService,
        private _sessionDataService: SessionService,
        private _AmCharts: AmChartsService,
        private _navbar: NavbarService,
        private _router: Router,
        private _statsService: StatsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _translate: TranslateService,
        private _device: DevicesService,
        private _dialog: MatDialog,
        private _kpi: KPIService,
        _themeService: ThemeService
    ) {
        super(_themeService);
        am4core.options.commercialLicense = true;
    }

    async ngOnInit() {

        this._translate.get('realtime.real_time').subscribe((res: string) => {
            this._navbar.setTitle(res);
        });

        this._translate.onLangChange.subscribe(() => {
            this._navbar.setTitle(this._translate.instant('realtime.real_time'));
        });

        // set variablet to check OEE or OAE in kpi
        this.oae = localStorage.getItem('oae') ? localStorage.getItem('oae') === 'true' : false;


        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // settings KPI
        await this._configureKPI();

        // check mat index
        await this.isLineChart();

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        this.getDataAndDrawChart();
    }

    ngOnDestroy() {
        if (this._AmCharts && this.chart && this.chartGroup._selectedIndex !== 0) {
            this._AmCharts.destroyChart(this.chart);
        } else if (this._AmCharts && this.chart) {
            this.chart.dispose();
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }

        // stop RT data interval
        if (this._RTdataInterval) {
            clearInterval(this._RTdataInterval);
        }
    }

    kFormatter(num: number) {
        return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
    }
    /**
     * Function to navigate to another view
     *
     * @param {any} path
     * @memberof RealtimeComponent
     */
    navigate(path) {
        this._router.navigateByUrl(path);
    }
    /**
     * Function to calculate days between startDate and endDate
     *
     * @param {any} startDate
     * @param {any} endDate
     * @memberof RealtimeComponent
     */
    daysBetween(startDate, endDate): number {
        const oneDay = 24 * 60 * 60 * 1000;
        const daysBetween = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / (oneDay)));
        return daysBetween;
    }

    onFilterChanged() {
        this._clearAllDataVariables();
        if (this.activeFilters.device.ProductionLine) {
            this.lockRequest = true;
            this.getDataAndDrawChart();
        } else {
            this._router.navigateByUrl('/realtime');
        }


        // if the date filter ends to now, fetch data every n seconds and update the chart
        this._setRTdataInterval();
    }

    /**
     * Set real time data fetching (every n seconds) interval if the current date filter is up to now
     *
     *
     * @memberOf RealtimeComponent
     */
    private _setRTdataInterval() {
        // TODO: FIX and reenable
        // const enabledDateIntervals = ['today', 'week', 'month', 'year'];
        // const activeDateInterval = this.activeFilters.activeDateInterval;

        // if (this._RTdataInterval) {
        //     clearInterval(this._RTdataInterval);
        // }

        // if (activeDateInterval && enabledDateIntervals.includes(activeDateInterval)) {
        //     this._RTdataInterval = setInterval(() => {
        //         this._getRTdata();
        //     }, environment.RTfetchDataInterval);
        // }
    }

    /**
     * Function to get data based on the selected filter and draw the chart.
     *
     * @memberof RealtimeLineComponent
     */
    async getDataAndDrawChart(deviceIndex?: number) {


        // get all device line
        await this.getLineDevice();

        if (this.tabIndex === -1) {
            this.statsAvaiable = false;

            this.sessions = await this._orderDataService.getAllDataSession(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.device.ProductionLine.id, this.activeFilters.device.Company.satelliteUrl, this.activeFilters.orderId);

            this.formattedData = await this._formatRawDataForLineGanttChart(this.sessions);
            this.lockRequest = false;

            this.drawLineGanttChart(this.formattedData);

        } else {

            this.statsAvaiable = false;
            let res: any;
            if (this.activeFilters.device.Company.satelliteUrl) {
                res = await Promise.all([
                    this._rawDataService.getAll(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._stopDataService.getAll(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._stopDataService.getAggregate(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._orderDataService.getAllDataSession(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.device.ProductionLine.id, this.activeFilters.device.Company.satelliteUrl, this.activeFilters.orderId, this.activeFilters.deviceId),
                    this._statsService.getStats(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._statsService.getAggregateOee(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), [this.activeFilters.deviceId])
                ]);
            } else {
                res = await Promise.all([
                    this._rawDataService.getAll(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._stopDataService.getAll(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._stopDataService.getAggregate(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._sessionDataService.getAll(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._statsService.getStats(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId),
                    this._statsService.getAggregateOee(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString(), [this.activeFilters.deviceId])
                ]);
            }
            // ---------
            this.lockRequest = false;
            this.rawData = res[0];
            this.stops = res[1];
            this.stopsAggregation = res[2];
            this.sessions = res[3];
            this.stats = res[4];
            this.oee = res[5];
            this.formattedData = this._formatRawDataForChart(this.rawData, true);

            // set current granularity
            if (this.rawData.length) {
                this._currentGranularity = this.rawData[0].granularity;
                this.activeFilters.granularity = this._currentGranularity;
            } else {
                this.activeFilters.granularity = 0;
            }
            await this._devicesService.getDevices();
            this.device = this._devicesService.getDeviceInfo(this.activeFilters.deviceId);

            this.timerMode = this.device.sessionType === 2 ? true : false;
            const stopsAggregated = [];
            this.drawChart(this.formattedData);
        }
    }

    /**
     * Get a subset of data from the server and reload the portion of the chart
     *
     * @param {Date} fromDate
     * @param {Date} toDate
     * @returns {Promise<any>}
     *
     * @memberOf RealtimeComponent
     */
    async getDataAndFillChart(fromDate: Date, toDate: Date): Promise<any> {
        if (!this.lockRequest) {
            // this.lockRequest = true;
            this.drawGuides(fromDate, toDate);

            this._changeDetectorRef.detectChanges();
            // get current data
            // const currentData = this.chart.dataSets[0].dataProvider;
            const currentData = this.formattedData;
            const currentDataLength = currentData.length;
            let startIndex = 0;
            let endIndex = 0;

            const rawData = await this._rawDataService.getAll(fromDate.toISOString(), toDate.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId);
            const rawDataFormatted = this._formatRawDataForChart(rawData);

            // set current data granularity
            if (rawData.length) {
                this._currentGranularity = rawData[0].granularity;
                this.activeFilters.granularity = this._currentGranularity;
            } else {
                this.activeFilters.granularity = 0;
            }

            for (let i = 0; i < currentDataLength; i++) {
                const item = currentData[i];
                if (!startIndex && new Date(item.date) > fromDate) {
                    startIndex = i;
                }
                if (!endIndex && new Date(item.date) > toDate) {
                    endIndex = i;
                    break;
                }
            }
            if (!endIndex) {
                endIndex = currentDataLength - 1;
            }

            const newData = [...currentData.slice(0, startIndex - 1), ...rawDataFormatted, ...currentData.slice(endIndex)];
            this.chart.dataSets[0].dataProvider = newData;
            this.chart.validateData();
            this.statsAvaiable = false;

            this.stats = await this._statsService.getStats(fromDate.toISOString(), toDate.toISOString(), this.activeFilters.deviceId, this.activeFilters.productId);
            this.oee = await this._statsService.getOee(fromDate.toISOString(), toDate.toISOString(), this.activeFilters.deviceId);
            this.drawStats();
            // this.lockRequest = false;

            // force template to recheck for changes
            this._changeDetectorRef.detectChanges();
            this.zoomTimeout = null;
        }
    }

    /**
     * Format an array of raw data to the format used by the chart
     *
     * @private
     * @param {RawData[]} rawData
     * @param {addEdgePoints} boolean add 0 data at the boundary of the datas, mandatory for amcharts to set a fixed time range
     * @returns {*}
     *
     * @memberof RealtimeLineComponent
     */
    private async _formatRawDataForLineGanttChart(rawData: any[], addEdgePoints?: boolean): Promise<any[]> {
        const ret: any[] = [];

        if (this.activeFilters.orderId) {
            rawData = rawData.filter((data) => data.orderObj[0].code === this.activeFilters.orderId);
        }

        for (const item of rawData) {
            ret.push(await this._formatOrderDataItemForLineGanttChart(item));
        }

        await this._fillEmplyLineForLineGanttChart(ret);

        ret.sort((a, b) => a.deviceId - b.deviceId);

        return ret;
    }

    private async _fillEmplyLineForLineGanttChart(rawData: any[]): Promise<any[]> {

        for (const device of this.devicesLine) {

            const check = rawData.find(data => data.deviceId === device.id);

            if (!check) {
                rawData.push({
                    label: device.label,
                    deviceId: device.id
                });
            }
        }

        return rawData;
    }

    private async _formatOrderDataItemForLineGanttChart(item: any): Promise<any> {
        if (item.orderObj[0] && item.orderObj[0].code) {
            item.stroke = this._getAndFillOrderColorForGanttChart(item.orderObj[0].code);
        } else {
            item.stroke = this._getAndFillOrderColorForGanttChart(item.productId);
        }
        item.fill = item.stroke.lighten(.2);
        item.orderCode = item.orderObj[0] && item.orderObj[0].code ? item.orderObj[0].code : "-";
        item.itemsTotal = item.itemsTotal.toString();
        item.scraps = item.scraps.toString();
        item.target = item.orderObj[0] && item.orderObj[0].suborders[0].target ? item.orderObj[0].suborders[0].target : "-";
        if (this.devicesLine.find(device => device.id === item.deviceId)) {
            item.label = this.devicesLine.find(device => device.id === item.deviceId).label;
        } else {
            item.label = item.deviceId;
        }
        moment(item.beginAt).format('dd MMM yyyy HH:mm');

        if (!item.endAt) {

            item.endAt = new Date();
            moment(item.endAt).format('dd MMM yyyy HH:mm');
            item.endProduction = this._translate.instant('realtime.progress');

        } else {
            moment(item.endAt).format('DD MMM YYYY HH:mm');
            item.endProduction = moment(item.endAt).format('DD MMM YYYY HH:mm:ss');
        }

        return item;
    }

    private _getAndFillOrderColorForGanttChart(item: any) {
        const colorOrder = this.orderColor.find(order => order.order === item);

        if (!colorOrder) {
            const color = this.colorSet.getIndex(this.orderColor.length * 2).lighten(-.2);
            const data = {
                order: item,
                color: color
            };
            this.orderColor.push(data);
            return color;
        } else {
            return colorOrder.color;
        }
    }

    /**
     * Format an array of raw data to the format used by the chart
     *
     * @private
     * @param {RawData[]} rawData
     * @param {addEdgePoints} boolean add 0 data at the boundary of the datas, mandatory for amcharts to set a fixed time range
     * @returns {*}
     *
     * @memberOf RealtimeComponent
     */
    private _formatRawDataForChart(rawData: RawData[], addEdgePoints?: boolean): ChartRawData[] {
        const ret: ChartRawData[] = [];

        if (addEdgePoints) {
            ret.push({
                date: this.activeFilters.dateBegin.getTime(),
                y: 0
            });
        }

        for (const item of rawData) {
            ret.push(this._formatRawDataItemForChart(item));
        }

        if (addEdgePoints) {
            ret.push({
                date: this.activeFilters.dateEnd.getTime(),
                y: 0
            });
        }

        return ret;
    }

    private _formatRawDataItemForChart(item: RawData): ChartRawData {
        return {
            date: moment(item.date).toDate().getTime(),
            y: item.average ? (Math.round((item.average * 60) * 100)) / 100 : 0
        };
    }

    /**
     * Function to draw gantt chart
     * 
     * @param {any}
     * @memberof RealtimeLineComponent
     */
    drawLineGanttChart(data: any[]) {

        /**
         * To display always all line devices, push label on data
         */
        // let lines: any[] = [];
        // this.devicesLine.forEach(line => {
        //     lines.push({ label: line.label })
        // });
        // data = data.concat(lines);

        this.colorSet.saturation = 0.4;
        this.chart = am4core.create("chart", am4charts.XYChart);
        this.chart.hiddenState.properties.opacity = 0;

        this.chart.paddingRight = 30;
        this.chart.dateFormatter.inputDateFormat = "dd MMM yyyy HH:mm";
        this.chart.data = data;
        this.chart.fontSize = 12;
        this.chart.fontFamily = 'Roboto';

        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "label";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.labels.template.fontSize = 18;
        categoryAxis.dx = -15;
        categoryAxis.fontFamily = 'Teko';
        categoryAxis.fontWeight = 600;
        categoryAxis.cursorTooltipEnabled = false;

        let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        this.chart.dateFormatter.dateFormat = "dd MMM yyyy HH:mm:ss";
        dateAxis.dateFormatter.dateFormat = "dd MMM yyyy HH:mm:ss";
        dateAxis.renderer.minGridDistance = 60;
        dateAxis.baseInterval = { count: 1, timeUnit: "second" };
        dateAxis.max = new Date(this.activeFilters.dateEnd).getTime();
        dateAxis.min = new Date(this.activeFilters.dateBegin).getTime();
        dateAxis.strictMinMax = true;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.tooltip.fontFamily = 'Teko';
        dateAxis.tooltip.fontWeight = 600;
        dateAxis.tooltipDateFormat = "MMM dd HH:mm:ss";

        //dateAxis.minPeriod = 'hh';
        dateAxis.parseDate = true;
        dateAxis.dateFormats.setKey('second', 'HH:mm:ss');
        dateAxis.dateFormats.setKey('minute', 'HH:mm');
        dateAxis.dateFormats.setKey('hour', 'HH:mm');
        dateAxis.dateFormats.setKey('day', 'MMM dd');
        dateAxis.dateFormats.setKey('week', '[bold]MMM dd[/]');
        dateAxis.dateFormats.setKey('month', '[bold]MMM YYYY[/]');
        dateAxis.dateFormats.setKey('year', '[bold]YYYY[/]');

        dateAxis.periodChangeDateFormats.setKey('second', '[bold]HH:mm[/]');
        dateAxis.periodChangeDateFormats.setKey('minute', '[bold]HH:mm[/]');
        dateAxis.periodChangeDateFormats.setKey('hour', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('day', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('week', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('month', '[bold]MMM YYYY[/]');
        dateAxis.periodChangeDateFormats.setKey('year', '[bold]YYYY[/]');

        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(100);
        //series1.columns.template.tooltipText = "{label}: {openDateX} - {dateX}";

        series.dataFields.openDateX = "beginAt";
        series.dataFields.dateX = "endAt";
        series.dataFields.categoryY = "label";
        series.columns.template.propertyFields.fill = "fill"; // get color from data
        series.columns.template.propertyFields.stroke = "stroke";
        series.columns.template.column.cornerRadiusTopLeft = 3;
        series.columns.template.column.cornerRadiusTopRight = 3;
        series.columns.template.column.cornerRadiusBottomLeft = 3;
        series.columns.template.column.cornerRadiusBottomRight = 3;
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#F5F5F5");
        series.tooltip.background.stroke = am4core.color("#323D46");
        series.columns.template.strokeOpacity = .8;
        series.columns.template.tooltipHTML = `<div class="line-tooltip"><center class="tooltip-title"><strong>{label} {orderId}</strong></center>
            <hr />
            <table>
            <tr>
            <th class="key" align="left" widht="100%">${this._translate.instant("realtime.order_label")}</th>
            <td class="value" widht="100%">{orderCode}</td>
            </tr>
            <tr>
            <th class="key" align="left" widht="100%">${this._translate.instant('date_filter.start_date')}</th>
            <td class="value" widht="100%">  {openDateX}</td>
            </tr>
            <tr>
            <th class="key" align="left" widht="100%">${this._translate.instant('date_filter.end_date')}</th>
            <td class="value" widht="100%">  {endProduction}</td>
            </tr>
            <tr>
            <th class="key" align="left" widht="100%">${this._translate.instant('realtime.pieces')}</th>
            <td class="value" widht="100%">{itemsTotal} / {target}</td>
            </tr>
            <tr>
            <th class="key" align="left" widht="100%">${this._translate.instant('realtime.scraps')}</th>
            <td class="value" widht="100%">{scraps}</td>
            </tr>
            </table>
            <hr /></div>`;

        series.tooltip.pointerOrientation = "vertical";

        this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.scrollbarX.animationDuration = 10000;


        /* Create a cursor */
        this.chart.cursor = new am4charts.XYCursor();
    }

    /**
     * Function to draw the chart
     *
     * @param {any} data
     * @memberof RealtimeLineComponent
     */
    drawChart(data: ChartRawData[]) {
        // drawChart(rawData: RawData[], stopsAggregated) {

        // const data = this._formatRawDataForChart(rawData);

        this.chart = this._AmCharts.makeChart('chart', {
            type: 'stock',
            theme: 'light',
            hideCredits: true,
            addClassNames: true,
            mouseWheelZoomEnabled: true,
            balloon: {
                adjustBorderColor: true,
                color: '#000000',
                cornerRadius: 2,
                fillColor: '#FFFFFF',
                fixedPosition: false
            },
            dataSets: [{
                color: '#93B7BE',
                fieldMappings: [{
                    fromField: 'y',
                    toField: 'y'
                }],
                dataProvider: data,
                categoryField: 'date',
            }],
            panels: [{
                allLabels: [{
                    bold: true,
                    text: this._translate.instant('realtime.pieces_hour'),
                    color: '#000000',
                    x: 0,
                    y: 0
                }],
                fontFamily: 'Roboto',
                fontSize: 12,
                stockGraphs: [{
                    id: 'g1',
                    valueField: 'y',
                    lineThickness: 2,
                    fillColors: '#ebfffb',
                    fillAlphas: 0.1,
                    // periodValue: 'Average',
                    connect: true,
                    balloonFunction: point => {
                        const value = point.dataContext.yAverage;
                        let isStop = false;
                        if (this.filteredStops) {
                            this.filteredStops.forEach(stop => {
                                if (moment(point.dataContext.date).isBetween(moment(stop.beginAt), moment(stop.endAt))) {
                                    isStop = true;
                                }
                            });
                        }
                        if (!value) {
                            return '';
                        } else if (isStop) {
                            return this._translate.instant('realtime.avg_below_granularity');
                        } else {
                            return (Math.round(point.dataContext.yAverage * 10) / 10).toString();
                        }
                    }
                }],
                stockLegend: {
                    enabled: false,
                    valueTextRegular: this._translate.instant('realtime.pieces_hour'),
                }
            }],
            valueAxesSettings: {
                minimum: 0,
                strictMinMax: true,
                inside: false
            },
            categoryAxesSettings: {
                minPeriod: 'ss',
                maxSeries: 500,
                groupToPeriods: []
            },
            legendSettings: {
                labelText: ''
            },
            panelsSettings: {
                marginLeft: 40,
                marginRight: 25,
                marginTop: 20,
                marginBottom: 0
            },
            chartCursorSettings: {
                enabled: true,
                pan: false,
                bulletsEnabled: true,
                valueBalloonsEnabled: true,
                graphBulletSize: 1,
                valueLineBalloonEnabled: false,
                valueLineEnabled: false,
                categoryBalloonEnabled: true,
                categoryBalloonFunction: (function (category) {
                    return this.formatDateForGranularity(category, this._currentGranularity);
                }).bind(this),
                categoryLineEnabled: false,
                valueLineAlpha: 0.5,
                fullWidth: false,
                cursorAlpha: 0
            },
            chartScrollbarSettings: {
                enabled: true,
                position: 'top',
                markPeriodChange: true,
                updateOnReleaseOnly: true,
                color: '#000000',
                graph: 'g1',
                dragIcon: 'dragIconRoundSmall',
                dragIconHeight: 20,
                dragIconWidth: 20,
                hideResizeGrips: false,
                backgroundAlpha: 0.1,
                selectedBackgroundAlpha: 0,
                graphFillAlpha: 0,
                graphLineAlpha: 0.5,
                selectedGraphLineColor: '#93B7BE',
                selectedGraphLineAlpha: 1,
                selectedGraphFillColor: '#ebfffb',
                selectedGraphFillAlpha: 0.1,
                gridAlpha: 0.2
            },
            zoomOutOnDataSetChange: true
        });
        if (data.length) {
            setTimeout(() => {
                this.drawGuides(this.activeFilters.dateBegin, this.activeFilters.dateEnd);
                this.drawStats();
            }, 100);
        }

        setTimeout(() => {
            // when zoomed redraw guides
            this.chart.addListener('zoomed', this.onChartZoomed.bind(this));
        }, 500);
    }

    /**
     * Triggered when the chart is zoomed
     *
     * @param {any} event
     *
     * @memberOf RealtimeComponent
     */
    onChartZoomed(event) {
        if (this.zoomTimeout !== null) {
            clearTimeout(this.zoomTimeout);
        }
        this.statsAvaiable = false;
        this.zoomTimeout = setTimeout(() => {
            this._zoomBeginDate = event.startDate;
            this._zoomEndDate = event.endDate;
            this.activeFilters.setDates(event.startDate, event.endDate);
            this._setRTdataInterval();
            this.getDataAndFillChart(this._zoomBeginDate, this._zoomEndDate);
        }, 300);
    }

    /**
     * Function to draw guides on the chart
     * i.e. stops intervals, sessions intervals
     *
     * @param {any} startDate
     * @param {any} endDate
     * @memberof HomeComponent
     */
    drawGuides(startDate?: Date, endDate?: Date) {
        if (!startDate) {
            startDate = this.activeFilters.dateBegin;
        }
        if (!endDate) {
            endDate = this.activeFilters.dateEnd;
        }
        // insert stop guide if it is long enough for selected interval
        /**
         * Create STOP guides
         */
        let stopsGuides;
        if (this.daysBetween(startDate, endDate) > 6) {
            // aggregate stops
            stopsGuides = this.drawAggregateStops();
        } else {
            stopsGuides = this.drawSingleStops(startDate, endDate);
        }
        /**
         * Create SESSION guides
        */
        const sessionGuides = [];
        this.sessions.forEach(session => {
            if (!session.endAt || (session.endAt && moment(session.endAt).valueOf() > moment(this.activeFilters.dateBegin).valueOf())) {
                // FIX SESSION START DATE
                let sessionStart;
                if (moment(session.beginAt).valueOf() < moment(this.activeFilters.dateBegin).valueOf()) {
                    sessionStart = moment(this.activeFilters.dateBegin).valueOf();
                } else {
                    sessionStart = moment(session.beginAt).valueOf();
                }
                if (!session.endAt) {
                } else {
                }
                let label;
                if (this.daysBetween(startDate, endDate) > 6) {
                    // don't show product label so they won't overlap
                    label = '';
                } else {
                    const filterStamp = moment(this.activeFilters.dateEnd).valueOf() - moment(this.activeFilters.dateBegin).valueOf();
                    const stamp = moment(session.endAt).valueOf() - moment(session.beginAt).valueOf();
                    if (stamp < (filterStamp / 50)) {
                        // don't show product label so they won't overlap
                        label = '';
                    } else {
                        if (session.Product) {
                            // label = 'PROD. ' + session.Product.code;
                            if (session.Product.code != session.Product.name) {
                                label = session.Product.code + ' - ' + session.Product.name;
                            } else {
                                label = this._translate.instant('realtime.product', {
                                    code: session.Product.code
                                });
                            }
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                        } else if (session.orderObj[0]) {

                            label = this._translate.instant('realtime.order', {
                                code: session.orderObj[0].code
                            });

                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                        } else {
                            label = this._translate.instant('realtime.not_defined');
                        }
                    }
                }
                if (moment(startDate).valueOf() === sessionStart) {
                    sessionStart += (moment(endDate).valueOf() - moment(startDate).valueOf()) / 100;
                }

                const sessionChartConfig = {
                    above: false,
                    boldLabel: true,
                    date: sessionStart,
                    // toDate: sessionEnd,
                    lineAlpha: 0,
                    lineColor: '#93B7BE',
                    lineThickness: 2,
                    fillColor: '#93B7BE',
                    fillAlpha: 0,
                    expand: true,
                    label: label,
                    labelOffset: 5,
                    color: '#000000',
                    labelRotation: 90,
                    position: 'top',
                    inside: true,
                };
                sessionGuides.push(sessionChartConfig);
            }
        });
        this.chart.panels[0]['categoryAxis'].guides = [...stopsGuides, ...sessionGuides];
        this.chart.validateData();
    }
    /**
     * Function to draw single stops on th chart
     *
     * @param {any} startDate
     * @param {any} endDate
     * @memberof RealtimeComponent
     */
    drawSingleStops(startDate, endDate) {
        const intervalLength = endDate.getTime() - startDate.getTime();
        const stopGuides = [];
        this.filteredStops = [];
        // display single stops
        this.stops.forEach(stop => {
            let end;
            if (!stop.endAt) {
                end = new Date().getTime();
            } else {
                end = moment(stop.endAt).toDate().getTime();
            }
            const stopDuration = end - moment(stop.beginAt).toDate().getTime();

            // vars for stop balloon
            let color = '';
            let motivation = '';
            let notes = '';
            switch (stop.type) {
                case StopType.changeOver:
                    color = '#FFDBB3';
                    motivation = this._translate.instant('realtime.change_over');
                    break;
                case StopType.error:
                    if (!stop.Error) {
                        color = '#6495ED';
                        motivation = this._translate.instant('realtime.not_specified');
                    } else {
                        if (stop.Error.Category.type === ErrorType.unplanned) {
                            color = '#D7510F';
                            notes = stop.note;
                            motivation = stop.Error.text;
                        } else {
                            color = '#AACC66';
                            motivation = stop.Error.text;
                        }
                    }
                    break;
                case StopType.nonProduction:
                    color = '#A9A9A9';
                    motivation = this._translate.instant('realtime.non_production');
                    break;
                case StopType.notSpecified:
                    color = '#6495ED';
                    motivation = this._translate.instant('realtime.not_specified');
                    break;
                case StopType.changeWorkProcessTask:
                    color = '#008048';
                    motivation = this._translate.instant('realtime.change_activity');
                    break;
                case StopType.disconnection:
                    color = '#FFEB3B';
                    motivation = this._translate.instant('realtime.disconnection');
                    break;
                default:
                    color = '#000000';
                    break;
            }
            // check if stop has endAt
            let stopEnd;
            if (stop.endAt) {
                stopEnd = moment(stop.endAt).toDate();
            } else {
                stopEnd = new Date();
            }

            let notesText = '';
            if (notes) {
                notesText = `
                    <div style="position: relative; width: 140px;">
                        <p style="color: ${color}; font-size: 15px; z-index: 999; margin: 12px auto 4px auto; width: 70px; position: relative;" class="balloon-title"><strong>NOTES</strong></p>
                        <div style="height: 1px; box-shadow: nONE; background-color: ${color}; color: ${color}; border-color: ${color}; position: absolute; top: 8px; left: 0px; width: 30px; border-left: 0px;"></div>
                        <div style="height: 1px; box-shadow: none; background-color: ${color}; color: ${color}; border-color: ${color}; position: absolute; top: 8px; right: 0px; width: 30px; border-left: 0px;"></div>
                        <div style="height: 1px;"></div>
                    </div>
                    <p class="balloon-text">${notes}</p>
                `;
            }

            const durationHours = Math.trunc(stopDuration / 1000 / 3600);
            const durationMinutes = Math.trunc((stopDuration - (durationHours * 1000 * 3600)) / 1000 / 60);
            const durationSeconds = Math.round((stopDuration - (durationHours * 1000 * 3600) - (durationMinutes * 1000 * 60)) / 1000);
            const durationText = `${this.padTime(durationHours)}:${this.padTime(durationMinutes)}:${this.padTime(durationSeconds)}`;

            const stopChartConfig = {
                above: true,
                date: moment(stop.beginAt).toDate(),
                toDate: stopEnd,
                lineColor: color,
                lineAlpha: 0,
                fillAlpha: 0.4,
                fillColor: color,
                position: 'top',
                fontSize: 17,
                color: color,
                expand: true,
                inside: true,
                balloonColor: '#FFFFFF',
                balloonText: `
                        <div style="position: relative; width: 140px;">
                            <p style="color: ${color}; font-size: 17px; z-index: 999; margin: 12px auto 4px auto; width: 70px; position: relative;" class="balloon-title"><strong>${this._translate.instant('realtime.stop_type')}</strong></p>
                            <div style="height: 1px; box-shadow: none; background-color: ${color}; color: ${color}; border-color: ${color}; position: absolute; top: 8px; left: 0px; width: 30px; border-left: 0px;"></div>
                            <div style="height: 1px; box-shadow: none; background-color: ${color}; color: ${color}; border-color: ${color}; position: absolute; top: 8px; right: 0px; width: 30px; border-left: 0px;"></div>
                            <div style="height: 1px;"></div>
                        </div>
                        <p style="font-size: 14px; font-size: 16px; width: 140px; word-wrap: break-word;" class="balloon-text">${motivation}</p>

                        <div style="position: relative; width: 140px;">
                            <p style="color: ${color}; font-size: 17px; z-index: 999; margin: 12px auto 4px auto; width: 70px; position: relative;" class="balloon-title"><strong>${this._translate.instant('realtime.duration')}</strong></p>
                            <div style="height: 1px; box-shadow: none; background-color: ${color}; color: ${color}; border-color: ${color}; position: absolute; top: 8px; left: 0px; width: 30px; border-left: 0px;"></div>
                            <div style="height: 1px; box-shadow: none; background-color: ${color}; color: ${color}; border-color: ${color}; position: absolute; top: 8px; right: 0px; width: 30px; border-left: 0px;"></div>
                            <div style="height: 1px;"></div>
                        </div>
                        <p style="font-size: 14px; font-size: 16px; width: 140px;" class="balloon-text">${durationText}</p>

                        ${notesText}
                    `
            };
            if (stopDuration > (intervalLength / stopDurationPercentage)) {
                this.filteredStops.push(stop);
                stopGuides.push(stopChartConfig);
            }
        });
        return stopGuides;
    }
    /**
     * Function to draw aggregate stops on the chart
     *
     * @param {any} startDate
     * @param {any} endDate
     * @memberof RealtimeComponent
     */
    drawAggregateStops() {

        const stopsAggregationGuides = [];
        this.stopsAggregation.forEach(stop => {

            const color = '#FF0000';
            const label = stop.n;
            stopsAggregationGuides.push({
                above: true,
                date: moment(stop.sampleBegin).toDate(),
                toDate: moment(stop.sampleEnd).toDate(),
                lineColor: color,
                lineAlpha: 0,
                fillAlpha: 0,
                fillColor: color,
                label: label,
                bold: true,
                labelCoolor: '#000000',
                position: 'bottom',
                fontSize: 16,
                color: color,
                expand: true,
                inside: true
            });

            stopsAggregationGuides.push({
                above: true,
                date: moment(stop.sampleBegin).toDate(),
                toDate: moment(stop.sampleEnd).toDate(),
                lineColor: color,
                lineAlpha: 0.2,
                fillAlpha: 0,
                position: 'top',
                fontSize: 17,
                color: color,
                expand: true,
                inside: true
            });
        });

        return stopsAggregationGuides;
    }
    /**
     * Function to get stats of the selected period
     *
     * @param startDate
     * @param endDate
     */
    drawStats() {
        // set uptime and downtime 
        this.productionTime();

        const piecesPerHour = this.stats.piecesPerMin * 60;
        const piecesPerHourString = Math.round(piecesPerHour * 10) / 10 + ' ' + this._translate.instant('realtime.pieces_hour');

        /**
         * AVRG Line
         */
        const avgGuide = [{
            above: true,
            balloonColor: '#5cc6d4',
            label: this._translate.instant('realtime.average', {
                value: piecesPerHourString
            }),
            labelRotation: 1.2,
            position: 'right',
            boldLabel: true,
            inside: true,
            color: '#555',
            lineAlpha: 1,
            lineColor: '#5cc6d4',
            lineThickness: 1.5,
            value: piecesPerHour,
            id: 'AvgLine'
        }];
        /**
         * Draw guides on chart
         */
        // remove previouis avg line if exists
        // let cleaned = _.remove(this.chart.panels[0].valueAxes[0].guides, function (n: any) {
        //     return n.id === "AvgLine";
        // });
        this.chart.panels[0].valueAxes[0].guides.pop();
        if (!this.timerMode) {
            this.chart.panels[0].valueAxes[0].guides = this.chart.panels[0].valueAxes[0].guides.concat(avgGuide);
        }
        this.chart.validateData();
        this.visibleSessions = this.getVisibleSessions();
        this.canExclude = this.canExcludeSessions();
        this.canExpand = this.canExpandSessions();
        this.statsAvaiable = true;
    }

    /**
     * Format a date for the chart balloon, given the granularity in minutes of the data
     *
     * @param {Date} date
     * @param {number} granularity
     * @returns {string}
     *
     * @memberOf RealtimeComponent
     */
    formatDateForGranularity(date: Date, granularity: number): string {
        if (granularity < 1440) {
            // less than 1 day granularity
            return moment(date).format('lll');
        } else if (granularity < 46080) {
            // less than 1 month granularity
            return moment(date).format('ll');
        } else {
            // 1 month or more
            return moment(date).format('MMMM YYYY');
        }
    }

    /**
     * Pad seconds, minutes or hours to 2 digits
     *
     * @param {number} n
     * @returns {string}
     *
     * @memberOf RealtimeComponent
     */
    padTime(n: number): string {
        const nString = n.toString();
        return ('00' + nString).substring(nString.length);
    }

    expandSessions() {
        const visibleSessions: Session[] = [];
        for (const session of this.sessions) {
            if (moment(session.beginAt).valueOf() < moment(this.activeFilters.dateEnd).valueOf() &&
                moment(session.endAt).valueOf() > moment(this.activeFilters.dateBegin).valueOf()) {
                visibleSessions.push(session);
            }
        }
        visibleSessions.sort((a, b) => {
            return moment(a.beginAt).valueOf() - moment(b.beginAt).valueOf();
        });
        this.activeFilters.setDates(moment(visibleSessions[0].beginAt).toDate(), moment(visibleSessions[visibleSessions.length - 1].endAt).toDate());
        this.activeFilters.emitChangeEvent();
    }
    excludeSessions() {
        const visibleSessions: Session[] = [];
        for (const session of this.sessions) {
            if (moment(session.beginAt).valueOf() < moment(this.activeFilters.dateEnd).valueOf() &&
                moment(session.endAt).valueOf() > moment(this.activeFilters.dateBegin).valueOf()) {
                visibleSessions.push(session);
            }
        }
        visibleSessions.sort((a, b) => {
            return moment(a.beginAt).valueOf() - moment(b.beginAt).valueOf();
        });
        this.activeFilters.setDates(moment(visibleSessions[1].beginAt).toDate(), moment(visibleSessions[visibleSessions.length - 2].endAt).toDate());
        this.activeFilters.emitChangeEvent();
    }

    canExcludeSessions() {
        if (!this.sessions || !this.sessions.length || this.sessions.length < 3) {
            return false;
        }
        if (!this.visibleSessions || !this.visibleSessions.length || this.visibleSessions.length < 3) {
            return false;
        }
        if (moment(this.visibleSessions[0].beginAt).valueOf() >= moment(this.activeFilters.dateBegin).valueOf() &&
            moment(this.visibleSessions[this.visibleSessions.length - 1].endAt).valueOf() <= moment(this.activeFilters.dateEnd).valueOf()) {
            return false;
        } else {
            return true;
        }
    }
    canExpandSessions() {
        if (!this.sessions || !this.sessions.length) {
            return false;
        }
        if (!this.visibleSessions || !this.visibleSessions.length) {
            return false;
        }
        if (moment(this.visibleSessions[0].beginAt).valueOf() >= moment(this.activeFilters.dateBegin).valueOf() &&
            moment
                (this.visibleSessions[this.visibleSessions.length - 1].endAt).valueOf() <= moment(this.activeFilters.dateEnd).valueOf()) {
            return false;
        } else {
            return true;
        }
    }

    getVisibleSessions(): Session[] {
        const visibleSessions: Session[] = [];
        for (const session of this.sessions) {
            if (!session.endAt) {
                session.endAt = this.activeFilters.dateEnd;
                session.isLast = true;
            }
            if (moment(session.beginAt).valueOf() <= moment(this.activeFilters.dateEnd).valueOf() &&
                moment(session.endAt).valueOf() >= moment(this.activeFilters.dateBegin).valueOf()) {
                visibleSessions.push(session);
            }
        }
        visibleSessions.sort((a, b) => {
            return moment(a.beginAt).valueOf() - moment(b.beginAt).valueOf();
        });
        return visibleSessions;
    }

    canShowScrapsPercentage() {
        if (this.device && this.device.Unipi) {
            switch (this.device.Unipi.sensorType) {
                case SensorType.delta:
                    if (!this.canExpand && !this.canExclude) {
                        // in this case, at least one session is shown in the dashboard,
                        // and we can show the scraps stats, i.e. percentage
                        return true;
                    } else {
                        return false;
                    }
                default:
                    return true;
            }
        } else {
            return true;
        }
    }

    areSessionsVisible() {
        if (this.device && this.device.Unipi && this.device.Unipi.sensorType === SensorType.delta) {
            if (this.visibleSessions.length > 1) {
                return true;
            }
            if (this.visibleSessions.length === 0) {
                return false;
            }
            if (this.visibleSessions.length === 1) {
                if (moment(this.visibleSessions[0].beginAt).valueOf() < moment(this.activeFilters.dateBegin).valueOf() ||
                    moment(this.visibleSessions[0].endAt).valueOf() > moment(this.activeFilters.dateEnd).valueOf()) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    isLastSession() {
        if (this.visibleSessions.length && this.visibleSessions[this.visibleSessions.length - 1].isLast &&
            this.device &&
            this.device.Unipi &&
            this.device.Unipi.sensorType === SensorType.delta) {
            return true;
        }
        return false;
    }

    showScrapsInfoDialog() {
        if (!this.canShowScrapsPercentage() || this.isLastSession()) {
            let nPartials = 0;
            if (moment(this.visibleSessions[0].beginAt).valueOf() < moment(this.activeFilters.dateBegin).valueOf()) {
                nPartials += 1;
            }
            if (moment(this.visibleSessions[this.visibleSessions.length - 1].endAt).valueOf() > moment(this.activeFilters.dateEnd).valueOf()) {
                nPartials += 1;
            }
            const nCompleted = this.visibleSessions.length - nPartials;
        }
    }

    private async _configureKPI() {

        // get KPI
        this.kpis = await this._kpi.getUserKPI();

        this.kpis = this.kpis.slice(0, 6);

    }

    productionTime() {
        this.downtime = 0;
        let disconnection = 0;
        // for each add duration time
        this.stops.forEach(stop => {

            let start, end;
            let stopStart = moment(stop.beginAt).toDate().getTime();
            let stopEnd = moment(stop.endAt).toDate().getTime();

            if (stopStart > this.activeFilters.dateEnd.getTime() || stopEnd < this.activeFilters.dateBegin.getTime()) return;

            if (!stop.endAt) {
                end = this.activeFilters.dateEnd.getTime();
            } else if (stopEnd > this.activeFilters.dateEnd.getTime()) {
                end = this.activeFilters.dateEnd.getTime();
            } else {
                end = stopEnd;
            }

            if (stopStart < this.activeFilters.dateBegin.getTime()) {
                start = this.activeFilters.dateBegin.getTime();
            } else {
                start = stopStart;
            }
            if (stop.type === StopType.disconnection) {
                disconnection += end - start;
            } else {
                this.downtime += end - start;
            }

        });

        const duration = this.activeFilters.dateEnd.getTime() - this.activeFilters.dateBegin.getTime();

        // get production time
        const uptime = duration - this.downtime - disconnection;
        const downtime = this.downtime;

        // se duration as a minutes for use DurationPipe class
        this.uptime = moment.duration(uptime).asMinutes();
        this.downtime = moment.duration(this.downtime).asMinutes();

        // calculate percentual
        this.uptimePerc = ((uptime * 100) / duration).toFixed(1);
        this.downtimePerc = ((downtime * 100) / duration).toFixed(1);

    }

    productionTimeMinusNoProduction() {
        let downtime = 0;
        let disconnection = 0;
        // for each add duration time
        this.stops.forEach(stop => {

            if (stop.type === StopType.nonProduction) return;

            let start, end;
            let stopStart = moment(stop.beginAt).toDate().getTime();
            let stopEnd = moment(stop.endAt).toDate().getTime();

            if (stopStart > this.activeFilters.dateEnd.getTime() || stopEnd < this.activeFilters.dateBegin.getTime()) return;

            if (!stop.endAt) {
                end = this.activeFilters.dateEnd.getTime();
            } else if (stopEnd > this.activeFilters.dateEnd.getTime()) {
                end = this.activeFilters.dateEnd.getTime();
            } else {
                end = stopEnd;
            }

            if (stopStart < this.activeFilters.dateBegin.getTime()) {
                start = this.activeFilters.dateBegin.getTime();
            } else {
                start = stopStart;
            }
            if (stop.type === StopType.disconnection) {
                disconnection += end - start;
            } else {
                downtime += end - start;
            }

        });

        const duration = this.activeFilters.dateEnd.getTime() - this.activeFilters.dateBegin.getTime();

        // get production time
        const uptimeNoProduction = duration - downtime - disconnection;

        // se duration as a minutes for use DurationPipe class
        this.uptimeNoProduction = moment.duration(uptimeNoProduction).asMinutes();

        // calculate percentual
        this.uptimeNoProductionPerc = ((uptimeNoProduction * 100) / duration).toFixed(1);

    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {

        this._clearAllDataVariables();
        this.tabIndex = tabChangeEvent.index - 1;

        if (this.tabIndex === -1) {
            this.lockRequest = true;
            this.getDataAndDrawChart();
        } else {
            this.activeFilters.deviceId = this.devicesLine[tabChangeEvent.index - 1].id;
            this.activeFilters.emitChangeEvent();
        }
    }

    /**
     * Check if current chart is gantt, return true if is it
     * 
     * @memberof RealtimeLineComponent
     */
    async isLineChart() {
        this.tabIndex = this.chartGroup._indexToSelect - 1;
    }

    /**
     * Clear all variables
     * 
     * @memberof RealtimeLineComponent
     */
    _clearAllDataVariables() {
        this.rawData = null;
        this.stops = null;
        this.stopsAggregation = null;
        this.sessions = null;
        this.stats = null;
        this.oee = null;
        this.formattedData = null;
        this.uptime = null;
        this.uptimePerc = null;
        this.downtime = null;
        this.downtimePerc = null;
    }

    /**
     * Get all device in line, now I can't test it so input manual
     */
    async getLineDevice() {

        const devices = await this._device.getDevices();
        this.devicesLine = devices.filter((device) => {
            if (device.ProductionLine) {
                if (device.ProductionLine.id === this.activeFilters.device.ProductionLine.id) {
                    this.lineLabel = device.ProductionLine.description;
                    return true;
                }
                return false;
            }
        });

        if (!this.lineLabel) {
            this.lineLabel = this._translate.instant('realtime.line');
        }

        this.devicesLine.sort((a, b) => a.id - b.id);
    }

}