import { TranslateService } from '@ngx-translate/core';
import { ErrorTypeString, StopTableRow } from './stopTableRow.interface';
import { SetStopNoteDialogComponent } from '../dialog-set-stop-note/dialog-set-stop-note.component';
import { SetStopErrorDialogComponent } from '../dialog-set-stop-error/dialog-set-stop-error.component';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {MatDialog, TooltipComponent} from '@angular/material';
import { LoginService } from 'app/services/login.service';
import { UserRole } from 'app/models/user';
import { FormControl } from '@angular/forms';
import { ErrorsTagModel } from 'app/models/errorsTag';
import { StopUtility } from 'app/services/stop.utility';

export interface StopTypeFilter {
    value: ErrorTypeString;
    text: string;
}

@Component({
    selector: 'app-stops-table',
    templateUrl: 'stopsTable.component.html',
    styleUrls: ['./stopsTable.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StopsTableComponent implements OnInit, OnChanges {
    @ViewChild('stopError') stopErrorTemplate: TemplateRef<any>;
    @ViewChild('product') productTemplate: TemplateRef<any>;
    @ViewChild('errorsTag') errorsTagTemplate: TemplateRef<any>;
    @ViewChild('productTooltip') mtbfTooltip: TooltipComponent;
    @ViewChild('date') dateTemplate: TemplateRef<any>;
    @ViewChild('duration') durationTemplate: TemplateRef<any>;
    @ViewChild('note') noteTemplate: TemplateRef<any>;
    @ViewChild('action') actionTemplate: TemplateRef<any>;
    @ViewChild('table') table: any;
    @Output() dataUpdated = new EventEmitter();

    stopsLimit: number;
    stopTypes: StopTypeFilter[];
    stopTypesSelected = new FormControl();
    stopGroups: ErrorsTagModel[];
    stopGroupsSelected = new FormControl();
    stopErrors: string[];
    stopErrorsSelected = new FormControl();
    // errorTypes: ErrorTypeString[];
    errorTypeFilter: ErrorTypeString = '';
    columns = [];
    userRole: number;
    @Input() stops: StopTableRow[];
    @Input() stopsPerPage: number;

    constructor(
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _storage: LoginService,
        private _stopsUtility: StopUtility
    ) { }
    ngOnChanges(changes: SimpleChanges): void {
        this.stopTypes = [];
        this.stopGroups = [];
        this.stopErrors = [];
        this.stops.forEach(stop => {
            if (!this.stopTypes.find(type => type.value === stop.errorTypeString) && stop && stop.errorTypeString) {
                this.stopTypes.push({
                    value: stop.errorTypeString,
                    text: this._stopsUtility.getStopTypeTranslationByLabel(stop.errorTypeString)
                });
            }
            if (stop.errorsTag && !this.stopGroups.find(group => group.id === stop.errorsTag.id)) {
                this.stopGroups.push(stop.errorsTag);
            }
            if (stop.error && stop.error.length && !this.stopErrors.find(error => error === stop.error)) {
                this.stopErrors.push(stop.error);
            }
        });
        this.stopTypesSelected.patchValue([...this.stopTypes]);
    }

    ngOnInit() {
        this.stopsLimit = this.stopsPerPage || 10;
        // this.stops.forEach(e => console.log(e.error));
        this.userRole = parseInt(this._storage.readFromLocalStorage('role'));
        // Prefetch all labels needed from table initialization
        this._translate.stream([
            'stops.date',
            'stops.duration',
            'stops.error_type',
            'stops.error',
            'stops.errorsTag',
            'stops.product',
            'stops.note',
            'stops.not_available'
        ]).subscribe((translations) => {
            this.columns = [
                {
                    resizeable: false,
                    canAutoResize: true,
                    maxWidth: 150,
                    name: translations['stops.date'],
                    prop: 'date',
                    cellTemplate: this.dateTemplate,
                    cellClass: 'date-cell'
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    maxWidth: 150,
                    name: translations['stops.duration'],
                    prop: 'duration',
                    cellTemplate: this.durationTemplate,
                    cellClass: 'duration-cell'
                },
                // {
                //     resizeable: false,
                //     canAutoResize: true,
                //     name: translations['stops.error_type'],
                //     cellClass: 'error-type-cell',
                //     headerClass: 'error-type-cell',
                //     cellTemplate: this.errorTypeTemplate,
                //     headerTemplate: this.errorTypeHeaderTemplate
                // },
                {
                    resizeable: false,
                    canAutoResize: true,
                    minWidth: 250,
                    name: translations['stops.error'],
                    prop: 'error',
                    cellClass: 'error-cell',
                    cellTemplate: this.stopErrorTemplate,
                    comparator: this.errorComparator.bind(this)
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    maxWidth: 200,
                    name: translations['stops.product'],
                    cellTemplate: this.productTemplate,
                    cellClass: 'product-cell'
                },
                {
                    resizable: false,
                    canAutoResize: true,
                    maxWidth: 200,
                    name: translations['stops.errorsTag'],
                    prop: 'errorsTag',
                    cellTemplate: this.errorsTagTemplate,
                    cellClass: 'errors-tag-cell'
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: translations['stops.note'],
                    cellTemplate: this.noteTemplate,
                    prop: 'note',
                    cellClass: 'note-cell',
                    comparator: this.noteComparator.bind(this)
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: '',
                    prop: 'id',
                    cellClass: 'action-cell',
                    cellTemplate: this.actionTemplate,
                    width: 50,
                    sortable: false,
                    frozenRight: true
                },
            ];
        });
    }

    /**
     * Comparator for error field
     *
     * @param {any} a
     * @param {any} b
     *
     * @memberOf StopsTableComponent
     */
    errorComparator(a: string, b: string, c, d) {
        if (a === 'N/D') {
            return true;
        }
        if (b === 'N/D') {
            return false;
        }

        return (a.toUpperCase() > b.toUpperCase()) ? 1 : -1;
    }

    /**
     * Comparator for note field
     *
     * @param {string} a
     * @param {string} b
     * @param {any} c
     * @param {any} d
     * @returns
     *
     * @memberOf StopsTableComponent
     */
    noteComparator(a: string, b: string, c, d) {
        if (a === 'N/D') {
            return true;
        }
        if (b === 'N/D') {
            return false;
        }

        return (a.toUpperCase() > b.toUpperCase()) ? 1 : -1;
    }

    /**
     * Load next page of stops
     *
     *
     * @memberOf StopsTableComponent
     */
    loadMoreStops() {
        this.stopsLimit += this.stopsPerPage || 10;
        this.table.pageSize = this.stopsLimit;
    }

    /**
     * Open a modal to set the stop's error
     *
     * @param {number} stopId
     *
     * @memberOf StopsTableComponent
     */
    setStopErrorModal(stop: StopTableRow) {
        if (this.userRole === UserRole.supervisor) {
            return;
        }

        let splits = [];
        if (stop.hasSplits) {
            splits = this.stops.filter( e => e.splitFrom === stop.id);
        }
        const dialogRef = this._dialog.open(SetStopErrorDialogComponent, {
            data: { stop: stop, splits: splits },
            minWidth: 600
        });

        dialogRef.afterClosed().subscribe(reload => {
            if (reload) {
                this.dataUpdated.emit();
            }
        });
    }
    /**
     * Open a modal to set the stop's note
     *
     * @param {StopTableRow} stop
     *
     * @memberOf StopsTableComponent
     */
    setStopNoteModal(stop: StopTableRow) {
        if (this.userRole === UserRole.supervisor) {
            return;
        }
        const dialogRef = this._dialog.open(SetStopNoteDialogComponent, {
            data: stop
        });

        dialogRef.afterClosed().subscribe(reload => {
            if (reload) {
                this.dataUpdated.emit();
            }
        });
    }

    getRowClass(row) {
        if (row && row.splitFrom) {
            return 'datatable-row-splitfrom';
        }

        return '';
    }   

    canEditSplits() {
        return this.userRole == UserRole.admin || this.userRole == UserRole.manager;
    }
}