import { Component, Inject, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    styleUrls: ['./dialog-scraps-info.component.scss'],
    templateUrl: './dialog-scraps-info.component.html'
})
export class ScrapsInfoDialogComponent implements OnInit, OnDestroy {
    loading = false;
    message: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ScrapsInfoDialogComponent>,
        private _translate: TranslateService
    ) { }

    ngOnInit() {
        this.message = '';
        this._translate.stream([
            'dialog_scraps.none_session',
            'dialog_scraps.completed_session_single',
            'dialog_scraps.completed_session_plural',
            'dialog_scraps.and',
            'dialog_scraps.partial_session_single',
            'dialog_scraps.partial_session_plural',
            'dialog_scraps.message1_end'
        ]).subscribe((translations) => {
            if (this.data.sessionsCompleted === 0) {
                this.message = translations['dialog_scraps.none_session'] + ' ' + translations['dialog_scraps.completed_session_single'];
            } else if (this.data.sessionsCompleted === 1) {
                this.message = '1 ' + translations['dialog_scraps.completed_session_single'];
            } else {
                this.message = this.data.sessionsCompleted + ' ' + translations['dialog_scraps.completed_session_plural'];
            }
            this.message = this.message + ' ' + translations['dialog_scraps.and'] + ' ';
            if (this.data.sessionsPartials === 0) {
                this.message = this.message + translations['dialog_scraps.none_session'] + ' ' + translations['dialog_scraps.partial_session_single'];
            } else if (this.data.sessionsPartials === 1) {
                this.message = this.message + '1 ' + translations['dialog_scraps.partial_session_single'];
            } else {
                this.message = this.message + this.data.sessionsPartials + ' ' + translations['dialog_scraps.partial_session_plural'];
            }
            this.message = this.message + ' ' + translations['dialog_scraps.message1_end'];
        });
    }

    /**
     * Set the error and close the modal
     *
     *
     * @memberOf ScrapsInfoDialogComponent
     */
    close(action?: string) {
        this.dialogRef.close(action);
    }
    ngOnDestroy() {
        // this.dialogRef = null;
    }
}
