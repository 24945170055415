import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { KPI, UserKPISetting } from 'app/models/KPI';
import { EnvConfigurationService } from "./env-config.service";

@Injectable()
export class KPIService {

    constructor(private httpService: HttpService,
        private envSettings: EnvConfigurationService) { }



    /**
     * Get all KPI
     *
     * @returns
     *
     * @memberOf KPIService
     */
    async getUserKPI(): Promise<UserKPISetting[]> {

        try {
            
            const url = `${this.envSettings.settings.apiBase}/kpi/settings`;

            return this.httpService
            .get(url)
            .toPromise()
            .then(response => response.json() as UserKPISetting[]);

        } catch (err) {
            console.error(err);
            return [];
        }
    }

    /**
     * Check if user has a KPI enabled
     *
     * @returns
     *
     * @memberOf KPIService
     */
    async isUserKPIEnabled(kpi: KPI): Promise<boolean> {

        try {
            let userKpiSettings: UserKPISetting[] = await this.getUserKPI();
            let foundKpi = userKpiSettings.find(uks => uks.KPISettingId == kpi)
            
            return foundKpi != undefined;
        } catch (err) {
            console.error(err);
            return false;
        }
    }


    /**
     * Upload all KPI
     *
     * @returns
     *
     * @memberOf KPIService
     */
    async uploadUserKPI(data: any[]): Promise<number> {

        try {
            
            const url = `${this.envSettings.settings.apiBase}/kpi/settings`;

            return this.httpService
            .put(url, data)
            .toPromise()
            .then(response => response.status);

        } catch (err) {

            console.error(err);

        }

    }
}