import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { CompanyInstance } from '../models/company';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class CompanyService {
  companies: CompanyInstance[];

  constructor(private _http: HttpService,
    private envSettings: EnvConfigurationService) { }

  async getCompany(companyId: number) {
    const url = `${this.envSettings.settings.apiBase}/company/${companyId}`;
    const response = await this._http.get(url).toPromise();

    return response.json() as CompanyInstance;
  }


  //   async getCompanies(populateDevice?: boolean) {
  //     let url = `${this._config.API.url}/company`;
  //     if (populateDevice) url += `?populateDevice=${populateDevice}`;
  //     const response = await this._http.get(url);

  //     this.companies = response.json() as CompanyInstance[];
  //     return this.companies;
  // }
  // async getPlants(companyId: number) {
  //     const url = `${this._config.API.url}/company/plants/${companyId}`;
  //     const response = await this._http.get(url);

  //     return response.json() as PlantInstance[];
  // }

  // async updateSatelliteUrl(companyId: number, satelliteUrl: string) {
  //     const url = `${this._config.API.url}/company/${companyId}?satelliteUrl=${satelliteUrl}`;
  //     const response = await this._http.put(url);

  //     return response.status;
  // }
}