import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MqttService } from '../../../services/mqtt.service';
import { DevicesService } from '../../../services/devices.service';
import { StatsService } from '../../../services/stats.service';
import { OeeParams } from '../../../models/stats';
import * as moment from 'moment/moment';
import { Device } from '../../../models/device';
import { Router } from '@angular/router';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import { ThemeService } from 'app/services/theme.service';
import { PageWithLoader } from 'app/components/pages/page-with-loader';

@Component({
    selector: 'device-box',
    templateUrl: 'device-box.component.html',
    styleUrls: ['./device-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceBoxComponent extends PageWithLoader implements OnInit, OnDestroy {
    @Input() device: Device;
    @Input() mqttService: MqttService;
    oee: OeeParams;
    timerInterval: any;
    oeeInterval: any;
    timer = -1;
    constructor(
        private _deviceService: DevicesService,
        private _statsService: StatsService,
        private router: Router,
        private activeFilters: ActiveFiltersService,
        private _translate: TranslateService,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }

    async ngOnInit() {
        await this.fetchDevice();
        await this.subscribeToDataChannel();
        await this.waitForPieces();
    }

    ngOnDestroy() {
        if (this.oeeInterval) {
            clearInterval(this.oeeInterval);
            this.oeeInterval = null;
        }
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
        this.mqttService.unsubscribe('10/' + this.device.id);
    }

    async subscribeToDataChannel() {
        try {
            console.log("subscribe to 10/" + this.device.id);
            await this.mqttService.subscribe('10/' + this.device.id);
        } catch (ex) {
            console.error(`${this.device.id}: error during mqtt subscription`)
        }
    }

    async waitForPieces() {
        if (this.mqttService.connected) {
            this.mqttService.client.on('message', async (topic: string, payload: Buffer) => {
                // console.log(`Got mqtt message on topic ${topic} ${payload}`)
                //TODO Ottimizzare gestione dei messaggi mqtt
                const command = parseInt(topic.substr(0, 2), 10);
                const deviceId = parseInt(topic.substr(3), 10);
                if (command === 10 && deviceId === this.device.id) {
                    const commandType = payload.readUIntBE(0, 1);
                    if (commandType === 40 && deviceId === this.device.id) {
                        await this.fetchDevice();
                    }
                }
            });
        }
    }

    async fetchDevice() {
        this.device = await this._deviceService.getDevice(this.device.id);
        if (this.device.Session && this.device.state !== 0) {
            this.oee = await this._statsService.getAggregateOee(this.getBegin(), moment().toISOString(), [this.device.id]);
            this.oee.oee *= 100;
            
            if (this.device.state === 1) {
                if (this.oeeInterval) {
                    clearInterval(this.oeeInterval);
                    this.oeeInterval = null;
                }
            } else if (!this.oeeInterval) {
                this.oeeInterval = setInterval(async () => {
                    if (this.device.Session && this.device.state !== 0) {
                        this.oee = await this._statsService.getAggregateOee(this.getBegin(), moment().toISOString(), [this.device.id]);
                        this.oee.oee *= 100;
                    }
                }, 60000);
            }
        } else {
            if (this.oeeInterval) {
                clearInterval(this.oeeInterval);
                this.oeeInterval = null;
            }
        }
        if (this.device.state === 1 && this.device.Session) {
            if (!this.timerInterval) {
                this.timerInterval = setInterval(() => {
                    this.timer = moment().valueOf() - moment(this.device.errorDate).valueOf();
                }, 1000);
            }
        } else {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
                this.timerInterval = null;
                this.timer = -1;
            }
        }
    }

    returnToGraph() {
        this.activeFilters.deviceId = this.device.id;
        this.activeFilters.dateBegin = moment().startOf('day').toDate();
        this.activeFilters.dateEnd = moment().toDate();
        this.router.navigateByUrl('/realtime');
    }

    getBegin(): string {
        if (moment().toDate().getTime() - moment(this.device.Session.beginAt).toDate().getTime() > 86400000) {
            return moment().subtract(86400000, "milliseconds").toISOString();
        } else {
            return moment(this.device.Session.beginAt).toISOString();
        }
    }
}
