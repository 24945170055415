import { ErrorTypeString, StopTableRow } from '../components/ui/stopsTable/stopTableRow.interface';
import { PipeTransform, Pipe  } from '@angular/core';
import { StopTypeFilter } from 'app/components/ui/stopsTable/stopsTable.component';

@Pipe({
  name: 'stopTypePipe'
})
export class StopTypePipe implements PipeTransform {

  transform(rows: StopTableRow[], filter: StopTypeFilter[]): any[] {
    if (!rows) {
      return;
    }
    return rows.filter(item => {
        if (!filter) {
            return false;
        }
        return (filter.find(type => type.value === item.errorTypeString));
    });
  }

}
